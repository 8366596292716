import {useEffect, useState, useRef} from 'react';
import {StepType} from '../types';
import useAudioRecorder from './useAudioRecorder';

const useStep = (
  steps: Array<StepType>,
  cb: (step: StepType, audio: Blob | null, isLast: boolean) => void,
  recordingReady: boolean
) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(-1);
  const [timeEnd, setTimeEnd] = useState<number>(0);
  const audioRecorder = useAudioRecorder();

  useEffect(() => {
    const startAudio = async () => await audioRecorder.startRecording();

    if (!steps[currentStepIndex]) {
      return;
    }

    let timerId = 0;

    let chain: Promise<any> = Promise.resolve(true);

    //start audio registration on letters step
    if (steps[currentStepIndex].type === 'letters' && recordingReady) {
      chain = chain.then(() => startAudio());
    }

    chain
      .then(() => {
        if (steps[currentStepIndex].type === 'letters' && !recordingReady) {
          return;
        }
        // set new duration time end
        setTimeEnd(Date.now() + steps[currentStepIndex].duration);
      })
      .then(() => {
        if (steps[currentStepIndex].type === 'letters' && !recordingReady) {
          return;
        }
        timerId = setTimeout(async () => {
          if (steps[currentStepIndex].type === 'letters') {
            // on last step stop audio stream
            const stopStream = currentStepIndex === steps.length - 1;
            const audio = await audioRecorder.stopRecording(stopStream);
            cb(
              steps[currentStepIndex],
              audio,
              currentStepIndex === steps.length - 1
            );
          } else {
            cb(
              steps[currentStepIndex],
              null,
              currentStepIndex === steps.length - 1
            );
          }

          setNextStep();
        }, steps[currentStepIndex].duration);
      });

    return () => clearTimeout(timerId);
  }, [currentStepIndex, recordingReady]);

  const setNextStep = () => {
    if (steps.length > 0 && currentStepIndex === steps.length - 1) {
      return;
    }
    setCurrentStepIndex(currentStepIndex + 1);
  };

  const resetSteps = () => {
    setCurrentStepIndex(-1);
  };

  return {
    setNextStep,
    currentStep: steps[currentStepIndex],
    endingTime: timeEnd,
    askAudioPermission: audioRecorder.askPermission,
    currentStepIndex,
    resetSteps,
    volumeRef: audioRecorder.volumeRef,
  };
};

export default useStep;
