import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MSelect,
} from '@material-ui/core';
import {FooterText} from '../Typography';

import chevronDown from '../../assets/icons/chevron-down.svg';

interface Props extends FormControlProps {
  label?: string;
  errorKey?: string;
  options: {value: string; label: string; disabled?: boolean}[];
  value: string | string[];
  name: string;
  multiple?: boolean;
}

const Select: React.FC<Props> = ({
  //   form: {touched, errors, setFieldValue},
  //   field,
  label,
  children,
  options,
  errorKey,
  ...props
}) => {
  const {t} = useTranslation();
  const {error, name} = props;

  //TODO: add correct types
  const handleChange = (e: any) => {
    props.onChange && props.onChange(e);
  };

  return (
    <StyledFormControl variant="outlined" {...props}>
      <ColorLabel id={`${name}-label`} className={error ? 'error' : ''}>
        {label}
      </ColorLabel>
      <StyledSelect
        value={props.value}
        onChange={handleChange}
        labelId={`${name}-label`}
        id={name}
        name={name}
        error={error}
        // onBlur={field.onBlur}
        label={label}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {vertical: 'center', horizontal: 'center'},
          transformOrigin: {
            vertical: -64,
            horizontal: 'center',
          },
        }}
        IconComponent={ChevronIcon}
        displayEmpty
        multiple={props.multiple}
        renderValue={(selected: any) => {
          if (props.multiple) {
            return (
              <>
                {(selected as string[]).map(value => (
                  <Chip key={value}>
                    <ChipLabel>{value}</ChipLabel>
                  </Chip>
                ))}
              </>
            );
          }
          return selected;
        }}
      >
        {options.map(({value, label}, i) => (
          <Item key={value} value={value} last={i === options.length - 1}>
            {t(label)}
          </Item>
        ))}
      </StyledSelect>
      {error && <FormHelperText error={true}>{t('error')}</FormHelperText>}
    </StyledFormControl>
  );
};
export default Select;

const StyledFormControl = styled(FormControl)`
  margin-bottom: 24px;

  .MuiFormLabel-root::first-letter {
    text-transform: uppercase;
  }
`;
const StyledSelect = styled(MSelect)`
  width: 100%;
  /* max-width: 392px; */
  padding-right: 10px;
  .MuiSelect-iconOutlined {
    right: 14px;
  }
`;
const ColorLabel = styled(InputLabel)``;

const Item = styled(MenuItem)<{last?: boolean}>`
  height: 56px;
  border-bottom: 2px solid
    ${props => (props.last ? 'transparent' : props.theme.colors.ironGray)};
`;

const ChevronIcon = styled.img.attrs(() => ({
  src: chevronDown,
}))``;

/* &.error {
    color: ${theme.palette.error.main};
  } */
const Chip = styled.div`
  height: 24px;
  padding: 0 8px;
  border-radius: 12px;
  background-color: ${props => props.theme.colors.grey400};
  display: inline-flex;
  align-items: center;
  margin: 0 4.5px;
`;
const ChipLabel = styled(FooterText)`
  color: ${props => props.theme.colors.white};
  font-size: 10px;
  font-weight: 500;
  line-height: 2.4;
  letter-spacing: 0.13px;

  @media ${props => props.theme.breakpoints.mobile} {
    font-size: 12px;
  }
`;
