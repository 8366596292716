import React, {useCallback, useContext} from 'react';
import styled, {ThemeContext} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

import {useAppState} from '../provider/AppStateProvider';
import {PrescriptionProductType} from '../../types';
import {Wrapper, ContentLeft, ContentRight} from '../../atoms/Layout';
import BackLink from '../components/BackLink';
import {Title, Text, Subtitle} from '../../atoms/Typography';

import Glasses from '../../atoms/Icon/Glasses';
import ContactLens from '../../atoms/Icon/ContactLens';

const PrescriptionProduct: React.FC = () => {
  const appState = useAppState();
  const history = useHistory();
  const {t} = useTranslation();
  const theme = useContext(ThemeContext);

  const setPrescriptionProduct = useCallback(
    (productType: PrescriptionProductType) => {
      appState.updateState({productType});

      history.push('/prescription');
    },
    []
  );

  const setGlasses = useCallback(() => setPrescriptionProduct('G'), []);
  const setContacts = useCallback(() => setPrescriptionProduct('CL'), []);

  return (
    <Wrapper light="left" footer header={<BackLink />}>
      <ContentLeft>
        <Title>{t('prescription.productType.title')}</Title>
      </ContentLeft>
      <ContentRight>
        <Description>{t('prescription.productType.subtitle')}</Description>
        <Row>
          <ProductTypeButton onClick={setGlasses}>
            <StyledGlasses color={theme.colors.main} />
            <ProductType>{t('prescription.productType.glass')}</ProductType>
          </ProductTypeButton>
          <ProductTypeButton onClick={setContacts}>
            <StyledContactLens color={theme.colors.main} />
            <ProductType>{t('prescription.productType.lens')}</ProductType>
          </ProductTypeButton>
        </Row>
      </ContentRight>
    </Wrapper>
  );
};

export default PrescriptionProduct;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const ProductTypeButton = styled.button`
  display: block;
  background-color: white;
  width: 186px;
  border: 0px;
  border-radius: 30px;
  box-shadow: 0 10px 30px 0 rgba(123, 138, 154, 0.1);
  padding: 40px 20px;
  margin-right: 20px;
  max-height: 172px;
  outline: none;
`;

const StyledGlasses = styled(Glasses)`
  margin: 0 auto 16px;
`;

const StyledContactLens = styled(ContactLens)`
  margin: 0 auto 16px;
`;

const ProductType = styled(Text)`
  font-size: 20px;
  font-weight: 600;
  color: ${({theme}) => theme.colors.blue};
  font-stretch: normal;
  font-style: normal;
`;
const Description = styled(Subtitle)`
  margin-bottom: 40px;
`;
