import React, {useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';

import {Wrapper, ContentRight, ContentLeft} from '../../../atoms/Layout';
import BackLink from '../../components/BackLink';
import {useAppState} from '../../provider/AppStateProvider';
import Chip from '../../../atoms/Chip';

import Button from '../../../atoms/Button';
import {Title, Subtitle} from '../../../atoms/Typography';
import {
  EYE_CONDITIONS,
  GENERAL_CONDITIONS,
} from '../../../constants/conditions';

enum CONDITIONS {
  PERSONAL,
  RELATIVES,
  EYE,
}

const HealthHistory: React.FC = () => {
  const appState = useAppState();

  const [eyeConditions, setEyeConditions] = useState<string[]>(
    appState.state.questionnaire?.eyeConditions || []
  );
  const [personalConditions, setPersonalCondition] = useState<string[]>(
    appState.state.questionnaire?.personalConditions || []
  );
  const [relativesConditions, setRelativesConditions] = useState<string[]>(
    appState.state.questionnaire?.relativesConditions || []
  );

  const {t} = useTranslation();
  const history = useHistory();

  const params: {step: string} = useParams();
  const formStep = parseInt(params.step) || 0;

  const nextStep = () => {
    if (formStep === CONDITIONS.PERSONAL) {
      return history.push('/conditions/1');
    }
    history.push('/conditions/2');
  };

  const handlePersonalSelection = (el: string) => () => {
    if (el === 'none' || personalConditions.includes('none')) {
      setPersonalCondition([el]);
    } else if (personalConditions.includes(el)) {
      setPersonalCondition(
        personalConditions.filter(selection => selection !== el)
      );
    } else {
      setPersonalCondition(personalConditions.concat([el]));
    }
  };

  const handleRelativesSelection = (el: string) => () => {
    if (el === 'none' || relativesConditions.includes('none')) {
      setRelativesConditions([el]);
    } else if (relativesConditions.includes(el)) {
      setRelativesConditions(
        relativesConditions.filter(selection => selection !== el)
      );
    } else {
      setRelativesConditions(relativesConditions.concat([el]));
    }
  };

  const handleEyeSelection = (el: string) => () => {
    if (el === 'none' || eyeConditions.includes('none')) {
      setEyeConditions([el]);
    } else if (eyeConditions.includes(el)) {
      setEyeConditions(eyeConditions.filter(selection => selection !== el));
    } else {
      setEyeConditions(eyeConditions.concat([el]));
    }
  };

  const onSubmit = () => {
    const questionnaire = appState.state.questionnaire;
    appState.updateState({
      questionnaire: {
        ...questionnaire,
        relativesConditions,
        personalConditions,
        eyeConditions,
      },
    });
    const productType = appState.state.productType;
    if (productType) {
      return history.push('/prescription');
    }

    history.push('/prescription-product');
  };

  const isStepValid = (): boolean => {
    if (formStep === CONDITIONS.RELATIVES) {
      return relativesConditions.length > 0;
    }
    if (formStep === CONDITIONS.PERSONAL) {
      return personalConditions.length > 0;
    }
    return eyeConditions.length > 0;
  };

  return (
    <Wrapper light="left" header={<BackLink />} footer>
      <ContentLeft>
        <Title>{t('healthHistory.title')}</Title>
      </ContentLeft>

      <ContentRight>
        {formStep === CONDITIONS.RELATIVES && (
          <>
            <InlineSubtitle>{t('conditions.relativesTitle')} </InlineSubtitle>
            <BoldInlineSubtitle>
              {t('conditions.relativesTitleEmphasized')}
            </BoldInlineSubtitle>

            <Conditions>
              {GENERAL_CONDITIONS.map(el => {
                return (
                  <Chip
                    key={el}
                    label={t(`conditions.${el}`)}
                    onClick={handleRelativesSelection(el)}
                    selected={relativesConditions.includes(el)}
                  />
                );
              })}
            </Conditions>
          </>
        )}
        {formStep === CONDITIONS.PERSONAL && (
          <>
            <InlineSubtitle>{t('conditions.personalTitle')} </InlineSubtitle>
            <BoldInlineSubtitle>
              {t('conditions.personalTitleEmpashized')}
            </BoldInlineSubtitle>

            <Conditions>
              {GENERAL_CONDITIONS.map(el => {
                return (
                  <Chip
                    key={el}
                    label={t(`conditions.${el}`)}
                    onClick={handlePersonalSelection(el)}
                    selected={personalConditions.includes(el)}
                  />
                );
              })}
            </Conditions>
          </>
        )}
        {formStep === CONDITIONS.EYE && (
          <>
            <InlineSubtitle>{t('conditions.eyeTitle')}</InlineSubtitle>

            <Conditions>
              {EYE_CONDITIONS.map(el => {
                return (
                  <Chip
                    key={el}
                    label={t(`conditions.${el}`)}
                    onClick={handleEyeSelection(el)}
                    selected={eyeConditions.includes(el)}
                  />
                );
              })}
            </Conditions>
          </>
        )}

        <StyledButton
          onClick={formStep !== CONDITIONS.EYE ? nextStep : onSubmit}
          disabled={!isStepValid()}
        >
          {t('action.continue')}
        </StyledButton>
      </ContentRight>
    </Wrapper>
  );
};

export default HealthHistory;

const StyledButton = styled(Button)`
  display: block;
  margin-top: 24px;

  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
    margin-bottom: 50px;
  }
`;

const Conditions = styled.div``;

const InlineSubtitle = styled(Subtitle)`
  display: inline;
`;

const BoldInlineSubtitle = styled(InlineSubtitle)`
  font-weight: 600;
`;
