import React from 'react';
import styled from 'styled-components';
import {useField} from 'react-final-form';
import Select from '../../../atoms/Form/Select';
import {useTranslation} from 'react-i18next';

interface SelectProps {
  name: string;
  placeholder?: string;
  validate?: () => string | undefined;
  multiple?: boolean;
  options: Array<OptionType> | [];
  initialValue?: string;
  label?: string;
  className?: string;
  disabled?: boolean;
}

export interface OptionType {
  label: string;
  value: string;
  abbreviation?: string;
}

interface OverloadGetValue {
  (el: OptionType): OptionType;
  (el: string): OptionType;
}

const SelectField: React.FC<SelectProps> = ({className, ...props}) => {
  const {t} = useTranslation();
  const {input, meta} = useField(props.name, {
    validate: props.validate,
  });

  const error = meta.touched && (meta.error || meta.submitError);

  //   const selectStyles = {
  //     menu: styles => ({...styles, zIndex: 999}),
  //     control: (styles, state) => ({
  //       ...styles,
  //       borderColor:
  //         error && !state.isFocused ? 'hsl(348, 100%, 61%)' : styles.borderColor,
  //     }),
  //   };

  const getInputValue: OverloadGetValue = (el: any) =>
    el.value ? el : props.options.find(option => option.value === el);

  const getValue = () => {
    if (!input.value) {
      return props.multiple ? [] : '';
    }
    // react-select wants value as an object shaped like {value: string, label: string}
    // if (!props.isMulti) {
    //   return input.value.value
    //     ? input.value
    //     : props.options.find(option => option.value === input.value);
    // }

    if (props.multiple) {
      return input.value;
    }

    const label = props.options.find(option => option.value === input.value)
      ?.label;

    return label ? t(label) : '';
  };

  const handleChange = (event: any) => {
    input.onChange(event.target.value);
    // if (!selection) {
    //   return input.onChange(selection);
    // }
    // const value = props.isMulti
    //   ? selection.map((el: OptionType) => el.value)
    //   : selection.value;

    // return input.onChange(value);
  };

  return (
    <StyledSelect
      {...props}
      className={className}
      value={getValue()}
      options={props.options}
      onChange={handleChange}
      // onBlur={() => input.onBlur(input.value)}
      error={error}
      label={props.label}
      name={props.name}
      multiple={props.multiple}
    />
  );
};
SelectField.displayName = 'SelectField';

export default SelectField;

const StyledSelect = styled(Select)`
  & > div {
    background-color: ${({theme}) => theme.colors.white};
  }
`;
