import merge from 'deepmerge';
import {createMuiTheme} from '@material-ui/core/styles';

import * as commonTheme from './common';
import lensCrafterLogo from '../assets/images/lens-crafter@3x.png';

const colors = {};

const breakpoints = {};

const shadows = {};

const muiTheme = {};

const meta = {
  name: 'LensCrafters',
  logo: lensCrafterLogo,
};

export default {
  styledTheme: {
    colors: merge(commonTheme.colors, colors),
    breakpoints: merge(commonTheme.breakpoints, breakpoints),
    meta,
    shadows: merge(commonTheme.shadows, shadows),
  },
  muiTheme: createMuiTheme(merge(commonTheme.muiTheme, muiTheme)),
};
