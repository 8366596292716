import React from 'react';
import styled from 'styled-components';
import {FooterText} from '../Typography';

const Footer: React.FC = ({children}) => (
  <FooterContainer>
    {children || (
      <Content>
        <FooterText>© Luxottica Group. All rights reserved.</FooterText>
      </Content>
    )}
  </FooterContainer>
);

export default Footer;

const FooterContainer = styled.footer`
  height: 100px;
  padding: 38px 96px;
  box-sizing: border-box;

  @media ${props => props.theme.breakpoints.mobile} {
    padding: 38px 0;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${props => props.theme.breakpoints.mobile} {
    flex-direction: column;
    justify-content: center;
  }
`;

const FooterLink = styled(FooterText)`
  margin-left: 14px;

  @media ${props => props.theme.breakpoints.mobile} {
    margin-left: 0;

    &:first-child {
      margin-right: 14px;
    }
  }
`;
