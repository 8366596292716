import React, {useState, useCallback, useRef, useEffect, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useAppState} from '../../provider/AppStateProvider';
import useStep from '../../../hooks/useStep';
import TestLetters from '../../components/TestLetters';
import TestInstruction from '../../components/TestInstruction';
import TestIntro from '../../views/TestInstruction';

import {StepType} from '../../../types';
import QuitTestModal from '../../components/Modal/QuitTestModal';
import {API_URL} from '../../../api/https';

const Test: React.FC = () => {
  const {t} = useTranslation();
  const [quitModal, setQuitModal] = useState(false);
  const [recordingReady, setRecordingReady] = useState(false);

  const appState = useAppState();
  const history = useHistory();
  const audioRef = useRef<HTMLAudioElement>(null);

  const handleStart = async () => {
    audioRef.current && audioRef.current.play();
    audioRef.current && audioRef.current.pause();
    await askAudioPermission()
      .then(() => setNextStep())
      .catch(() => {
        return history.push('/permissions');
      });
  };

  const toggleQuitModal = useCallback(() => {
    setQuitModal(!quitModal);
  }, [quitModal]);

  const handleQuit = useCallback(() => {
    appState.updateState({tests: []});
    audioRef.current && audioRef.current.pause();
    setQuitModal(false);
    resetSteps();
    setRecordingReady(false);
  }, [quitModal, audioRef]);

  const onStepEnd = (step: StepType, audio: Blob | null, isLast: boolean) => {
    if (step.type === 'letters') {
      const tests = appState.state.tests;
      if (!tests) {
        const initArray = [
          {
            step,
            audioBlob: audio,
          },
        ];
        appState.updateState({
          tests: initArray,
        });
      } else {
        appState.updateState({
          tests: tests.concat([
            {
              step,
              audioBlob: audio,
            },
          ]),
        });
      }
    }
    setRecordingReady(false);

    if (isLast) {
      history.push('/completed');
    }
  };

  const {
    currentStep,
    askAudioPermission,
    setNextStep,
    currentStepIndex,
    resetSteps,
    endingTime,
    volumeRef,
  } = useStep(appState.state.stepConfig || [], onStepEnd, recordingReady);

  useEffect(() => {
    if (audioRef.current && currentStep) {
      audioRef.current.play().catch(console.error);
    }
  }, [currentStep]);

  useEffect(() => {
    const startUserRecording = () => {
      if (currentStep.type === 'letters') {
        setRecordingReady(true);
      }
    };
    audioRef.current?.addEventListener('ended', startUserRecording);

    return () =>
      audioRef.current?.removeEventListener('ended', startUserRecording);
  }, [currentStep]);

  const renderContent = () => {
    if (!currentStep) {
      return <TestIntro onContinue={handleStart} />;
    }

    if (currentStep.type === 'letters') {
      return (
        <TestLetters
          endTime={endingTime}
          step={currentStep}
          onCancel={toggleQuitModal}
          timerReady={recordingReady}
          volumeRef={volumeRef}
        />
      );
    }

    return (
      <TestInstruction
        endTime={endingTime}
        step={currentStep}
        onCancel={toggleQuitModal}
        firstStep={currentStepIndex === 0}
      />
    );
  };

  return (
    <>
      {renderContent()}
      <audio
        ref={audioRef}
        src={currentStep && API_URL + t(currentStep.audio)}
      ></audio>

      <QuitTestModal
        visible={quitModal}
        onQuit={handleQuit}
        onContinue={toggleQuitModal}
      />
    </>
  );
};

export default Test;
