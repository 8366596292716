import React, {useState, useEffect, MouseEvent} from 'react';
import {Form} from 'react-final-form';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

import {OptionType} from '../../components/Fields/SelectField';
import AutoCompleteField from '../../components/Fields/AutoCompleteField';

import BackLink from '../../components/BackLink';
import {fetchNations} from '../../../api/nations';
import {useAppState} from '../../provider/AppStateProvider';
import {required} from '../../../utils/validation';
import {Wrapper, ContentLeft, ContentRight} from '../../../atoms/Layout';
import {Title, Subtitle, Text} from '../../../atoms/Typography';
import Button from '../../../atoms/Button';
import QualificationStateModal from '../../components/Modal/QualificationStateModal';

const StateSelection: React.FC = () => {
  const {t} = useTranslation();
  const appState = useAppState();
  const history = useHistory();

  const [nations, setNations] = useState<Array<OptionType>>([]);
  const [isLoadingNations, setIsLoadingNations] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isInfo, setIsInfo] = useState(false);

  useEffect(() => {
    fetchNations().then(nations => {
      setNations(nations);
      setIsLoadingNations(false);
    });
  }, [appState.state.state]);

  const handleStop = () => {
    history.push('/stop', {
      issue: 'checkQualify.fail.stateIssue',
    });
  };

  const onSubmit = (data: {nation: string}): void => {
    appState.updateState({
      state: data.nation,
    });
    history.push('/user-info');
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setIsInfo(false);
  };

  const backToStore = () => {
    if (appState.state.successRedirectUrl) {
      window.location.href = appState.state.successRedirectUrl;
    } else {
      history.push('/');
    }
  };

  const openInfoModal = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsModalVisible(true);
    setIsInfo(true);
  };

  const handleRejection = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      <Wrapper light="left" footer header={<BackLink />}>
        <ContentLeft>
          <Title>{t('checkQualify.state.title')}</Title>
        </ContentLeft>

        <ContentRight>
          <Form
            initialValues={{
              nation: appState.state.state || '',
            }}
            onSubmit={onSubmit}
            render={({
              handleSubmit,
              submitError,
              hasValidationErrors,
              initialValues,
              values,
            }): JSX.Element => {
              const isQualifiedState = !!nations.find(
                el => el.abbreviation === values.nation
              );

              return (
                <>
                  {submitError && <p>{submitError}</p>}

                  <Subtitle>{t('checkQualify.state.subtitle')}</Subtitle>
                  <AutoCompleteField
                    name="nation"
                    options={nations}
                    validate={required}
                    placeholder="eg. California"
                    label={t('checkQualify.state.stateLabel')}
                    loading={isLoadingNations}
                  />
                  <Row>
                    <Button
                      onClick={
                        isQualifiedState ? handleSubmit : handleRejection
                      }
                      disabled={hasValidationErrors}
                    >
                      {t('action.continue')}
                    </Button>
                    <TextButton onClick={handleStop} color="primary">
                      {t('checkQualify.state.outsideUS')}
                    </TextButton>
                  </Row>
                  <StyledText>
                    {t('checkQualify.state.noteFirst')}
                    <InfoLink onClick={openInfoModal} href="#">
                      {t('checkQualify.state.noteLink')}
                    </InfoLink>
                    {t('checkQualify.state.noteSecond')}
                  </StyledText>
                </>
              );
            }}
          />
        </ContentRight>
      </Wrapper>
      <QualificationStateModal
        visible={isModalVisible}
        onQuit={closeModal}
        onContinue={backToStore}
        info={isInfo}
      />
    </>
  );
};

export default StateSelection;

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${props => props.theme.breakpoints.mobile} {
    flex-direction: column;
    margin-top: 16px;

    & button {
      margin: 16px;
    }
  }
`;

const TextButton = styled(Button).attrs({variant: 'text'})`
  font-weight: 900;
  color: ${props => props.theme.colors.primary.first};
`;

const StyledText = styled(Text)`
  margin-top: 48px;
`;

const InfoLink = styled.a`
  text-decoration: underline;
  color: ${props => props.theme.colors.gray};
`;
