import axios from 'axios';
import {sendErrorTrace} from './configuration';

export const API_URL = process.env.REACT_APP_API_ENDPOINT || '';

let token = '';
export const setToken = (userToken: string): string => (token = userToken);

const https = axios.create({
  baseURL: API_URL,
});

https.interceptors.request.use(config => {
  if (!token) {
    return config;
  }
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

https.interceptors.response.use(
  response => response,
  error => {
    sendErrorTrace('api-error', {error});
    return Promise.reject(error);
  }
);

export default https;
