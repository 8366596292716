import React, {useState, useCallback} from 'react';
import styled from 'styled-components';
import {useField} from 'react-final-form';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';

import Input from '../../../atoms/Form/Input';
import closeIcon from '../../../assets/icons/close.svg';
import imageCompression from 'browser-image-compression';

interface Props {
  name: string;
}

const ImageField: React.FC<Props> = ({name}) => {
  const {t} = useTranslation();
  const {input} = useField(name, {type: 'file'});
  const [fileName, setFileName] = useState('');

  const onDrop = useCallback(async files => {
    let file = files[0];

    if (!file) {
      return;
    }

    if (file.type !== 'application/pdf') {
      file = await imageCompression(file, {maxSizeMB: 5});
    }

    setFileName(file.name);

    const reader = new FileReader();
    reader.onload = ({target}) => {
      if (target && target.result) {
        input.onChange(target.result);
      }
    };
    reader.readAsDataURL(file);
  }, []);

  const onDropRejected = () => {
    // TODO
    return;
  };

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    onDropRejected,
    accept: ['image/png', 'image/jpeg', 'application/pdf'],
    maxSize: 10485760,
  });

  const onCancel = useCallback(evt => {
    evt.stopPropagation();
    input.onChange('');
    setFileName('');
  }, []);

  return (
    <InputContainer {...getRootProps()}>
      <FileInput {...getInputProps()} />
      <StyledInput label={t('prescription.label')} fullWidth disabled value={fileName} />
      {fileName && <CloseIcon onClick={onCancel} />}
    </InputContainer>
  );
};

export default ImageField;

const InputContainer = styled.div`
  outline: none;
  position: relative;
`;

const FileInput = styled.input``;

const StyledInput = styled(Input)`
  background-color: ${({theme}) => theme.colors.white};

  & label {
    font-weight: 900;
  }

  & > div {
    padding-right: 44px;
    pointer-events: none;

    & > input {
      color: ${({theme}) => theme.colors.black};
      font-weight: bold;
      cursor: pointer;
    }
  }
`;

const CloseIcon = styled.img.attrs({src: closeIcon})`
  cursor: pointer;
  position: absolute;
  height: 32px;
  width: 32px;
  right: 12px;
  top: 14px;
`;
