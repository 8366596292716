import React, {FC} from 'react';
import styled from 'styled-components';

import {ReactComponent as contacts} from '../../assets/icons/contactlens.svg';

function LightenDarkenColor(colorCode: any, amount: any) {
  let usePound = false;
  if (colorCode[0] == '#') {
    colorCode = colorCode.slice(1);
    usePound = true;
  }
  const num = parseInt(colorCode, 16);
  let r = (num >> 16) + amount;
  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }
  let b = ((num >> 8) & 0x00ff) + amount;
  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }
  let g = (num & 0x0000ff) + amount;
  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }
  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

interface Props {
  className?: string;
  color: string;
}

const ContactLens: FC<Props> = ({className, color}) => {
  return <StyledIcon className={className} color={color} />;
};

export default ContactLens;

const StyledIcon = styled(contacts)<Props>`
  & ellipse {
    fill: ${({color}) => LightenDarkenColor(color, 60)};
  }

  & path:first-child {
    fill: ${({color}) => color};
  }
`;
