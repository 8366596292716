import React, {useContext} from 'react';
import styled, {ThemeContext} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {StaticContext, RouteComponentProps, useHistory} from 'react-router';

import {Wrapper} from '../../atoms/Layout';
import {Title, Subtitle} from '../../atoms/Typography';
import Button from '../../atoms/Button';
import StoreLogo from '../../atoms/StoreLogo';
import {useAppState} from '../provider/AppStateProvider';
import {postAbortSession} from '../../api/user';

type Props = RouteComponentProps<
  {},
  StaticContext,
  {issue?: string; title?: string}
>;

const QualificationFail: React.FC<Props> = props => {
  const theme = useContext(ThemeContext);
  const {t} = useTranslation();
  const {state} = useAppState();
  const history = useHistory();

  const backToStore = () => {
    if (state.failRedirectUrl) {
      window.location.href = state.failRedirectUrl;
    } else {
      history.push('/');
    }
  };

  const issue = props.location.state?.issue;
  const title = props.location.state?.title;

  if (title?.match('age') && state.sessionId) {
    postAbortSession(state.sessionId);
  }

  return (
    <Wrapper dark footer header={<StoreLogo inverted />}>
      <Container>
        <FailTitle>{title ? t(title) : t('checkQualify.fail.title')}</FailTitle>
        <Description>
          {issue ? t(issue) : t('checkQualify.fail.healthIssue')}
        </Description>
        <Subtitle>
          {t('checkQualify.fail.info')}
          {/* TODO: add mail address */}
          <Mail href="mailto:">{t('checkQualify.fail.contact')}</Mail>
        </Subtitle>
        <StyledButton onClick={backToStore}>
          {t('action.backToStore', {
            storeName: theme.meta.name,
          })}
        </StyledButton>
      </Container>
    </Wrapper>
  );
};

export default QualificationFail;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 96px 48px;

  @media ${props => props.theme.breakpoints.mobile} {
    padding: 0;
    padding-bottom: 48px;
  }
`;
const FailTitle = styled(Title)`
  margin-bottom: 24px;
  white-space: pre-line;

  @media ${props => props.theme.breakpoints.mobile} {
    text-align: left;
  }
`;
const Mail = styled.a`
  text-decoration: underline;
  color: ${props => props.theme.colors.white};
`;
const Description = styled(Subtitle)`
  white-space: pre-line;
  color: ${({theme}) => theme.colors.grey200};
`;

const StyledButton = styled(Button)`
  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
  }
`;
