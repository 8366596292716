import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import IconWithText from '../components/IconWithText';
import {Wrapper, ContentLeft, ContentRight} from '../../atoms/Layout';
import {Title, Subtitle} from '../../atoms/Typography';
import Button from '../../atoms/Button';
import BackLink from '../components/BackLink';
import lenses from '../../assets/icons/lenses.svg';
import sun from '../../assets/icons/sun.svg';
import distance from '../../assets/icons/distance.svg';
import StepCalculator from '../components/Modal/StepCalculatorModal';

interface Props {
  onContinue: () => Promise<void>;
}

const TestInstruction: React.FC<Props> = props => {
  const {t} = useTranslation();
  const history = useHistory();
  const [helpModal, setHelpModal] = useState(false);

  const handleTestStart = async () => {
    try {
      await props.onContinue();
    } catch (e) {
      //TODO: redirect to screen permission
    }
  };

  const toggleHelpModal = () => {
    setHelpModal(!helpModal);
  };

  return (
    <Wrapper light="left" header={<BackLink />} footer>
      <ContentLeft>
        <Title>{t('test.instruction.title')}</Title>
      </ContentLeft>
      <ContentRight>
        <Subtitle>{t('test.instruction.description')}</Subtitle>
        <Subtitle>{t('test.instruction.toStart')}</Subtitle>

        <IconWithText icon={lenses} text={t('test.instruction.step1')} />
        <IconWithText icon={sun} text={t('test.instruction.step2')} />
        <IconWithText icon={distance} text={t('test.instruction.step3')} />

        <Row>
          <Button onClick={toggleHelpModal} inverted>
            {t('test.instruction.help')}
          </Button>
          <Button onClick={handleTestStart}>
            {t('test.instruction.start')}
          </Button>
        </Row>
      </ContentRight>

      {helpModal && <StepCalculator onClose={toggleHelpModal} />}
    </Wrapper>
  );
};

export default TestInstruction;

const Row = styled.div`
  margin-top: 44px;
  display: flex;
  justify-content: space-between;

  @media ${props => props.theme.breakpoints.mobile} {
    padding-bottom: 44px;
    display: block;

    & button {
      width: 100%;
    }

    & button:first-child {
      margin-bottom: 24px;
    }
  }
`;
