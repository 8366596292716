import React from 'react';
// import styled from 'styled-components';
import {useField} from 'react-final-form';
import {useTranslation} from 'react-i18next';
import Input from '../../../atoms/Form/Input';

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  validate?: () => string | undefined;
  fullWidth?: boolean;
  initialValue?: string | undefined | boolean;
  disabled?: boolean;
  type?: string;
  maxLength?: string;
}

const TextField: React.FC<Props> = ({name, ...props}) => {
  const {input, meta} = useField(name, {...props});
  const {t} = useTranslation();

  const error =
    (meta.touched && meta.error) ||
    (!meta.dirtySinceLastSubmit && meta.submitError);

  return (
    <Input
      {...input}
      {...props}
      error={error}
      helperText={t(error)}
      inputProps={{maxLength: props.maxLength}}
    />
  );
};

export default TextField;
