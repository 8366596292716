import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Form} from 'react-final-form';
import {useTranslation} from 'react-i18next';

import SelectField from '../Fields/SelectField';
import RadioField from '../Fields/RadioField';
import Button from '../../../atoms/Button';
import {Title, Subtitle, Heading} from '../../../atoms/Typography';
import {SIZE_OPTIONS} from '../../../constants/shoeSize';
import {getDistanceInSteps} from '../../../utils/deviceDistance';
import close from '../../../assets/icons/close-dark.svg';

interface Props {
  onClose: () => void;
}

const GENDER_OPTIONS = [
  {value: 'male', label: 'checkQualify.gender.male'},
  {value: 'female', label: 'checkQualify.gender.female'},
];

const StepCalculator: React.FC<Props> = ({onClose}) => {
  const {t} = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <ModalContainer>
        <Row>
          <StyledTitle>{t('test.measuring.title')}</StyledTitle>
          <CloseButton onClick={onClose} />
        </Row>
        <Form
          onSubmit={onClose}
          render={({handleSubmit, values}) => {
            const steps = getDistanceInSteps(values);

            return (
              <FormContainer>
                <SpacedCol>
                  <Col>
                    <StyledSubtitle>
                      {t('test.measuring.selectSize')}
                    </StyledSubtitle>
                    <SelectField
                      options={SIZE_OPTIONS}
                      name="shoeSize"
                      label={t('test.measuring.size')}
                    />
                    <RadioField name="gender" options={GENDER_OPTIONS} />
                  </Col>

                  <StyledButton onClick={handleSubmit} disabled={!steps}>
                    {t('action.gotIt')}
                  </StyledButton>
                </SpacedCol>

                <Box isActive={!!steps}>
                  <StyledHeading>{t('test.measuring.distance')}</StyledHeading>
                  <Title>
                    {steps || '-'} {t('test.measuring.steps')}
                  </Title>
                </Box>
                <MobileButton onClick={handleSubmit} disabled={!steps}>
                  {t('action.gotIt')}
                </MobileButton>
              </FormContainer>
            );
          }}
        />
      </ModalContainer>
    </Container>
  );
};

export default StepCalculator;

const Container = styled.div`
  position: fixed;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);

  @media ${props => props.theme.breakpoints.mobile} {
    position: absolute;
  }
`;

const ModalContainer = styled.div`
  padding: 48px;
  width: 832px;
  min-height: 520px;
  border-radius: 24px;
  background-color: ${({theme}) => theme.colors.white};
  box-shadow: 0 10px 30px 0 rgba(31, 35, 39, 0.2);

  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
    border-radius: 0;
    padding: 24px;
    padding-bottom: 56px;
    position: absolute;
    top: 0;
  }
`;

const StyledTitle = styled(Title)`
  max-width: 461px;

  @media ${props => props.theme.breakpoints.mobile} {
    text-align: left;
  }
`;

const FormContainer = styled.div`
  display: flex;

  @media ${props => props.theme.breakpoints.mobile} {
    flex-direction: column;
  }
`;

const Col = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const SpacedCol = styled(Col)`
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  align-self: flex-start;

  @media ${props => props.theme.breakpoints.mobile} {
    display: none;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 48px;
`;

const StyledSubtitle = styled(Subtitle)`
  margin-bottom: 16px;
`;

const CloseButton = styled.img.attrs({src: close})`
  cursor: pointer;
`;

const Box = styled.div<{isActive: boolean}>`
  margin-left: 71px;
  width: 454px;
  height: 256px;
  border-radius: 30px;
  padding: 36px;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.colors.grey100};

  & > h1,
  h3 {
    opacity: ${({isActive}) => (isActive ? 1 : 0.3)};
  }

  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
    margin-left: 0;
    height: 300px;
    margin-top: 36px;
    padding: 27px;
  }
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 37px;
`;

const MobileButton = styled(Button)`
  display: none;
  margin-top: 36px;

  @media ${props => props.theme.breakpoints.mobile} {
    display: block;
  }
`;
