import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import arrow from '../../../assets/icons/round-arrow.svg';
import {Text} from '../../../atoms/Typography';
import {DIRECTIONS} from './';

interface Direction {
  direction: DIRECTIONS;
}

interface Props extends Direction {
  src: string;
}

const ImagePreview: React.FC<Props> = ({src, direction}) => {
  const {t} = useTranslation();

  const directionTextMap = {
    [DIRECTIONS.LEFT]: t('redness.left'),
    [DIRECTIONS.RIGHT]: t('redness.right'),
    [DIRECTIONS.UP]: t('redness.up'),
  };

  return (
    <Container>
      <LabelContainer>
        <Arrow direction={direction} />
        <DirectionText bold>{directionTextMap[direction]}</DirectionText>
      </LabelContainer>

      <Image src={src} />
    </Container>
  );
};

export default ImagePreview;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${props => props.theme.breakpoints.mobile} {
    margin-bottom: 52px;
  }
`;

const LabelContainer = styled.div`
  @media ${props => props.theme.breakpoints.mobile} {
    display: flex;
  }
`;

const Arrow = styled.img.attrs({src: arrow})<Direction>`
  height: 32px;
  width: 32px;
  opacity: 0.35;

  transform: ${({direction}) => {
    if (direction === 0) {
      return 'rotate(0deg)';
    }

    if (direction === 1) {
      return 'rotate(180deg)';
    }

    return 'rotate(90deg)';
  }};

  @media ${props => props.theme.breakpoints.mobile} {
    margin-right: 16px;
  }
`;

const DirectionText = styled(Text)`
  opacity: 0.5;
  margin: 7px 0 16px;
`;

const Image = styled.img`
  width: 261px;
  height: 141px;
  border-radius: 30px;
  object-fit: cover;
`;
