import React, {useContext} from 'react';
import styled, {ThemeContext} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {useAppState} from '../provider/AppStateProvider';

import {Wrapper, ContentLeft, ContentRight} from '../../atoms/Layout';
import Button from '../../atoms/Button';
import {Title, Subtitle} from '../../atoms/Typography';
import StoreLogo from '../../atoms/StoreLogo';

import thanks from '../../assets/images/thank-you.svg';

const Thanking: React.FC = () => {
  const {t} = useTranslation();
  const {state} = useAppState();
  const history = useHistory();
  const theme = useContext(ThemeContext);

  const backToStore = () => {
    if (state.successRedirectUrl) {
      window.location.href = state.successRedirectUrl;
    } else {
      history.push('/');
    }
  };

  return (
    <Wrapper header={<StoreLogo />} footer>
      <StyledContentLeft>
        <CustomTitle>{t('test.finish.thankTitle')}</CustomTitle>
        <StyledSubtitle>
          {t('test.finish.thankDescriptionFirst')}
        </StyledSubtitle>

        <CustomButton onClick={backToStore}>
          {t('action.goBack', {
            storeName: theme.meta.name,
          })}
        </CustomButton>
      </StyledContentLeft>

      <StyledContentRight>
        <MockAsset />
      </StyledContentRight>
    </Wrapper>
  );
};

export default Thanking;

const StyledContentLeft = styled(ContentLeft)`
  padding-right: 0;
  @media ${props => props.theme.breakpoints.mobile} {
    position: relative;
    z-index: 1;
    margin-top: 8vh;
  }
`;

const CustomTitle = styled(Title)`
  @media ${props => props.theme.breakpoints.mobile} {
    text-align: left;
  }
`;

const CustomButton = styled(Button)`
  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
    max-width: calc(100% - 48px);
  }
`;

const StyledSubtitle = styled(Subtitle)`
  margin: 24px 0 52px;
  @media ${props => props.theme.breakpoints.mobile} {
    width: 176px;
  }
`;

const StyledContentRight = styled(ContentRight)`
  padding: 0;
`;

const MockAsset = styled.div`
  width: 488px;
  height: 488px;
  background: url(${thanks}) no-repeat center center;
  background-size: contain;
  @media ${props => props.theme.breakpoints.mobile} {
    width: 160px;
    height: 250px;
    background-size: cover;
    background-position: left top;
    position: fixed;
    right: 0;
    top: 120px;
    z-index: 0;
  }
`;
