import https, {API_URL} from './https';
import {OptionType} from '../app/components/Fields/SelectField';

export const fetchNations = async (): Promise<OptionType[]> => {
  try {
    const response = await https.get(`${API_URL}/states`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
