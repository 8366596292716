import React from 'react';
import {Form} from 'react-final-form';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

import {Wrapper, ContentRight, ContentLeft} from '../../../atoms/Layout';
import BackLink from '../../components/BackLink';
import TextField from '../../components/Fields/TextField';
import {useAppState} from '../../provider/AppStateProvider';
import {
  required,
  requiredEmail,
  requiredPhoneNumber,
} from '../../../utils/validation';
import Button from '../../../atoms/Button';
import {Title, Subtitle} from '../../../atoms/Typography';

const Name: React.FC = () => {
  const {t} = useTranslation();
  const appState = useAppState();
  const history = useHistory();

  const onSubmit = (data: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  }): void => {
    appState.updateState({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
    });
    history.push('/nation');
  };

  return (
    <Wrapper light="left" header={<BackLink />} footer>
      <ContentLeft>
        <Title>{t('personalData.title')}</Title>
      </ContentLeft>

      <ContentRight>
        <Form
          initialValues={{
            firstName: appState.state.firstName || '',
            lastName: appState.state.lastName || '',
            email: appState.state.email || '',
            phoneNumber: appState.state.phoneNumber || '',
          }}
          onSubmit={onSubmit}
          render={({
            handleSubmit,
            hasValidationErrors,
            initialValues,
          }): JSX.Element => (
            <>
              <Subtitle>{t('personalData.subtitle')}</Subtitle>
              <TextField
                name="firstName"
                validate={required}
                disabled={
                  !!appState.state.incomingData?.find(
                    data => data === 'firstName'
                  )
                }
                label={t('personalData.firstName')}
                fullWidth
              />
              <TextField
                name="lastName"
                validate={required}
                disabled={
                  !!appState.state.incomingData?.find(
                    data => data === 'lastName'
                  )
                }
                label={t('personalData.lastName')}
                fullWidth
              />
              <TextField
                name="email"
                validate={requiredEmail}
                disabled={
                  !!appState.state.incomingData?.find(data => data === 'email')
                }
                label={t('personalData.email')}
                fullWidth
                type="email"
              />
              <TextField
                name="phoneNumber"
                validate={requiredPhoneNumber}
                disabled={
                  !!appState.state.incomingData?.find(
                    data => data === 'phoneNumber'
                  )
                }
                label={t('personalData.phoneNumber')}
                fullWidth
              />

              <StyledButton
                onClick={handleSubmit}
                disabled={hasValidationErrors}
              >
                {t('action.continue')}
              </StyledButton>
            </>
          )}
        />
      </ContentRight>
    </Wrapper>
  );
};

export default Name;

const StyledButton = styled(Button)`
  display: block;
  margin-top: 24px;

  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
    margin-bottom: 50px;
  }
`;
