import validate from 'validate.js';
import i18n from '../i18n/i18n';

validate.format = (string, params) => {
  return i18n.t(string, params);
};

validate.validators.numericality.options = {
  notGreaterThanOrEqualTo: 'validation.tooSmall',
  notLessThanOrEqualTo: 'validation.tooBig',
  notValid: 'validation.requiredNumber',
};
validate.validators.presence.options = {message: 'validation.presence'};
validate.validators.email.options = {
  message: 'validation.email',
};
validate.validators.length.options = {
  wrongLength: 'validation.length',
};

export const required = (value?: string) =>
  validate.single(value, {
    presence: {
      allowEmpty: false,
    },
  });

export const requiredEmail = (value?: string) =>
  validate.single(value, {
    email: true,
    presence: {
      allowEmpty: false,
    },
  });

export const requiredCheck = (value?: boolean) =>
  validate.single(value, {
    presence: {
      message: 'validation.check',
    },
    inclusion: {
      within: [true],
      message: 'validation.check',
    },
  });

export const requiredLenght = (length: 4) => (value?: string) =>
  validate.single(value, {
    length: {
      is: length,
    },
  });

export const requiredInterval = (
  from: number,
  to: number,
  needPresence?: boolean
) => (value?: string) =>
  validate.single(value, {
    numericality: {
      greaterThanOrEqualTo: from,
      lessThanOrEqualTo: to,
    },
    presence: needPresence ? {allowEmpty: false} : false,
  });

const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const requiredPhoneNumber = (value?: string) =>
  validate.single(value, {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: phoneRegex,
      message: 'validation.invalidPhoneNumber',
    },
  });
