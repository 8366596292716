import React from 'react';
import ReactDOM from 'react-dom';

import CatcherInTheRye from './errorCatcher';
import {GlobalStyle} from './theme';
import App from './app';

import './polifill.js';

// STEP: prepare store
ReactDOM.render(
  <CatcherInTheRye>
    <GlobalStyle />
    <App />
  </CatcherInTheRye>,
  document.getElementById(`root`)
);
