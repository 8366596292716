import React from 'react';
import styled from 'styled-components';
import {useAppState} from '../provider/AppStateProvider';

import cmToPix from '../../utils/cmToPix';

/**
 * This is the magic number that we need to remove
 * white space around OpticianSans' letters.
 *
 * Calculation: 5.398cm are 549.37px as font-size
 * 549.37 / cmToPix(5.398) = 1.974
 *
 * For more information, see: https://iamvdo.me/en/blog/css-font-metrics-line-height-and-vertical-align
 */
const OPTICIAN_SANS_MAGIC_NUMBER = 1.974;

type Props = {
  dimensions: number;
  characther: string;
};

type LetterProps = {
  size: number;
};

const Letter: React.FC<Props> = props => {
  const {state} = useAppState();

  if (!state.pixelRatio) {
    // we should avoid being here
    return null;
  }

  const size = state.pixelRatio * props.dimensions;
  return <TestLetter size={cmToPix(size)}>{props.characther}</TestLetter>;
};

export default Letter;

const TestLetter = styled.p<LetterProps>`
  display: flex;
  font-size: ${props => `${props.size * OPTICIAN_SANS_MAGIC_NUMBER}px`};
  font-family: 'OpticianSans';
`;
