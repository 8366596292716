import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {breakpoints} from '../../../theme/common';

import {useAppState} from '../../provider/AppStateProvider';
import {Wrapper} from '../../../atoms/Layout';
import {Title} from '../../../atoms/Typography';
import Button from '../../../atoms/Button';
import Range from '../../../atoms/Form/Range';
import BackLink from '../../components/BackLink';

import CreditCard from '../../../assets/images/blue-credit-card.svg';
import {ReactComponent as Add} from '../../../assets/icons/blue-add.svg';
import {ReactComponent as Remove} from '../../../assets/icons/blue-remove.svg';

import cmToPix from '../../../utils/cmToPix';

const CC_WIDTH = 8.6; //cm
const PIXEL_WIDTH = cmToPix(CC_WIDTH);

const CC_HEIGHT = 5.398; //cm
const PIXEL_HEIGHT = cmToPix(CC_HEIGHT);

const isMobile = window.matchMedia(breakpoints.mobile).matches;

export default function Meter() {
  const appState = useAppState();
  const history = useHistory();
  const [ratio, setRatio] = useState(
    appState.state.pixelRatio || (isMobile ? 1.5 : 1)
  );
  const {t} = useTranslation();

  const onSave = () => {
    appState.updateState({pixelRatio: ratio});
    history.push('/test');
  };

  const step = 0.001;
  const buttonStep = 0.025;
  const min = isMobile ? 1 : 0.5;
  const max = isMobile ? 2 : 1.5;

  const handleChange = (e: any, value: any) => {
    setRatio(parseFloat(value));
  };

  const increaseRange = () => {
    setRatio(ratio + buttonStep > max ? max : ratio + buttonStep);
  };

  const decreaseRange = () => {
    setRatio(ratio - buttonStep < min ? min : ratio - buttonStep);
  };

  return (
    <Wrapper light="bottom" header={<BackLink />}>
      <Content>
        <TopContainer>
          <CardTitle>{t('test.screenSize.card')}</CardTitle>

          <MobileImgWrapper cardWidth={PIXEL_WIDTH * ratio}>
            <img
              height={(PIXEL_HEIGHT * ratio).toString()}
              width={(PIXEL_WIDTH * ratio).toString()}
              src={CreditCard}
              alt="credit card indicator"
            />
          </MobileImgWrapper>
        </TopContainer>

        <BottomContainer>
          <SliderRow>
            <RoundButton onClick={decreaseRange}>
              <RemoveIcon />
            </RoundButton>
            <Range
              step={step}
              min={min}
              max={max}
              value={ratio}
              onChange={handleChange}
            />
            <RoundButton onClick={increaseRange}>
              <AddIcon />
            </RoundButton>
          </SliderRow>

          <CustomButton onClick={onSave}>
            {t('test.screenSize.match')}
          </CustomButton>
        </BottomContainer>
      </Content>
    </Wrapper>
  );
}

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 64px 96px 48px;
  min-height: 100%;

  @media ${props => props.theme.breakpoints.mobile} {
    padding: 34px 0;
  }
`;

const CustomButton = styled(Button)`
  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const MobileImgWrapper = styled.div<{cardWidth: number}>`
  @media ${props => props.theme.breakpoints.mobile} {
    position: absolute;
    left: -${props => props.cardWidth - 220}px;
    bottom: -150px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const BottomContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  @media ${props => props.theme.breakpoints.mobile} {
    position: relative;
    min-height: 400px;
  }
`;

const CardTitle = styled(Title)`
  font-size: 32px;
  text-align: center;
  margin-bottom: 66px;
  font-weight: 500;
  white-space: pre-line;
  @media ${props => props.theme.breakpoints.mobile} {
    font-size: 32px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    margin-bottom: 40px;
  }
`;

const SliderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 536px;
  margin-bottom: 64px;
  align-items: center;
  @media ${props => props.theme.breakpoints.mobile} {
    margin-bottom: 0;
    height: 320px;
    width: 48px;
    flex-direction: column-reverse;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

const RoundButton = styled.button`
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
  border-radius: 24px;
  box-shadow: 0 10px 30px 0 rgba(123, 138, 154, 0.1),
    0 2px 4px 0 rgba(162, 171, 181, 0.1);
  background-color: ${props => props.theme.colors.white};
  border: none;
  outline: none;
`;

const AddIcon = styled(Add).attrs(({theme}) => ({
  fill: theme.colors.main,
}))`
  color: ${props => props.theme.colors.primary};
`;

const RemoveIcon = styled(Remove).attrs(({theme}) => ({
  fill: theme.colors.main,
}))`
  width: 18px;
  height: 18px;
`;
