import React, {useCallback} from 'react';
import styled from 'styled-components';
import {Form} from 'react-final-form';
import {useTranslation} from 'react-i18next';

import TextField from './Fields/TextField';
import Button from '../../atoms/Button';
import {Title, Subtitle, Text, FooterText} from '../../atoms/Typography';
import home from '../../assets/home.png';
import orientation from '../../assets/images/orientation.svg';
import {Wrapper} from '../../atoms/Layout';

interface IsMobile {
  isMobile: boolean;
}

const MobileStop: React.FC<IsMobile> = ({isMobile}) => {
  const {t} = useTranslation();

  const onSubmit = useCallback(() => {
    //@ TODO: handle submit
  }, []);

  return (
    <Wrapper footer>
      <Container>
        <StyledTitle>{t('mobile.welcome')}</StyledTitle>
        <ImageContainer>
          <StyledSubtitle>{t('mobile.renew')}</StyledSubtitle>

          <Image />
        </ImageContainer>

        {isMobile ? (
          <Box>
            <DarkText>{t('mobile.biggerScreen')}</DarkText>
            <StyledText>{t('mobile.provideEmail')}</StyledText>

            <Form
              onSubmit={onSubmit}
              render={({handleSubmit, submitting, hasValidationErrors}) => {
                return (
                  <>
                    <TextField
                      name="email"
                      fullWidth
                      label={t('mobile.email')}
                    />
                    <Button
                      onClick={handleSubmit}
                      fullWidth
                      disabled={submitting || hasValidationErrors}
                    >
                      {t('mobile.send')}
                    </Button>
                  </>
                );
              }}
            />
          </Box>
        ) : (
          <TabletBox>
            <Orientation />
            <BoldDarkText>{t('mobile.rotate')}</BoldDarkText>
          </TabletBox>
        )}
      </Container>
    </Wrapper>
  );
};

export default MobileStop;

const Container = styled.div`
  width: 100%;

  @media ${props => props.theme.breakpoints.tablet} {
    padding: 48px;
  }

  @media ${props => props.theme.breakpoints.mobile} {
    padding: 24px;
  }
`;

const StyledTitle = styled(Title)`
  font-size: 58px;
  line-height: 60px;
  letter-spacing: -1.4px;
  max-width: 369px;
  position: relative;
  z-index: 1;

  @media ${props => props.theme.breakpoints.mobile} {
    font-size: 48px;
    line-height: 50px;
  }
`;

const ImageContainer = styled.div`
  min-height: 112px;
  width: 100%;
  position: relative;
  margin-bottom: 64px;
  z-index: 0;
`;

const Image = styled.img.attrs({src: home})`
  height: 488px;

  width: auto;
  position: absolute;
  left: 400px;

  top: -95px;
  z-index: 0;

  @media ${props => props.theme.breakpoints.mobile} {
    height: 250px;
    left: 176px;
  }
`;

const StyledSubtitle = styled(Subtitle)`
  margin-top: 32px;
  max-width: 321px;

  @media ${props => props.theme.breakpoints.mobile} {
    max-width: 176px;
  }
`;

const Box = styled.div`
  width: 100%;
  margin-bottom: 48px;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 30px;
  box-shadow: 0 10px 30px 0 rgba(123, 138, 154, 0.1);
  background-color: ${({theme}) => theme.colors.white};
`;

const TabletBox = styled.div`
  display: flex;
  width: 343px;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 30px;
  box-shadow: 0 10px 30px 0 rgba(123, 138, 154, 0.1);
  background-color: ${({theme}) => theme.colors.white};
`;

const StyledText = styled(Text)`
  margin-bottom: 20px;
`;

const DarkText = styled(StyledText)`
  color: ${({theme}) => theme.colors.grey1000};
`;

const BoldDarkText = styled(DarkText)`
  margin: 0;
  margin-top: 12px;
  font-weight: 900;
`;

const FooterContainer = styled.div<IsMobile>`
  width: 100%;
  display: flex;
  justify-content: ${({isMobile}) => (isMobile ? 'center' : 'space-between')};
  align-items: center;
  flex-wrap: wrap;

  & > span:first-child {
    ${({isMobile}) =>
      isMobile &&
      `
      text-align: center;
      width: 100%;
    `}
  }
`;

const FooterLink = styled(FooterText)`
  margin: 8px;
`;

const Orientation = styled.img.attrs({src: orientation})`
  width: 90px;
  height: 74px;
  margin-right: 24px;
`;
