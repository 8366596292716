import React from 'react';
import styled from 'styled-components';

interface Props {
  onClick: () => void;
  children: JSX.Element;
}

const SecondaryButton: React.FC<Props> = ({onClick, children, ...rest}) => (
  <Container {...rest} onClick={onClick}>
    {children}
  </Container>
);

export default SecondaryButton;

const Container = styled.div`
  text-align: center;
  cursor: pointer;
  padding: 20px;
  border-radius: 24px;
  box-shadow: 0 10px 30px 0 rgba(123, 138, 154, 0.1),
    0 2px 4px 0 rgba(162, 171, 181, 0.05);

  color: ${({theme}) => theme.colors.blue};
  font-weight: 900;
  background-color: ${({theme}) => theme.colors.white};
`;
