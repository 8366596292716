import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import Letter from './Letter';
import CountDown from '../../atoms/CountDown';
import Button from '../../atoms/Button';
import {Title} from '../../atoms/Typography';
import {LettersStep} from '../../types';

interface Props {
  endTime: number;
  step: LettersStep;
  onCancel: () => void;
  timerReady: boolean;
  volumeRef: any;
}

const TestLetters: React.FC<Props> = ({
  step,
  endTime,
  onCancel,
  timerReady,
  volumeRef,
}) => {
  const {t} = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Inside>
        <Button onClick={onCancel} grey>
          {t('test.cancel')}
        </Button>
        <div>
          <StyledTitle>{t('test.read')}</StyledTitle>
          <LettersContainer>
            {step.letters?.split('').map(char => (
              <Letter
                key={char}
                dimensions={step.dimension}
                characther={char}
              />
            ))}
          </LettersContainer>
        </div>
        <CountDown
          key={step.id}
          disabled={!timerReady}
          endTime={endTime}
          withCircles
          initialTime={step.duration / 1000}
          volumeRef={volumeRef}
        />
      </Inside>
    </Container>
  );
};

export default TestLetters;

const CountDownPlaceHolder = styled.div`
  height: 172px;
`;

const Container = styled.div<{firstStep?: boolean}>`
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  padding: 48px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
      to bottom,
      ${({theme}) => theme.colors.white},
      rgba(255, 255, 255, 0) 71%
    ),
    linear-gradient(
      to bottom,
      ${({theme}) => theme.colors.wrapperBackgroundBottom},
      ${({theme}) => theme.colors.wrapperBackgroundBottom}
    );

  @media ${props => props.theme.breakpoints.mobile} {
    padding: 16px;
  }
`;

const Inside = styled.div`
  max-width: 1024px;
  max-height: 768px;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitle = styled(Title)`
  font-weight: normal;
  margin-bottom: 32px;
`;

const LettersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
`;
