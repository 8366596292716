import React from 'react';
import {Form} from 'react-final-form';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

import {Wrapper, ContentRight, ContentLeft} from '../../../atoms/Layout';
import BackLink from '../../components/BackLink';
import SelectField from '../../components/Fields/SelectField';
import TextField from '../../components/Fields/TextField';
import {useAppState} from '../../provider/AppStateProvider';
import {required} from '../../../utils/validation';
import {GENDERS} from '../../../constants/user';
import Button from '../../../atoms/Button';
import {Title, Subtitle} from '../../../atoms/Typography';

const BasicInfo: React.FC = () => {
  const {t} = useTranslation();
  const appState = useAppState();
  const history = useHistory();

  const onSubmit = (data: {age: string; gender: string}): void => {
    if (parseInt(data.age) < 18) {
      return history.push('/stop', {
        title: 'checkQualify.fail.ageTitle',
      });
    }

    appState.updateState({
      age: data.age,
      gender: data.gender,
    });

    history.push('/health-history');
  };

  const isStepValid = (errors: {
    age?: string[];
    gender?: string[];
  }): boolean => {
    return !errors.age && !errors.gender;
  };

  return (
    <Wrapper light="left" header={<BackLink />} footer>
      <ContentLeft>
        <Title>{t('checkQualify.title')}</Title>
      </ContentLeft>

      <ContentRight>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            age: appState.state.age || '',
            gender: appState.state.gender || '',
          }}
          render={({handleSubmit, errors}): JSX.Element => (
            <>
              <Subtitle>{t('checkQualify.ageAndGenederDescription')}</Subtitle>
              <TextField
                name="age"
                disabled={
                  !!appState.state.incomingData?.find(data => data === 'age')
                }
                validate={required}
                label={t('checkQualify.ageLabel')}
                fullWidth
                type="number"
              />
              <Select
                name="gender"
                disabled={
                  !!appState.state.incomingData?.find(data => data === 'gender')
                }
                options={GENDERS}
                validate={required}
                label={t('checkQualify.genderLabel')}
              />
              <StyledButton
                onClick={handleSubmit}
                disabled={!isStepValid(errors)}
              >
                {t('action.continue')}
              </StyledButton>
            </>
          )}
        />
      </ContentRight>
    </Wrapper>
  );
};

export default BasicInfo;

const Select = styled(SelectField)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  display: block;
  margin-top: 24px;

  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
    margin-bottom: 50px;
  }
`;
