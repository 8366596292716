import React from 'react';
import ReactDom from 'react-dom';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import Button from '../../../atoms/Button';
import {Heading, Subtitle} from '../../../atoms/Typography';
import close from '../../../assets/icons/close-dark.svg';

interface Props {
  visible: boolean;
  onChangePeriod: () => void;
  onGoBack: () => void;
}

const LastExamModal: React.FC<Props> = ({visible, onChangePeriod, onGoBack}) => {
  const {t} = useTranslation();

  if (!visible) {
    return null;
  }

  const root = document.getElementById('root');

  return (
    root &&
    ReactDom.createPortal(
      <Container>
        <ModalContainer>
          <CloseButton onClick={onChangePeriod} />
          <TextContainer>
            <StyledTitle>{t('test.cannotContinue.title')}</StyledTitle>
            <StyledSubtitle>{t('test.cannotContinue.subtitle')}</StyledSubtitle>
          </TextContainer>
          <Row>
            <StyledButton onClick={onChangePeriod} inverted>
              {t('test.cannotContinue.change')}
            </StyledButton>
            <StyledButton onClick={onGoBack}>{t('test.cannotContinue.goBack')}</StyledButton>
          </Row>
        </ModalContainer>
      </Container>,
      root
    )
  );
};

export default LastExamModal;

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

const ModalContainer = styled.div`
  overflow: hidden;
  width: 832px;
  border-radius: 24px;
  background-color: ${({theme}) => theme.colors.white};
  box-shadow: 0 10px 30px 0 rgba(31, 35, 39, 0.2);
  position: relative;

  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
    min-height: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: visible;
  }
`;

const TextContainer = styled.div`
  padding: 48px 48px 16px;
  @media ${props => props.theme.breakpoints.mobile} {
    padding: 24px;
  }
`;

const StyledTitle = styled(Heading)`
  margin-bottom: 32px;
  font-weight: 700;
  font-size: 26px;
  letter-spacing: -0.72px;
  line-height: 40px;
`;

const StyledSubtitle = styled(Subtitle)`
  margin-bottom: 12px;
  font-weight: lighter;
`;

const Row = styled.div`
  padding: 48px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: ${({theme}) => theme.colors.grey100};

  @media ${props => props.theme.breakpoints.mobile} {
    display: block;
  }
`;

const StyledButton = styled(Button)`
  margin: 0 24px;
  min-width: 252px;

  @media ${props => props.theme.breakpoints.mobile} {
    min-width: 100%;
    margin: 0;
    margin-bottom: 24px;
  }
`;

const CloseButton = styled.img.attrs({src: close})`
  cursor: pointer;
  position: absolute;
  top: 48px;
  right: 48px;
`;
