import React from 'react';
import styled from 'styled-components';
import {useField} from 'react-final-form';
import {useTranslation} from 'react-i18next';
import {RadioGroup, FormControlLabel, Radio} from '@material-ui/core';

import {FooterText} from '../../../atoms/Typography';

interface Option {
  label: string;
  value: string | boolean;
}

interface Props {
  name: string;
  options: Option[];
  validate?: () => string | undefined;
}

const RadioField: React.FC<Props> = ({name, options, ...rest}) => {
  const {t} = useTranslation();
  const {input, meta} = useField(name, {type: 'radio', ...rest});

  const error =
    (meta.touched && meta.error) ||
    (!meta.dirtySinceLastSubmit && meta.submitError);

  return (
    <>
      <StyledRadioGroup value={input.value} onChange={input.onChange}>
        {options.map(option => (
          <FormControlLabel
            key={option.label}
            value={option.value}
            control={<Radio color="primary" />}
            label={t(option.label)}
          />
        ))}
      </StyledRadioGroup>

      {error && <ErrorText>{t(error)}</ErrorText>}
    </>
  );
};

export default RadioField;

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
`;

const ErrorText = styled(FooterText)`
  color: ${({theme}) => theme.colors.red};
`;
