import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {Wrapper, ContentLeft, ContentRight} from '../../atoms/Layout';
import Button from '../../atoms/Button';
import {Title, Subtitle} from '../../atoms/Typography';
import StoreLogo from '../../atoms/StoreLogo';
import home from '../../assets/home@3x.png';

const Welcome: React.FC = () => {
  const {t} = useTranslation();
  const history = useHistory();

  const proceed = useCallback(() => history.push('/how-it-works'), []);

  return (
    <StyledWrapper header={<StoreLogo />} footer>
      <StyledContentLeft>
        <CustomTitle>{t('welcome.title')}</CustomTitle>
        <StyledSubtitle>{t('welcome.subtitle')}</StyledSubtitle>

        <CustomButton onClick={proceed}>{t('welcome.start')}</CustomButton>
      </StyledContentLeft>

      <StyledContentRight>
        <WelcomeImage />
      </StyledContentRight>
    </StyledWrapper>
  );
};

export default Welcome;

const StyledWrapper = styled(Wrapper)`
  position: relative;
  overflow: hidden;
  padding: 0;

  @media ${props => props.theme.breakpoints.mobile} {
    & header {
      margin-left: 24px;
    }
  }
`;

const StyledContentLeft = styled(ContentLeft)`
  padding-right: 0;
  @media ${props => props.theme.breakpoints.mobile} {
    z-index: 1;
    padding: 0 24px;
  }
`;

const CustomTitle = styled(Title)`
  @media ${props => props.theme.breakpoints.mobile} {
    text-align: left;
    max-width: 300px;
  }
`;

const CustomButton = styled(Button)`
  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
    margin-top: 44px;
  }
`;

const StyledSubtitle = styled(Subtitle)`
  margin: 24px 0 32px;
  @media ${props => props.theme.breakpoints.mobile} {
    width: 176px;
  }
`;

const StyledContentRight = styled(ContentRight)`
  padding: 0;
`;

const WelcomeImage = styled.div`
  width: 477px;
  height: 417px;
  background: url(${home}) no-repeat center center;
  background-size: contain;

  @media ${props => props.theme.breakpoints.mobile} {
    width: 250px;
    height: 250px;

    background-size: cover;
    background-position: left top;
    position: absolute;
    left: 200px;
    top: 230px;
    z-index: 0;
  }
`;
