import merge from 'deepmerge';
import {createMuiTheme} from '@material-ui/core/styles';

import * as commonTheme from './common';
import targetOpticalLogo from '../assets/images/target-optical@3x.png';

export const colors = {
  primary: {
    first: '#d2202f',
  },
};

export const breakpoints = {};

export const shadows = {};

export const muiTheme = {
  palette: {
    primary: {
      main: '#d2202f',
    },
  },
};

const meta = {
  name: 'Target Optical',
  logo: targetOpticalLogo,
};

export default {
  styledTheme: {
    colors: merge(commonTheme.colors, colors),
    breakpoints: merge(commonTheme.breakpoints, breakpoints),
    meta,
    shadows: merge(commonTheme.shadows, shadows),
  },
  muiTheme: createMuiTheme(merge(commonTheme.muiTheme, muiTheme)),
};
