import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import CountDown from '../../atoms/CountDown';
import Button from '../../atoms/Button';
import {Title, Text} from '../../atoms/Typography';
import {InstructionStep} from '../../types';

interface Props {
  step: InstructionStep;
  endTime: number;
  onCancel: () => void;
  firstStep?: boolean;
}

const TestInstructions: React.FC<Props> = ({
  step,
  endTime,
  onCancel,
  firstStep,
}) => {
  const {t} = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container firstStep={firstStep}>
      <Inside>
        <Button onClick={onCancel} grey>
          {t('test.cancel')}
        </Button>

        <StyledTitle>{t(step.text)} </StyledTitle>
        <FooterContainer>
          <CountDown key={step.id} endTime={endTime} lightRail={firstStep} />
          <FooterText>{t('test.secondsToStart')}</FooterText>
        </FooterContainer>
      </Inside>
    </Container>
  );
};

export default TestInstructions;

const Container = styled.div<{firstStep?: boolean}>`
  height: calc(var(--vh, 1vh) * 100);
  padding: 48px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({theme, firstStep}) =>
    firstStep ? theme.colors.testInstruction1 : theme.colors.testInstruction2};

  @media ${props => props.theme.breakpoints.mobile} {
    padding: 16px;
  }
`;

const Inside = styled.div`
  max-width: 1024px;
  max-height: 768px;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitle = styled(Title)`
  color: ${({theme}) => theme.colors.white};
  font-weight: normal;
  line-height: 68px;
  letter-spacing: -1.4px;
  text-align: center;
  max-width: 550px;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterText = styled(Text)`
  margin-top: 16px;
  color: ${({theme}) => theme.colors.white};
  font-weight: 700;
`;
