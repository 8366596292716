import React, {FC, useState, useRef, useCallback} from 'react';
import styled from 'styled-components';
import {Button} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

import {Text} from '../../atoms/Typography';
import stop from '../../assets/icons/stop.svg';
import {ReactComponent as stopBlue} from '../../assets/icons/stop-blue.svg';

import play from '../../assets/icons/play.svg';
import {ReactComponent as playBlue} from '../../assets/icons/play-blue.svg';
import {useAppState} from '../provider/AppStateProvider';

interface AudioButtonProps {
  audio?: string;
}

const AudioButton: FC<AudioButtonProps> = ({audio}) => {
  const {t} = useTranslation();
  const [duration, setDuration] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef<HTMLAudioElement>(null);
  const {state, updateState} = useAppState();

  const handleLoadedData = useCallback(() => {
    audioRef.current && setDuration(audioRef.current.duration);
    updateState({isPlayingAudio: false});
  }, [audioRef]);

  const handleUpdate = useCallback(
    () => audioRef.current && setCurrentTime(audioRef.current.currentTime),
    [audioRef]
  );

  const handleEnd = useCallback(() => {
    setCurrentTime(0);
    updateState({isPlayingAudio: false});
  }, []);

  const handleClick = useCallback(() => {
    let isPaused = false;
    if (audioRef.current && audioRef.current.paused) {
      isPaused = true;
    }

    if (isPaused && audioRef.current && !state.isPlayingAudio) {
      audioRef.current && audioRef.current.play();
      updateState({isPlayingAudio: true});
    } else {
      audioRef.current && audioRef.current.pause();
      updateState({isPlayingAudio: isPaused});
    }
  }, [audioRef, state, updateState]);
  const buttonContent = useCallback(() => {
    if (!currentTime) {
      return (
        <Container>
          <PlayIconBlue />
          <ButtonText>{t('test.complete.listen')}</ButtonText>
        </Container>
      );
    }

    const progress = (100 * currentTime) / duration;

    if (audioRef.current) {
      return (
        <>
          <Container>
            {audioRef.current.paused ? <PlayIconBlue /> : <StopIconBlue />}
            <ButtonText>
              {audioRef.current.paused
                ? t('test.complete.listen')
                : t('test.complete.stop')}
            </ButtonText>
          </Container>

          <Overlay style={{width: progress}}>
            {audioRef.current.paused ? <PlayIcon /> : <StopIcon />}
            <ButtonText>
              {audioRef.current.paused
                ? t('test.complete.listen')
                : t('test.complete.stop')}
            </ButtonText>
          </Overlay>
        </>
      );
    } else {
      return null;
    }
  }, [audioRef, currentTime, duration]);

  return (
    <>
      <audio
        ref={audioRef}
        onLoadedData={handleLoadedData}
        onTimeUpdate={handleUpdate}
        onEnded={handleEnd}
      >
        <source src={audio} type="audio/mp3" />
      </audio>

      <MuiButton onClick={handleClick}>{buttonContent()}</MuiButton>
    </>
  );
};

export default AudioButton;

const PlayIcon = styled.img.attrs({src: play})`
  margin-left: 12px;
`;
const PlayIconBlue = styled(playBlue)`
  margin-left: 12px;

  & path {
    fill: ${({theme}) => theme.colors.main};
  }
`;
const StopIcon = styled.img.attrs({src: stop})`
  margin-left: 12px;
`;
const StopIconBlue = styled(stopBlue)`
  margin-left: 12px;

  & g {
    fill: ${({theme}) => theme.colors.main};
  }
`;

const MuiButton = styled(Button)`
  text-transform: none;
  height: 50px;
  min-width: 100px;
  border-radius: 25px;
  background-color: ${({theme}) => theme.colors.white};
  border: 1px solid ${({theme}) => theme.colors.blue};
  overflow: hidden;
  color: ${({theme}) => theme.colors.blue};
  position: relative;
`;

const Container = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

const Overlay = styled(Container)`
  overflow: hidden;
  background: ${({theme}) => theme.colors.blue};

  & p {
    color: ${({theme}) => theme.colors.white};
  }
`;

const ButtonText = styled(Text).attrs({bold: true})`
  color: ${({theme}) => theme.colors.blue};
  margin-left: 6px;
  margin-top: 3px;
  font-weight: 700;
`;
