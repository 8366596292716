import React from 'react';
import {useField} from 'react-final-form';
import Checkbox from '../../../atoms/Form/Checkbox';

interface Props {
  name: string;
  label?: React.ReactNode;
  validate?: () => string | undefined;
  initialValue?: boolean;
}

const CheckBoxField: React.FC<Props> = ({name, label, ...props}) => {
  const {input} = useField(name, {...props});

  return <Checkbox label={label} {...input} checked={!!input.value} />;
};

export default CheckBoxField;
