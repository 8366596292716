export const EYE_CONDITIONS = [
  'eyeStrain',
  'dryEyes',
  'doubleVision',
  'itchyEyes',
  'neckShoulderPain',
  'flashOfLight',
  'floaters',
  'blurredVision',
  'headaches',
  'none',
];

export const GENERAL_CONDITIONS = [
  'cataracts',
  'glaucoma',
  'retinalDesase',
  'diabetes',
  'thyroid',
  'cancer',
  'highPressure',
  'hearthDisease',
  'asthma',
  'lungDisease',
  'none',
];
