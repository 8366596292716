import React from 'react';
import Slider from '@material-ui/core/Slider';
import {withStyles} from '@material-ui/core/styles';
import styled from 'styled-components';
import {breakpoints} from '../../theme/common';
import {ReactComponent as blueCode} from '../../assets/icons/blue-code.svg';

interface Props {
  onChange: (e: any, value: any) => void;
  value: number;
  min: number;
  max: number;
  step: number;
}

const CustomThumb: React.FC = (props: any) => {
  return (
    <Thumb {...props}>
      <Arrows />
    </Thumb>
  );
};

const calcSliderOrientation = (): 'vertical' | 'horizontal' => {
  const isMobile = window.matchMedia(breakpoints.mobile).matches;
  if (isMobile) {
    return 'vertical';
  }
  return 'horizontal';
};

const Range: React.FC<Props> = props => {
  return (
    <CustomSlider
      min={props.min}
      max={props.max}
      step={0.001}
      orientation={calcSliderOrientation()}
      value={props.value}
      onChange={props.onChange}
      defaultValue={1}
      ThumbComponent={CustomThumb}
    />
  );
};

export default Range;

const Thumb = styled.div`
  background-color: ${props => props.theme.colors.white};
  border: 2px solid ${props => props.theme.colors.primary.first};
  height: 48px;
  width: 48px;
  margin: -20px 0 0 -24px;
  box-shadow: 'inherit';

  @media ${props => props.theme.breakpoints.mobile} {
    transform: translateY(18px) rotate(90deg);

    left: 6px;
  }
`;

const Arrows = styled(blueCode).attrs(({theme}) => ({
  fill: theme.colors.main,
}))``;

const CustomSlider = withStyles({
  root: {
    color: 'primary',
    height: calcSliderOrientation() === 'horizontal' ? 8 : `160px !important`,
    width: calcSliderOrientation() === 'horizontal' ? 392 : `10px !important`,
  },
  thumb: {
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  track: {
    height: calcSliderOrientation() === 'horizontal' ? 10 : '100%',
    width:
      calcSliderOrientation() === 'horizontal' ? '100%;' : `10px !important`,
    borderRadius: 5,
  },
  rail: {
    height: calcSliderOrientation() === 'horizontal' ? 10 : '100%',
    width:
      calcSliderOrientation() === 'horizontal' ? '100%' : `10px !important`,
    borderRadius: 5,
    color: '#ccd1d6',
  },
})(Slider);
