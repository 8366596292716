import React, {useCallback} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import useWebcam from '../../../hooks/useWebcam';
import BackLink from '../../components/BackLink';
import {Wrapper, ContentLeft, ContentRight} from '../../../atoms/Layout';
import {Title, Subtitle} from '../../../atoms/Typography';
import Button from '../../../atoms/Button';
import IconWithText from '../../components/IconWithText';
import volumeIcon from '../../../assets/icons/volume.svg';
import cameraIcon from '../../../assets/icons/camera-grey.svg';
import glassesIcon from '../../../assets/icons/glasses-2.svg';
import eyeIcon from '../../../assets/icons/eye.svg';

type Props = {
  onNext: (cb: () => Promise<boolean>) => void;
};

const Intro: React.FC<Props> = ({onNext}) => {
  const {t} = useTranslation();
  const history = useHistory();
  const {askPermission} = useWebcam(400, 200);

  const handleClick = useCallback(async () => {
    onNext(async () => {
      try {
        await askPermission();
        return true;
      } catch (e) {
        history.push('/permissions');
        return false;
      }
    });
  }, [onNext, askPermission]);

  return (
    <Wrapper light="left" header={<BackLink />} footer>
      <ContentLeft>
        <Title>{t('redness.title')}</Title>
      </ContentLeft>
      <ContentRight>
        <Subtitle>{t('redness.description')}</Subtitle>
        <IconWithText icon={volumeIcon} text={t('redness.instruction1')} />
        <IconWithText icon={cameraIcon} text={t('redness.instruction2')} />
        <IconWithText icon={glassesIcon} text={t('redness.instruction3')} />
        <IconWithText icon={eyeIcon} text={t('redness.instruction4')} />

        <StyledButton onClick={handleClick}>{t('redness.start')}</StyledButton>
      </ContentRight>
    </Wrapper>
  );
};

export default Intro;

const StyledButton = styled(Button)`
  margin-top: 16px;

  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
    margin-bottom: 32px;
  }
`;
