const MOCK_TRANSLATIONS = {
  en: {
    welcome: {
      title: 'Welcome to our Online Vision Assessment',
      subtitle: 'Get an assessment today with our Online Vision Assessment.',
      start: 'Request an assessment',
    },
    mobile: {
      welcome: 'Welcome to the online vision test',
      renew: 'Renew your prescription from home with our online vision exam.',
      biggerScreen:
        'The online vision test requires a bigger screen such as a tablet or a laptop computer.',
      provideEmail:
        'Please provide your e-mail address, we will send you a link to retry the test from another device.',
      email: 'Your E-mail address',
      send: 'Send me the link',
      rotate: 'Please, turn your device in landscape mode in order to proceed.',
    },
    howitworks: {
      title: 'How can we help you?',
      step1: "First we'll ask you a few questions about your eye health and vision.",
      step2:
        "Then, you'll be asked to enter your last prescription information for review by one of our doctors.",
      step3:
        'Lastly, you will take a brief vision assessment to determine if you are able to see clearly in your current glasses or contact lenses.',
      step4: 'You will need to grant access to camera and microphone.',
      agreement:
        "I understand that this consultation and potential prescription renewal isn't a replacement for a comprehensive eye exam.",
      next: "Okay, let's start",
      privacy: 'I understand that the Notice of Privacy Practices is available ',
      clickableHere: 'here.',
    },
    login: {
      title: "Let's start",
    },
    prescription: {
      title: 'Your last prescription',
      subtitle:
        'Please upload your expired prescription so that the doctor can determine if the values on the expired prescription are still right for you',
      instruction:
        "Please ensure the whole prescription is visible and not cut off. This must be a paper copy of your prescription with your doctor's information and expiration date visible. ",
      instructionUploadWarningBold:
        'You cannot upload a picture of your current contact lens boxes',
      instructionUploadWarning: ', otherwise it will be automatically rejected.',
      option: 'Would you rather take a picture of your prescription?',
      fileSizeError: 'File size exceed 10MB',
      picture: {
        title: 'Is the picture in focus and not cut-off?',
        take: 'Take Picture',
        upload: 'Upload prescription',
        requirements: 'You can select a PDF, JPG, or PNG file of maximum 10MB.',
        retake: 'No, I want to retake it',
        confirm: 'Yes, continue',
      },
      label: 'Select or drag & drop a file over here',
      manual: {
        title:
          'Please fill in the fields below with the values from your prescription or your Contact Lens boxes',
        leftEye: 'Left Eye (OS)',
        rightEye: 'Right Eye (OD)',
        sameValues: 'Use same product for both eyes',
        pupillaryDistance: 'Pupillary Distance (PD)',
        sphereLabel: 'Sphere (SPH)',
        cylinderLabel: 'Cylinder (CYL)',
        axisLabel: 'Axis',
        addLabel: 'Add',
        addDescLabel: 'Add description',
        pupillaryDistanceLabel: 'PD Value',
        lensTypeLabel: 'Contact Lens Type',
        prismVLabel: 'Prims - V Orient',
        prismHLabel: 'Prism - H Orient',
        diameter: 'Diameter',
        diameterLabel: 'Diameter (DIA)',
        baseCurveLabel: 'Base curve (BC)',
        lensBrand: 'Contact Lenses brand',
        lensType: 'Contact Lenses type',
      },
      productType: {
        title: 'Prescription Review',
        subtitle: 'Select which type of prescription you would like to have reviewed.',
        glass: 'Glasses',
        lens: 'Contact Lenses',
      },
    },
    redness: {
      title: 'Redness Check',
      description: 'We need to evaluate your eyes for redness or irritation.',
      instruction1: 'Turn your volume up to hear the instructions.',
      instruction2: 'Activate your webcam/camera of your device when requested',
      instruction3: 'Remove your glasses before this test',
      instruction4: 'Position your eyes inside the guide frames',
      start: 'Continue',
      clear: 'Are these pictures in focus and clear enough?',
      retake: 'No, I want to retake them',
      confirm: 'Yes, continue',
      ready: "Okay, I'm Ready",
      take: 'Take a shot in 3 seconds',
      setup: 'Move yourself until your eyes are well positioned inside the guide frames',
      positioning: {
        buttonText: 'Need help positioning',
        title: 'Example:',
        actionText: 'Got it',
      },
      left: 'Look Left',
      right: 'Look Right',
      up: 'Look Up',
      soundStart: '/static/audio/exam/Position-yourself.mp3',
      soundLeft: '/static/audio/exam/Look-left.mp3',
      soundRight: '/static/audio/exam/Look-right.mp3',
      soundUp: '/static/audio/exam/Look-up.mp3',
    },
    checkQualify: {
      title: 'Please provide us with some details',
      ageAndGenederDescription: 'Please, provide the information below in order to proceed.',
      ageLabel: 'Specify your age',
      genderLabel: 'Specify your gender',
      ssnLastDigits: 'What are the last four digits of your Social Security Number (SSN)?',
      ssnLabel: 'Please insert the last 4 of you SSN',
      gender: {
        male: 'Male',
        female: 'Female',
        other: 'Non-binary',
      },
      state: {
        title: 'Welcome {{username}}',
        subtitle:
          'To send your Vision Assessment to one of our licensed doctors, we will need to know what state you live in.',
        stateLabel: 'Add your state',
        outsideUS: 'I live outside the US',
        noteFirst:
          "Please note that the state you're in might not be included in the list. If so, click ",
        noteLink: 'here',
        noteSecond: ' for more info',
      },
      issues: {
        title: 'Your eyes health history',
        eyeMonitoring: {
          title: 'Eye monitoring',
          first: 'I took my last exam more than 6 months ago ',
          second: ' My eyes where dilated recently',
          third: 'I’ve used prescription eye drops',
          fourth: 'Right now, my eyes are irritated, itchy and red',
        },
        healthCondition: {
          title: 'Health conditions',
          description:
            'Me or my close relatives suffered from: Cataracts, Glaucoma, Retinal Disease, Diabetes, Thyroid Condition, Cancer, High blood pressure, Heart Disease, Asthma, Lung Disease.',
        },
        experiencedCondition: 'I identify with at least one of the above conditions',
        noExperiencedCondition: 'I don’t relate to any of the listed conditions',
      },
      fail: {
        title: 'Sorry, we cannot continue…',
        ageTitle: 'Sorry, we cannot offer this consultation to individuals under the age of 18',
        stateIssue:
          'The Online Vision Assessment is currently available only for clients living in the US, \ntherefore we cannot proceed.',
        healthIssue:
          'Unfortunately, your current or previous condition(s) prevent us from moving forward, for your health and safety we recommend a comprehensive eye exam for further assessment.',
        ageIssue:
          'Unfortunately, we’re unable to proceed with your assessment. The service is only available to people over the age of 18.',
        info: 'If you need any additional information, please ',
        contact: 'contact us',
        state: {
          title: 'Your State is not included in the application list',
          infoTitle: 'Why are some states not featured in the application list?',
          subtitle:
            'The Vision Assessment service will be available throughout the U.S territory as more states are being added weekly. Please check again in the following weeks to see if your state has been included.',
          back: 'Change state',
          infoBack: 'Go back to the application',
          backEcommerce: 'Back to homepage',
        },
      },
    },
    inactivity: {
      title: 'Sorry, time for testing has expired',
      description:
        'The available time to complete the test has run out. Click on the button below to start again.',
      restart: 'Restart assessment',
    },
    healthHistory: {
      title: 'Your Health and Vision',
      subtitle:
        'We need to know a few things about your health and vision to make sure you can continue.',
      lastExamDate: 'When was your last eye exam?',
      selectDate: 'Select option',
      currentEyewear: 'What type of Vision Correction do you currently wear?',
      selectType: 'Select type',
      medications: 'If you are taking any medications, please list them here',
      enterMedications: 'Specify the medication',
      pastInfections:
        'Have you ever had an eye infection or eye inflammation that you were prescribed medication for(eye drops or pills)?',
      lastExam: {
        1: 'Less than 1 year',
        2: '1 - 2 years',
        5: 'More than 2 years',
      },
      eyewear: {
        fullTime: 'Full time distance',
        fullTimeProgressive: 'Full time Progressive',
        fullTimeBifocal: 'Full time Bifocal',
        reading: 'Reading',
        computer: 'Computer',
        multifocal: 'Multifocal',
        sunglasses: 'Sunglasses',
        sports: 'Sports',
        contacts: 'Contact Lenses',
        safety: 'Safety',
      },
      yes: 'yes',
      no: 'no',
    },
    test: {
      'step-back': 'Position yourself\nat 5 feet away\nfrom the screen',
      'cover-left': 'Cover your left eye',
      'cover-right': 'Cover your right eye',
      'both-eyes': 'Uncover both eyes',
      cancel: 'Cancel Test',
      position: 'Position yourself at 10 feet distance from screen',
      read: 'Read these letters aloud:',
      secondsToStart: 'SECONDS TO START',
      screenSize: {
        title: 'Vision Assessment',
        subtitle: 'To make sure your test is accurate, we need to check the size of your screen. ',
        info: 'Grab a credit cart, drivers licence, supermarket card or something of similar size',
        haveIt: 'Got it',
        match: 'They match',
        card:
          'Place your card flat on the screen and adjust the picture below until it matches the size of your card',
      },
      instruction: {
        title: 'Ready to start?',
        description:
          'You will be asked to read the letters on the screen aloud, so please speak nice and loud so the microphone can hear you.',
        toStart: ' You will need:',
        step1: 'To be wearing your glasses or lenses.',
        step2: 'To be in a well-lit and quiet space where your voice can be recorded clearly.',
        step3: 'To position yourself 5 feet away from the screen during the entire test.',
        help: 'Need help measuring',
        start: "Let's start",
      },
      measuring: {
        title: "Let's calculate distance together",
        selectSize: 'Select your shoe size',
        size: 'Shoe size',
        distance: 'To reach the right distance you need to back up',
        steps: 'steps',
      },
      quit: {
        title: 'Are you sure you want to quit the test?',
        subtitle:
          'If you quit, you will not be able to perform the vision assessment  required to complete your consultation or renew your prescription.',
        quit: 'Yes, I want to quit the test',
        continue: 'No, continue',
      },
      cannotContinue: {
        title: 'Why I cannot continue?',
        subtitle:
          'Due to the length of time since your last exam, it is recommended that you see a Doctor of Optometry or Ophthalmology for your annual eye exam. This is not a replacement for an annual eye exam.',
        change: 'Change period',
        goBack: 'Go back',
      },
      complete: {
        title: 'Your Vision Assessment is complete!',
        description:
          'Listen to the recordings and make sure your voice is clear enough. If not, you can retake the test',
        retake: 'Retake the test',
        send: 'Submit for review',
        goToCart: 'Add to cart',
        goToCartDescription:
          'In order to submit your Vision Assessment, you will first be directed to the cart. Once this is complete, your Vision Assessment will be submitted for review.',
        listen: 'Listen',
        stop: 'Stop',
        leftEye: 'Left eye',
        rightEye: 'Right eye',
        bothEyes: 'Both eyes',
      },
      finish: {
        thankTitle: 'Thank you!',
        thankDescriptionFirst:
          'Keep an eye out! Within 24 hours you will receive an email confirming that the results of your online vision assessment are under review by one of our Licensed Optometrists.',
      },
      audio: {
        distance: '/static/audio/exam/5-ft.mp3',
        coverLeft: '/static/audio/exam/Cover-left.mp3',
        readAloud: '/static/audio/exam/Read-out-loud.mp3',
        coverRight: '/static/audio/exam/Cover-right.mp3',
        uncover: '/static/audio/exam/Both-eyes-open.mp3',
      },
    },
    action: {
      continue: 'Continue',
      back: 'Back',
      confirm: 'Confirm',
      okay: 'Okay',
      cancel: 'Cancel',
      gotIt: 'Got it',
      backToStore: 'Back to homepage',
      goBackToStore: 'Back to homepage',
      wait: 'Please wait',
      goBack: 'Back to homepage',
    },
    validation: {
      presence: 'This value is required',
      email: 'This value requires a valid email',
      tooSmall: 'The minimum value is {{count}}',
      tooBig: 'The maximum value is {{count}}',
      requiredNumber: 'This value requires a number',
      invalidPhoneNumber: 'This value requires a valid US phone number',
    },
    personalData: {
      title: 'Let’s start',
      subtitle: 'Please complete your profile in order to continue.',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email address',
      phoneNumber: 'Phone number',
    },
    conditions: {
      eyeStrain: 'Eyestrain',
      dryEyes: 'Dry Eyes',
      doubleVision: 'Double Vision',
      itchyEyes: 'Itchy Eyes',
      neckShoulderPain: 'Frequent Neck and Shoulders Pain',
      flashOfLight: 'Flashes of Light',
      floaters: 'Floaters',
      blurredVision: 'Blurred Vision with Glasses or Contacts',
      headaches: 'Severe or Frequent Headaches',
      cataracts: 'Cataracts',
      glaucoma: 'Glaucoma',
      retinalDesase: 'Retinal Disease',
      diabetes: 'Diabetes',
      thyroid: 'Thyroid Condition',
      cancer: 'Cancer',
      highPressure: 'High blood pressure',
      hearthDisease: 'Heart Disease',
      asthma: 'Asthma',
      lungDisease: 'Lung Disease',
      personalTitle: 'Read the list below and select any condition',
      personalTitleEmpashized: 'you have been diagnosed with:',
      relativesTitle: 'Read the list below and select any condition',
      relativesTitleEmphasized: 'your family members have been diagnosed with:',
      eyeTitle:
        'Regarding your eye health, are you currently experiencing any of the following issues?',
      none: 'None',
    },
    permissions: {
      sorry: 'Sorry,',
      title: 'we need your permission',
      subtitle1: 'To continue your exam, we need the access to your camera and your microphone.',
      subtitle2: 'Choose your platform and follow the instructions to enable it.',
      retry: 'Retry',
      iOS: {
        stepOne: 'Go to Settings app.',
        stepTwo: 'Tap Privacy and then Camera.',
        stepThree: 'Scroll down and tap Safari.',
        stepFour: 'Select While Using the App to enable camera access',
        stepFive: 'Tap the back arrow twice to return to the Privacy menu',
        stepSix: 'Now tap on Microphone',
        stepSeven: 'Scroll down and tap Safari',
        stepEight: 'Select While Using the App to enable microphone acces',
        stepNine: 'Return to Safari and tap the Retry button below',
      },
      android: {
        stepOne: 'On your Android device, open the Chrome app',
        stepTwo: 'To the right of the address bar, tap More > Settings',
        stepThree: 'Tap Site Settings',
        stepFour: 'Tap Permissions',
        stepFive: 'Tap Microphone or Camera',
        stepSix: 'Tap to turn the microphone or camera on or off',
        stepSeven: 'If you see the site you want to use under Blocked, tap the site',
        stepEight: 'Select Access your microphone',
        stepNine: 'Select Allow',
      },
    },
    invalidSession: {
      title: 'Sorry, something went wrong',
      subtitle: 'This link is invalid.',
      buttonText: 'Back to homepage',
    },
    submissionError: {
      title: 'Submission error',
      subtitle:
        'We are sorry to inform you that we experienced an issue during the submission of your test results.',
    },
    privacy: {
      title: 'Luxottica of America Inc. Notice of Privacy Practices',
      content: `
      This notice describes how medical information about you may be used and disclosed and how you can get access to this information.
      
      Please review it carefully
      
      OUR LEGAL DUTIES
      
      Luxottica of America Inc. (“Luxottica”), including all direct and indirect subsidiaries, is committed to protecting your privacy. This Notice tells about the uses and disclosures we make of your personal health information, including certain rights that you have, and obligations we are bound to, with respect to such information.
      
      We are required by applicable federal and state law to do the following:
      •	Maintain the privacy and safeguard the security of your health information;
      •	Give you this Notice about our privacy practices, our legal duties, and your rights concerning your health information;
      •	Notify you, along with all other affected individuals, of a breach of unsecured health information; and
      •	Follow the privacy practices that are described in this Notice while it is in effect. 
      
      This Notice takes effect April 1, 2019, and will remain in effect until we replace it. We reserve the right to change our privacy practices and the terms of this Notice at any time, provided such changes are permitted by applicable law.
      
      We reserve the right to make the changes in our privacy practices and this Notice, effective for all health information that we maintain, including health information we created or received before we made the changes. In the event we make a material change in our privacy practices, we will change this Notice and provide it to you or it can be viewed on our website. You may request a copy of our Notice at any time. For more information about our privacy practices, or for additional copies of this Notice, please contact us using the information listed at the end of this Notice.
      
      USES AND DISCLOSURES OF HEALTH INFORMATION
      
      We use health information about you for treatment, to obtain payment for treatment, for administrative purposes, and to evaluate the quality of care and service that you receive. Your health information is contained in a medical or optical dispensary record that is the physical property of Luxottica. Your health information consists of any information, whether in oral or recorded form, that is created or received by us and individually identifies you, and that relates to your past, present or future physical or mental health or condition; the provision of health care to you; or the past, present or future payment for the provision of health care to you.
      
      How We May Use or Disclose Your Health Information
      
      For Treatment. We may use or disclose your health information to an optometrist, ophthalmologist, optician or other health care providers providing treatment to you.  This may include:
      •	the provision, coordination, or management of health care;
      •	consultation between health care providers relating to your care;
      •	referring you to another health care provider; or
      •	appointment reminders and recall information.
      
      For Payment. We may use and disclose your health information to facilitate payments of benefits for treatment and services provided to you. This may include:
      •	billing and collection activities and related data processing;
      •	submitting claims to your health or vision coverage; 
      •	disclosure to consumer reporting agencies of information relating to collection of payments.
      
      For Health Care Operations. We may use and disclose health information about you for our health care operational purposes. For example, your health information may be used or disclosed to:
      •	conduct quality assessments and improvement activities;
      •	conduct training programs or credentialing activities;
      •	conduct or arrange for medical review, legal services, audit services, fraud and abuse detection and compliance programs;
      •	determine how to continually improve the quality and effectiveness of the products, service and care we provide, including customer satisfaction surveys and data analyses;
      •	properly manage our business;
      •	business plan and development, including acquisitions, mergers and consolidations; and
      •	communicate with you concerning (a) a health-related product or service that is provided by us, (b) your treatment, or (c) case management, care coordination,  or to recommend alternative treatments, therapies, providers or settings for care to the extent such activities are not within your current treatment. 
      
      To You, Your Family and Friends. We must disclose your health information to you, as described in the Your Health Information Rights section of this Notice. We may disclose your health information to a family member, friend or other person to the extent necessary to help with your health care or with payment for your health care, but only if you agree that we may do so or, if you are not able to agree, if it is necessary in our professional judgment. 
      
      Location and Notification. We may use or disclose your health information to notify, or assist in the notification of (including identifying or locating) a family member, your personal representative or another person responsible for your care, of your location or your general condition. We will make this type of disclosure only if you agree that we may do so or, if you are not able to agree, if it is necessary in our professional judgment.  
      
      Required by Law. We may use and disclose information about you as required by applicable law. In addition, we may disclose information for the following purposes:
      •	for judicial and administrative proceedings pursuant to court order or specific legal authority;
      •	pursuant to a shared/joint custody and child care or support arrangement authorized by law or court order;
      •	to report information related to victims of abuse, neglect or domestic violence;
      •	to assist law enforcement officials in their law enforcement duties; or
      •	to assist public health, safety or law enforcement officials avert a serious threat to the health or safety of you or any other person.
      
      Personal Representatives; Decedents. We may disclose your health information to your personal representatives authorized under applicable law, such as a guardian, power of attorney for health care, or court-appointed administrator. Your health information may also be disclosed to executors, legally authorized family members, funeral directors or coroners to enable them to carry out their lawful duties upon your death.
      
      Organ/Tissue Donation. Your health information may be used or disclosed for cadaveric organ, eye or tissue donation purposes, provided we follow applicable laws. 
      
      Government Functions. Specialized government functions such as protection of public officials or reporting to various branches of the armed services that may require use or disclosure of your health information.
      
      Worker Compensation. Your health information may be used or disclosed in order to comply with laws and regulations related to Worker Compensation.
      
      Marketing Products or Services. “Marketing” means to make a communication to you that encourages you to purchase or use a product or service. We will not use or disclose your health information for marketing communications without your prior written authorization, except in the narrow circumstances permitted by HIPAA. We may also provide you with information regarding products or services that we offer related to your health care needs, provided that we are not paid or otherwise receive compensation for such communications.
      
      We are also permitted to communicate with you regarding, treatment, case management or care coordination (including recommending alternative treatments, providers or settings for care). However, if we will receive compensation (directly or indirectly) in return for making any such communications, we must first obtain your written authorization, unless the communication describes only a drug or biologic that is currently prescribed for you and any compensation we receive relates solely to the cost of making the communication. This requirement does not apply to any payment or compensation for providing treatment to you.
      
      Sale of Your Health Information. We will never sell your health information without your prior authorization, except in the narrow circumstances permitted by HIPAA. Under HIPAA, we, or our business associate, may receive compensation (directly or indirectly) related to an exchange of your health information for the following purposes: (a) public health activities; (b) research purposes (if the price charged reflects the cost of preparation and transmittal of the information); (c) payment or compensation for your treatment; (d) health care operations related to the sale, merger or consolidation of all or part of our business; (e) performance of services by a business associate on our behalf; (f) providing you with a copy of your health information; or (g) other reasons determined necessary or appropriate by applicable laws or regulations.
      
      Your Authorization. You may give us written authorization to use your health information or to disclose it to anyone for any purpose. We will not condition your current or future treatment on the basis of providing an authorization. If you give us an authorization, you may revoke it in writing at any time. Your revocation will not affect any use or disclosures permitted by your authorization while it was in effect. Unless you give us a written authorization, we cannot use or disclose your health information for any reason except those described in this Notice.
      
      To a Business Associate.  A Business Associate is a person or entity that helps Luxottica provide its services to you.  We will only disclose your health information to Business Associates who have agreed in writing to protect that information as required by HIPAA.
      
      Organized Health Care Arrangement (“OHCA”).  If we are a member of an OHCA, we may disclose your Protected Health Information to another member of the OHCA for the health care operations of the OHCA.
      
      YOUR HEALTH INFORMATION RIGHTS
      
      Access: You have the right to review or get copies of your health information, with limited exceptions. You may request that we provide copies in a format other than photocopies. We will use the format you request unless we cannot practicably do so. You may be asked to make a request in writing to obtain access to your health information. You may obtain a form to request access by using the contact information listed at the end of this Notice. We may charge you a reasonable cost based fee for expenses such as copies and staff time. You may also request access by sending us a letter to the address at the end of this Notice setting forth the specific information to which you desire access. If you request an alternative format, provided that it is practicable for us to produce the information in such format, we may charge a cost-based fee for preparing and transmitting your health information in that format. If you prefer, we will prepare a summary or an explanation of your health information for a cost-based fee. If we use or maintain an electronic health record (“EHR”) with respect to your care, you have the right to request a copy of your information in electronic format, and to direct us to transmit a copy of your information to a third party designated by you; and our fee may not exceed our labor costs in responding to such request. Please contact us using the information listed at the end of this Notice for a full explanation of our fee structure.
      
      Disclosure Accounting: You have the right to receive a list of instances in which we or our business associates disclosed your health information for purposes other than treatment, payment, health care operations, where you have provided an authorization and certain other activities, for the last 6 years (or a shorter period if our relationship with you has existed for less than 6 years).
      
      If you request this accounting more than once in a 12 month period, we may charge you a reasonable, cost based fee for responding to these additional requests.
      
      With respect to disclosures made by our business associates, we may choose to provide you with a list of business associates acting on our behalf, along with their contact information, who must provide you with the accounting upon a request made directly by you to such entities
      
      Restriction: You have the right to request that we place additional restrictions on our use or disclosure of your health information. Except as noted below, we are not required to agree to these additional restrictions, but if we do, we will abide by our agreement (except in an emergency). Upon your request, and except as otherwise required by law, we will not disclose your health information to a health plan for purposes of payment or health care operations when the information relates solely to a service/product for which you paid out-of-pocket in full.
      
      Alternative Communication: You have the right to request in writing that we communicate with you about your health information by alternative means or to alternative locations. Your request must specify the alternative means or location, and provide satisfactory explanation how payments will be handled under the alternative means or location you request. 
      
      Amendment: You have the right to request that we amend your health information. Your request must be in writing, and it must explain why the information should be amended. We may deny your request under certain circumstances. You may obtain a form to request an amendment to your health information by using the contact information listed at the end of this Notice.
      
      Electronic Notice: If you receive this Notice on our website or by electronic mail (email), you are entitled to receive this Notice in written form, as well.
      
      Breach of Unsecured Health Information: If we discover that your health information has been breached (for example, disclosed to or acquired by an unauthorized person, stolen, lost, or otherwise used or disclosed in violation of applicable privacy law) and the privacy or security of the information has been compromised, we must notify you of the breach without unreasonable delay and in no event later than 60 days following our discovery of the breach.
      
      PRIVACY QUESTIONS AND COMPLAINTS
      
      If you want more information about our privacy practices or have privacy questions or concerns, please contact us. If you are concerned that we may have violated your privacy rights, or you disagree with a decision we made about access to your health information or in response to a request you made to amend or restrict the use or disclosure of your health information or to have us communicate with you by alternative means or at alternative locations, you may complain to us using the contact information listed at the end of this Notice. You may separately choose to file a complaint with the U.S. Department of Health and Human Services, Office of Civil Rights (OCR), by completing a Health Information Privacy Complaint Form (available at http://www.hhs.gov/ocr/privacy/hipaa/complaints/hipcomplaintform.pdf) and sending to the applicable OCR Regional Office listed on the form, or by calling 1-800-368-1019 for instructions and contact information. An electronic complaint may be filed at http://www.hhs.gov/ocr/privacy/hipaa/complaints/index.html. You must file a complaint with OCR within 180 days (6 months) after the occurrence of the act or omission giving rise to your complaint.
      
      We support your right to the privacy of your health information. We will not retaliate in any way if you choose to file a complaint with us or with the Office of Civil Rights.
      
      Contact Information
      If you have any questions or complaints relating to privacy, please contact:
      
      Senior Security & Privacy Official
      Privacy Office
      Luxottica of America Inc.
      4000 Luxottica Place
      Mason, Ohio 45040
      Phone: 513-765-4321
      Email: privacyoffice@luxotticaretail.com      
      `,
    },
  },
};

interface TranslationDictionary {
  [key: string]: object;
}

export const getTranslations = async (): Promise<TranslationDictionary> => {
  try {
    return MOCK_TRANSLATIONS;
    // const response = await https.get(`${API_URL}/exam/languages`);
    // return response.data;
  } catch (error) {
    // return MOCK_TRANSLATIONS;
    throw error;
  }
};
