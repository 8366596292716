import React, {useContext} from 'react';
import styled from 'styled-components';
import {ThemeContext} from 'styled-components';
import {useAppState} from '../app/provider/AppStateProvider';

interface Props {
  inverted?: boolean;
}

const StoreLogo: React.FC<Props> = props => {
  const {state} = useAppState();
  const theme = useContext(ThemeContext);
  const logo = theme.meta.logo;
  const hasUrl = !!state.storeOriginUrl;

  const redirectToStore = () => {
    if (state.storeOriginUrl) {
      window.location.href = state.storeOriginUrl;
    }
  };

  return (
    <Logo
      onClick={redirectToStore}
      src={logo as string}
      inverted={props.inverted}
      hasUrl={hasUrl}
    />
  );
};

export default StoreLogo;

const Logo = styled.img<{inverted?: boolean; hasUrl: boolean}>`
  width: 172px;
  cursor: ${props => (props.hasUrl ? 'pointer' : 'auto')};
  ${props =>
    props.inverted &&
    `
    filter: brightness(0) invert(1);
  `}
`;
