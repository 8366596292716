import merge from 'deepmerge';
import {createMuiTheme} from '@material-ui/core/styles';

import * as commonTheme from './common';
import contactsDirectLogo from '../assets/images/contacts-direct.svg';

const colors = {
  primary: {
    first: '#0080a9',
  },
  main: '#0080a9',
  blue: '#0080a9',
  testInstruction1: '#01222c',
  testInstruction2: '#0080a9',
  timer: '#ffffff',
  timerCircles: '#0080a9',
  timerRail: '#0c5a75',
  timerLightRail: '#0c5a75', //'#ccd1d6',
  timerCirclesRail: '#c7e1ea',
  timerCirclesOuter: '#eaf1f5',
  timerCirclesMiddle: '#d2e4ed',
  timerCirclesInner: '#bdd9e6',
  wrapperBackgroundLeft: '#f6f7f8',
  wrapperBackgroundTop: '#ffffff',
  wrapperBackgroundBottom: '#ffffff',
};

const breakpoints = {};

const shadows = {
  wrapper: 'none;',
};

const muiTheme = {
  palette: {
    primary: {
      main: '#0080a9',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ffffff',
      contrastText: '#0080a9',
    },
  },

  overrides: {
    MuiButton: {
      containedPrimary: {
        '&:hover': {
          backgroundColor: '#DFDFDF',
        },
      },
    },
  },
};

const meta = {
  name: 'Contacts Direct',
  logo: contactsDirectLogo,
};

export default {
  styledTheme: {
    colors: merge(commonTheme.colors, colors),
    breakpoints: merge(commonTheme.breakpoints, breakpoints),
    meta,
    shadows: merge(commonTheme.shadows, shadows),
  },
  muiTheme: createMuiTheme(merge(commonTheme.muiTheme, muiTheme)),
};
