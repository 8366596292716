import {StepType, StoreType} from '../types';
import lensCrafterLogo from '../assets/images/lens-crafter@3x.png';
import targetOpticalLogo from '../assets/images/target-optical@3x.png';

export default {
  cmpx: 37.795276,
  incm: 2.54,
  ccSide: 12,
  slideTime: 3,
  slideTimeDelay: 3,
  slides: [
    {
      id: 1,
      letter: `A`,
      dimension: 8,
    },
    {
      id: 2,
      letter: `B`,
      dimension: 5,
    },
    {
      id: 3,
      letter: `E`,
      dimension: 3,
    },
  ],
};

export const STEPS: Array<StepType> = [
  {
    id: 1,
    text: 'test.step-back',
    duration: 5000,
    type: 'instruction',
    audio: 'test.audio.distance',
  },
  {
    id: 2,
    text: 'test.cover-left',
    duration: 3000,
    type: 'instruction',
    audio: 'test.audio.coverLeft',
  },
  {
    id: 3,
    duration: 10000,
    letters: 'EKPN',
    dimension: 0.22,
    type: 'letters',
    audio: 'test.audio.readAloud',
  },
  {
    id: 4,
    text: 'test.cover-right',
    duration: 3000,
    type: 'instruction',
    audio: 'test.audio.coverRight',
  },
  {
    id: 5,
    letters: 'RLSU',
    dimension: 0.22,
    duration: 10000,
    type: 'letters',
    audio: 'test.audio.readAloud',
  },
  {
    id: 6,
    text: 'test.both-eyes',
    duration: 3000,
    type: 'instruction',
    audio: 'test.audio.uncover',
  },
  {
    id: 7,
    letters: 'Eotf',
    duration: 10000,
    dimension: 0.22,
    type: 'letters',
    audio: 'test.audio.readAloud',
  },
];

export const STORECONFIG: StoreType = {
  'lens-crafter': {
    name: 'LensCrafters',
    logo: lensCrafterLogo,
    redirectionUrl: 'https://www.lenscrafters.com/',
  },
  'target-optical': {
    name: 'Target Optical',
    logo: targetOpticalLogo,
    redirectionUrl: 'https://www.targetoptical.com/',
  },
};

export const TOKEN = (() => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('token');
})();
