import https, {API_URL} from './https';
import {AppState} from '../app/provider/AppStateProvider';
import {mockUser} from '../utils/mock';

export const getUser = async (): Promise<unknown> => {
  try {
    const response = await https.get('/me');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const login = async (data: {
  email: string;
  password: string;
  store?: string | null;
}): Promise<{token: string}> => {
  try {
    const response = await https.post('/login/customer', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendTest = async (data: any, sessionId?: string) => {
  const {audios, ...state} = data;

  const fd = new FormData();

  fd.append('data', JSON.stringify(state));

  audios?.forEach((audio: any) => {
    if (audio.audio) {
      fd.append(`audio-${audio.id}`, audio.audio);
    }
  });

  await https.post(`${API_URL}/exam/save/${sessionId}`, fd);
};

export const postSession = async (token: string | null) => {
  try {
    const response = await https.post(`${API_URL}/exam/session`, {
      token,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postAbandonSession = (sessionId: string, email?: string) => {
  let url = `${API_URL}/exam/session/abandon/${sessionId}`;

  if (email) {
    url += '?email=' + email;
  }

  navigator.sendBeacon(url);
};

export const postAbortSession = (sessionId: string, email?: string) => {
  let url = `${API_URL}/exam/session/abort/${sessionId}`;

  if (email) {
    url += '?email=' + email;
  }

  navigator.sendBeacon(url);
};
