import React, {useState, useCallback, useContext} from 'react';
import {
  StepType,
  PrescriptionType,
  PrescriptionProductType,
  QuestionnaireType,
} from '../../types';
import {TOKEN} from '../../constants/config';

export type AppState = {
  state?: string;
  tests?: Array<TestType>;
  pixelRatio?: number;
  store?: string;
  rednessImages?: Array<string>;
  age?: string;
  gender?: string;
  flowMode?: string;
  prescriptionImage?: string | ArrayBuffer | null;
  prescription?: PrescriptionType;
  successRedirectUrl?: string;
  storeOriginUrl?: string;
  failRedirectUrl?: string;
  sessionId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  stepConfig?: Array<StepType>;
  token: string | null;
  productType?: PrescriptionProductType;
  questionnaire?: QuestionnaireType;
  incomingData?: string[];
  isPlayingAudio?: boolean;
};

type TestType = {
  step: StepType;
  audioBlob: Blob | null;
};

type ContextType = {
  state: AppState;
  updateState: (partialState: Partial<AppState>) => void;
  resetState: () => void;
};

export const AppStateContext = React.createContext<ContextType>({
  state: {
    token: TOKEN,
  },
  updateState: () => {
    throw new Error('Abstract method updateState');
  },
  resetState: () => {
    throw new Error('Abstract method resetState');
  },
});

export const useAppState = () => useContext(AppStateContext);

const AppStateProvider: React.FC = ({children}) => {
  const [state, setState] = useState<AppState>({
    token: TOKEN,
  });

  const updateState = useCallback(
    (partialState: Partial<AppState>) =>
      setState(state => ({
        ...state,
        ...partialState,
      })),
    []
  );

  const resetState = useCallback(
    () =>
      setState({
        token: TOKEN,
      }),
    []
  );

  const contextValue = {
    state,
    updateState,
    resetState,
  };

  return (
    <AppStateContext.Provider value={contextValue}>
      {children}
    </AppStateContext.Provider>
  );
};

export default AppStateProvider;
