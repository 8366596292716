import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import {Wrapper} from '../../atoms/Layout';
import Button from '../../atoms/Button';
import {Title, Subtitle, Text} from '../../atoms/Typography';

//TODO: steps for other devices/ translations
const steps = [
  'stepOne',
  'stepTwo',
  'stepThree',
  'stepFour',
  'stepFive',
  'stepSix',
  'stepSeven',
  'stepEight',
  'stepNine',
];

//TODO: complete function that detects os/ browser
const getOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  return 'iOS';
};

const Permissions: React.FC = () => {
  const {t} = useTranslation();
  const history = useHistory();

  return (
    <Wrapper footer>
      <Container>
        <Title>{t('permissions.sorry')}</Title>
        <Title>{t('permissions.title')}</Title>

        <StyledSubtitle>{t('permissions.subtitle1')}</StyledSubtitle>

        <SecondSubtitle>{t('permissions.subtitle2')}</SecondSubtitle>

        <Steps>
          {steps.map((step, index) => (
            <StepContainer>
              <StepNumber>{index + 1}</StepNumber>
              <Text>{t(`permissions.${getOS()}.${step}`)}</Text>
            </StepContainer>
          ))}
          <StyledButton onClick={history.goBack}>
            {t('permissions.retry')}
          </StyledButton>
        </Steps>
      </Container>
    </Wrapper>
  );
};

export default Permissions;

const Container = styled.div``;

const StyledSubtitle = styled(Subtitle)`
  color: ${({theme}) => theme.colors.black};
  margin: 24px 0 0;
`;

const SecondSubtitle = styled(StyledSubtitle)`
  color: ${({theme}) => theme.colors.black};
  margin: 0 0 36px;
`;

const Steps = styled.div`
  column-count: 2;
  @media ${props => props.theme.breakpoints.mobile} {
    column-count: 1;
  }
`;

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const StepNumber = styled.div`
  margin-right: 12px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 12px;
  line-height: 12px;
  font-weight: 900;
  color: ${({theme}) => theme.colors.black};
  background-color: ${({theme}) => theme.colors.grey200};
`;

const StyledButton = styled(Button)`
  margin-top: 12px;
  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
  }
`;
