import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import BackLink from '../components/BackLink';
import {Wrapper, ContentRight, ContentLeft} from '../../atoms/Layout';
import {Title, Subtitle, Text} from '../../atoms/Typography';
import Button from '../../atoms/Button';

import creditCard from '../../assets/icons/credit-card.svg';

const SizeScreenIntro: React.FC = () => {
  const {t} = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    history.push('/meter');
  };

  return (
    <Wrapper light="left" header={<BackLink />} footer>
      <ContentLeft>
        <Title>{t('test.screenSize.title')}</Title>
      </ContentLeft>
      <ContentRight>
        <Subtitle>{t('test.screenSize.subtitle')}</Subtitle>
        <InfoContainer>
          <Card />
          <Text>{t('test.screenSize.info')}</Text>
        </InfoContainer>
        <StyledButton onClick={handleClick}>
          {t('test.screenSize.haveIt')}
        </StyledButton>
      </ContentRight>
    </Wrapper>
  );
};

export default SizeScreenIntro;

const Card = styled.img.attrs(() => ({
  src: creditCard,
}))`
  margin-right: 14px;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 48px;
`;

const StyledButton = styled(Button)`
  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
  }
`;
