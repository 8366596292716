import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useField} from 'react-final-form';
import styled from 'styled-components';
import Input from '../../../atoms/Form/Input';
import {MouseOrTouchEvent} from 'react-select/src/Select';
import {Text} from '../../../atoms/Typography';

import chevronDown from '../../../assets/icons/chevron-down.svg';
import add from '../../../assets/icons/add.svg';
import remove from '../../../assets/icons/remove.svg';

export interface OptionType {
  label: string;
  value: string;
}

interface PositionType {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

interface SelectProps {
  name: string;
  validate?: () => string | undefined;
  options: Array<OptionType>;
  label?: string;
  withOperator?: boolean;
  disabled?: boolean;
  position?: PositionType;
  top?: boolean;
  left?: boolean;
  optionColumns?: number;
  moveLeft?: number;
  moveRight?: number;
}

const PrescriptionField: React.FC<SelectProps> = props => {
  const {input} = useField(props.name, {
    validate: props.validate,
  });

  const [menuOpen, setMenuOpen] = useState(false);
  const [isPositive, setIsPositive] = useState(false);
  const menuRef = useRef(null);

  const singleColumn = props.optionColumns === 1;

  useEffect(() => {
    if (props.disabled) {
      setMenuOpen(false);
    }
  }, [props.disabled]);

  const handleClick = useCallback(
    e => {
      if (menuRef.current && !(menuRef.current as any).contains(e.target)) {
        setMenuOpen(false);
      }
    },
    [setMenuOpen]
  );

  useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, [handleClick]);

  const handlePositive = (e: MouseOrTouchEvent) => {
    e.stopPropagation();
    setIsPositive(true);
  };
  const handleNegative = (e: MouseOrTouchEvent) => {
    e.stopPropagation();
    setIsPositive(false);
  };

  const toggleMenu = (e: MouseOrTouchEvent) => {
    setMenuOpen(!menuOpen);
  };

  const handleSelection = (value: string) => () => {
    const finalValue =
      props.withOperator && value !== 'PL' && value !== 'DS' && value !== '0.00'
        ? `${isPositive ? '+' : '-'}${value}`
        : value;
    input.onChange(finalValue);
  };

  return (
    <InputContainer onClick={toggleMenu} ref={menuRef}>
      <StyledInput fullWidth label={props.label} value={input.value} disabled={props.disabled} />

      {menuOpen && !props.disabled ? <ChevronIconDown /> : <ChevronIconUp />}

      {menuOpen && !props.disabled && (
        <>
          <MenuMobileBg menuOpen={menuOpen} />
          <Menu
            singleColumn={singleColumn}
            menuOpen={menuOpen}
            top={props.top}
            left={props.left}
            moveLeft={props.moveLeft}
            moveRight={props.moveRight}
          >
            <MenuMobileRow>
              <MenuMobileTitle>{props.label}</MenuMobileTitle>
              {props.withOperator && (
                <SignToggle>
                  <ToggleUp selected={!isPositive} onClick={handleNegative}>
                    <RemoveIcon />
                  </ToggleUp>
                  <ToggleDown selected={isPositive} onClick={handlePositive}>
                    <AddIcon />
                  </ToggleDown>
                </SignToggle>
              )}
            </MenuMobileRow>
            <OptionsContainer
              singleColumn={singleColumn}
              columns={props.optionColumns || Math.ceil(props.options.length / 6)}
            >
              {singleColumn && <TopGradient />}
              {props.options.map(option => {
                return (
                  <Option singleColumn={singleColumn} onClick={handleSelection(option.value)}>
                    <Text>
                      {props.withOperator &&
                        option.label !== 'PL' &&
                        option.label !== 'DS' &&
                        option.label !== '0.00' &&
                        `${isPositive ? '+ ' : '- '}`}
                      {option.label}
                    </Text>
                  </Option>
                );
              })}
              {singleColumn && <BottomGradient />}
            </OptionsContainer>
          </Menu>
          <BorderTriangle top={props.top} />
          <Triangle top={props.top} />
        </>
      )}
    </InputContainer>
  );
};

export default PrescriptionField;

const InputContainer = styled.div`
  position: relative;
  outline: none;
  cursor: pointer;
`;

const Menu = styled.div<{
  singleColumn: boolean;
  top?: boolean;
  left?: boolean;
  menuOpen?: boolean;
  moveLeft?: number;
  moveRight?: number;
}>`
  display: flex;
  position: absolute;

  top: calc(100% - 13px);
  left: ${props => props.moveLeft || 0}px;
  ${props =>
    props.top &&
    `
    bottom: calc(100% + 10px);
    top:initial;
  `}
  ${props =>
    props.left &&
    `
    right: ${props.moveRight || 0}px;
    left:initial;
  `}

  width: ${props => (props.singleColumn ? '100%' : 'auto')};
  max-width: 832px;
  background-color: ${props => props.theme.colors.white};
  z-index: 10;
  border-radius: 5px;
  border: solid 2px ${props => props.theme.colors.gray500};
  animation: fade 0.3s;
  ${props =>
    props.singleColumn
      ? 'max-height: 265px; overflow: auto; overflow-x: hidden; padding: 16px 0;'
      : 'padding: 16px 8px;'}

  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media ${props => props.theme.breakpoints.mobile} {
    max-height: none;
    border: none;
    border-bottom-left-radius: none;
    border-bottom-right-radius: none;
    padding: 20px 20px 0 20px;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    animation: slideIn 0.2s forwards;
    flex-direction: column;
  }

  @keyframes slideIn {
    0% {
      top: 100vh;
    }
    100% {
      top: 35vh;
    }
  }
`;

const MenuMobileBg = styled.div<{menuOpen?: boolean}>`
  display: none;
  @media ${props => props.theme.breakpoints.mobile} {
    display: block;
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 8;
    animation: fade 0.3s forwards;
    background-color: rgba(0, 0, 0, 0.6);
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.6;
    }
  }
`;

const MenuMobileRow = styled.div`
  @media ${props => props.theme.breakpoints.mobile} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    height: 56px;
  }
`;

const MenuMobileTitle = styled.div`
  display: none;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;

  @media ${props => props.theme.breakpoints.mobile} {
    display: block;
  }
`;

const ToggleButton = styled.button<{selected?: boolean}>`
  width: 48px;
  height: 48px;
  outline: none;
  border: solid 2px ${props => props.theme.colors.ironGray};
  background-color: ${props =>
    props.selected ? props.theme.colors.ironGray : props.theme.colors.white};

  @media ${props => props.theme.breakpoints.mobile} {
    width: 56px;
    height: 56px;
  }
`;

const ToggleUp = styled(ToggleButton)`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  @media ${props => props.theme.breakpoints.mobile} {
    border-top-right-radius: 0;
    border-bottom-left-radius: 5px;
  }
`;

const ToggleDown = styled(ToggleButton)`
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  @media ${props => props.theme.breakpoints.mobile} {
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
  }
`;

const SignToggle = styled.div`
  display: flex;
  flex-direction: column;

  @media ${props => props.theme.breakpoints.mobile} {
    flex-direction: row;
  }
`;

const OptionsContainer = styled.div<{columns: number; singleColumn: boolean}>`
  ${props =>
    props.singleColumn
      ? 'display: flex; overflow-y: auto; padding: 32px 0; width: 100%'
      : `column-count: ${props.columns}; column-fill: auto; column-gap: 0px; width: auto`}
  flex-direction: column;

  @media ${props => props.theme.breakpoints.mobile} {
    column-count: initial;
    width: 100%;
    overflow-y: auto;
  }
`;

const Option = styled.button<{singleColumn: boolean}>`
  width: ${props => (props.singleColumn ? '100%' : '70px')};
  height: 40px;
  background-color: ${props => props.theme.colors.white};
  outline: none;
  border: none;
  cursor: pointer;
  flex-shrink: 0;

  @media ${props => props.theme.breakpoints.mobile} {
    width: ${props => (props.singleColumn ? '90%' : '74px')};
    height: 56px;
    border: solid 2px ${props => props.theme.colors.ironGray};
    border-radius: 5px;
    margin: 4px;
  }

  &:hover {
    background-color: ${props => props.theme.colors.grey50};
  }
`;

const StyledInput = styled(Input)`
  background-color: ${({theme}) => theme.colors.white};

  & > div {
    pointer-events: none;
    & > input {
      cursor: pointer;
    }
  }
`;

const ChevronIconUp = styled.img.attrs(() => ({
  src: chevronDown,
}))`
  position: absolute;
  right: 18px;
  top: 18px;
  pointer-events: none;
`;

const ChevronIconDown = styled(ChevronIconUp)`
  transform: rotate(180deg);
`;

const AddIcon = styled.img.attrs(() => ({
  src: add,
}))``;

const RemoveIcon = styled.img.attrs(() => ({
  src: remove,
}))``;

const BorderTriangle = styled.div<{top?: boolean}>`
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8.5px 10px 8.5px;
  border-color: transparent transparent ${props => props.theme.colors.black}
    transparent;
  position: absolute;
  bottom: 12px;
  left: calc(50% - 8.5px);
  z-index: 11;
  ${props =>
    props.top &&
    `
    top: -11px;
    bottom: initial;
    transform: rotate(180deg);
  `}
    @media ${props => props.theme.breakpoints.mobile} {
      display: none;
    }
`;

const Triangle = styled.div<{top?: boolean}>`
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7.5px 9px 7.5px;
  border-color: transparent transparent ${props => props.theme.colors.white}
    transparent;
  position: absolute;
  bottom: 11px;
  left: calc(50% - 7.5px);
  z-index: 11;
  ${props =>
    props.top &&
    `
    bottom: initial;
    top: -12px;
    transform: rotate(180deg);
  `}
    @media ${props => props.theme.breakpoints.mobile} {
      display: none;
    }
`;

const TopGradient = styled.div`
  position: absolute;
  pointer-events: none;
  top: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(
    ${props => props.theme.colors.white},
    ${props => props.theme.colors.white} 50%,
    rgba(255, 255, 255, 0)
  );

  @media ${props => props.theme.breakpoints.mobile} {
    display: none;
  }
`;

const BottomGradient = styled.div`
  position: absolute;
  pointer-events: none;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(
    rgba(255, 255, 255, 0),
    ${props => props.theme.colors.white} 50%,
    ${props => props.theme.colors.white}
  );

  @media ${props => props.theme.breakpoints.mobile} {
    display: none;
  }
`;
