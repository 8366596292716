import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';

const MAX_INACTIVITY = 1000 * 60 * 15; //15 mins

const useActivity = () => {
  const history = useHistory();

  useEffect(() => {
    const checkActivity = debounce(
      async () => history.replace('/inactivity'),
      MAX_INACTIVITY
    );

    window.addEventListener('mousemove', checkActivity);

    return () => window.removeEventListener('mousemove', checkActivity);
  }, []);
};

export default useActivity;

const debounce = (func: () => void, wait: number) => {
  let timeout: any;

  return function executedFunction() {
    const later = () => {
      timeout = null;
      func();
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
