import React from 'react';
import styled from 'styled-components';

import {OutlinedTextFieldProps, TextField} from '@material-ui/core';

type Props = Omit<OutlinedTextFieldProps, 'variant'>;

const Input: React.FC<Props> = ({children, ...props}) => {
  return (
    <StyledTextField
      {...props}
      variant="outlined"
      // InputProps={{style: {backgroundColor: 'white'}}}
    >
      {children}
    </StyledTextField>
  );
};

export default Input;

const StyledTextField = styled(TextField)`
  margin-bottom: 24px;

  & > div {
    background-color: ${({theme}) => theme.colors.white};
  }

  .MuiFormLabel-root::first-letter {
    text-transform: uppercase;
  }
  .MuiFormHelperText-root::first-letter {
    text-transform: uppercase;
  }
`;
