import React, {useCallback, useContext, useState} from 'react';
import {RouteProps} from 'react-router';
import {useHistory} from 'react-router-dom';
import styled, {ThemeContext} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Form} from 'react-final-form';

import {Wrapper} from '../../atoms/Layout';
import BackLink from '../components/BackLink';
import Button from '../../atoms/Button';
import CheckboxField from '../components/Fields/CheckboxField';
import {Title, Text} from '../../atoms/Typography';
import {requiredCheck} from '../../utils/validation';

import message from '../../assets/icons/message.svg';
import prescription from '../../assets/icons/prescription.svg';
import eye from '../../assets/icons/eye.svg';
import camera from '../../assets/icons/camera-grey.svg';
import PrivacyModal from '../components/Modal/PrivacyModal';

type Props = RouteProps & {};

interface StepProps {
  text: string;
  icon: string;
}

const Step: React.FC<StepProps> = ({icon, text}) => (
  <StepContainer>
    <StepIcon icon={icon} />
    <StepText>{text}</StepText>
  </StepContainer>
);

const HowItWorks: React.FC<Props> = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const styledTheme = useContext(ThemeContext);
  const [privacyModal, setPrivayModal] = useState(false);

  const isMobile = window.matchMedia(styledTheme.breakpoints.mobile);

  const onSubmit = async () => {
    history.push('/start');
  };

  const toggleQuitModal = useCallback(() => {
    setPrivayModal(!privacyModal);
  }, [privacyModal]);

  return (
    <Wrapper
      header={<BackLink />}
      footer
      light={isMobile.matches ? 'left' : undefined}
    >
      <Form
        onSubmit={onSubmit}
        render={({handleSubmit, hasValidationErrors}) => {
          return (
            <Content>
              <StyledTitle>{t('howitworks.title')}</StyledTitle>

              <Row>
                <Step icon={message} text={t('howitworks.step1')} />
                <Step icon={prescription} text={t('howitworks.step2')} />
                <Step icon={eye} text={t('howitworks.step3')} />
                <Step icon={camera} text={t('howitworks.step4')} />
              </Row>
              <Box>
                <CheckboxContainer>
                  <CheckboxField
                    name="agreement"
                    label={<span>{t('howitworks.agreement')}</span>}
                    validate={requiredCheck}
                  />
                  <CheckboxField
                    name="privacy"
                    label={
                      <span>
                        {t('howitworks.privacy')}
                        <ClickableSpan onClick={toggleQuitModal}>
                          {t('howitworks.clickableHere')}
                        </ClickableSpan>
                      </span>
                    }
                    validate={requiredCheck}
                  />
                </CheckboxContainer>

                <StyledButton
                  onClick={handleSubmit}
                  disabled={hasValidationErrors}
                >
                  {t('howitworks.next')}
                </StyledButton>
              </Box>
              <PrivacyModal visible={privacyModal} onQuit={toggleQuitModal} />
            </Content>
          );
        }}
      />
    </Wrapper>
  );
};

export default HowItWorks;

const Content = styled.div`
  padding: 0 96px;

  @media ${props => props.theme.breakpoints.mobile} {
    padding: 0;
  }
`;
const StyledTitle = styled(Title)`
  @media ${props => props.theme.breakpoints.mobile} {
    height: 100px;
  }
`;

const Row = styled.div`
  margin: 59px 0 30px;
  display: flex;
  flex-wrap: wrap;

  @media ${props => props.theme.breakpoints.mobile} {
    margin-top: 90px;
    flex-direction: column;
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 26px 32px;
  border-radius: 30px;
  box-shadow: 0 10px 30px 0 rgba(123, 138, 154, 0.1);
  background-color: ${props => props.theme.colors.white};

  @media ${props => props.theme.breakpoints.mobile} {
    flex-direction: column;
    margin-bottom: 75px;

    & button {
      margin-top: 39px;
    }
  }
`;

const CheckboxContainer = styled.div`
  max-width: 500px;
`;

const StyledButton = styled(Button)``;

const StepContainer = styled.div`
  display: flex;
  margin-bottom: 40px;
  width: 50%;

  &:nth-child(odd) {
    & p {
      margin-right: 40px;
    }
  }

  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;

    & p {
      margin-right: 0;
    }
  }
`;

const StepIcon = styled.div<{icon: string}>`
  width: 38px;
  height: 38px;
  background: url(${props => props.icon}) no-repeat center center;
  background-size: contain;
`;

const StepText = styled(Text)`
  flex-grow: 1;
  flex-basis: 0;
  margin-left: 12px;
`;

const ClickableSpan = styled.span`
  text-decoration: underline;
`;
