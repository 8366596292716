import imageCompression from 'browser-image-compression';
import {useState, useCallback, useEffect} from 'react';

export default function useWebcam(width: number, height: number) {
  const [stream, setStream] = useState<MediaStream | null>(null);

  const askPermission = useCallback(async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: {width, height, aspectRatio: width / height},
    });
    stream?.getTracks().forEach(track => track.stop());
  }, []);

  const startStream = useCallback(async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();

    let deviceId = '';

    devices.forEach(device => {
      if (device.label.toLowerCase().includes('front')) {
        deviceId = device.deviceId;
      }
    });

    const stream = await navigator.mediaDevices.getUserMedia({
      video: {
        width,
        height,
        aspectRatio: width / height,
        facingMode: deviceId ? undefined : {ideal: 'user'},
        deviceId: deviceId ? {exact: deviceId} : undefined,
      },
    });

    setStream(stream);
  }, []);

  const takePicture = useCallback(
    async (video: HTMLVideoElement): Promise<string> => {
      if (!stream || !video) {
        return '';
      }

      const {
        width: streamWidth = width,
        height: streamHeight = height,
      } = stream.getVideoTracks()[0].getSettings();

      const canvas = document.createElement('canvas');
      canvas.width = streamWidth;
      canvas.height = streamHeight;

      document.body.appendChild(canvas);

      const context = canvas.getContext('2d');
      context?.translate(streamWidth, 0);
      context?.scale(-1, 1);
      context?.drawImage(video, 0, 0, streamWidth, streamHeight);

      let image: File | Blob | string = await imageCompression.canvasToFile(
        canvas,
        'png',
        'tmp',
        Date.now()
      );
      image = await imageCompression(image, {maxSizeMB: 5});
      image = await imageCompression.getDataUrlFromFile(image);

      canvas.remove();
      return image;
    },
    [stream]
  );

  const stopStream = useCallback(() => {
    if (!stream) {
      return;
    }

    stream?.getTracks().forEach(track => track.stop());
  }, [stream]);

  // on unmount, stop stream
  useEffect(() => () => stopStream(), [stopStream]);

  return {
    startStream,
    stopStream,
    takePicture,
    stream,
    askPermission,
  };
}
