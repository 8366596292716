export const GENDERS = [
  {value: 'male', label: 'checkQualify.gender.male'},
  {value: 'female', label: 'checkQualify.gender.female'},
  {value: 'other', label: 'checkQualify.gender.other'},
];

export const LAST_EXAM_OPTIONS = [
  {value: '1', label: 'healthHistory.lastExam.1'},
  {value: '2', label: 'healthHistory.lastExam.2'},
  {value: '5', label: 'healthHistory.lastExam.5'},
];

export const EYEWEAR_TYPE_OPTIONS = [
  {value: 'Full time distance', label: 'healthHistory.eyewear.fullTime'},
  {
    value: 'Full time progressive',
    label: 'healthHistory.eyewear.fullTimeProgressive',
  },
  {value: 'Full time bifocal', label: 'healthHistory.eyewear.fullTimeBifocal'},
  {value: 'Reading', label: 'healthHistory.eyewear.reading'},
  {value: 'Computer', label: 'healthHistory.eyewear.computer'},
  {value: 'Multifocal', label: 'healthHistory.eyewear.multifocal'},
  {value: 'Sunglasses', label: 'healthHistory.eyewear.sunglasses'},
  {value: 'Sports', label: 'healthHistory.eyewear.sports'},
  {value: 'CL’s', label: 'healthHistory.eyewear.contacts'},
  {value: 'Safety', label: 'healthHistory.eyewear.safety'},
];

export const MEDICATION_OPTIONS = [
  {value: 'true', label: 'healthHistory.yes'},
  {value: 'false', label: 'healthHistory.no'},
];
