export const mapSessionToAppState = ({assessment}: any) => {
  const {...prescription} = assessment.prescription || {};

  if (assessment.productType === 'CL') {
    prescription.sameValues = true;
  }

  return {
    sessionId: assessment._id,
    productType: assessment.productType,
    firstName: assessment.firstName,
    lastName: assessment.lastName,
    email: assessment.email,
    phoneNumber: assessment.phoneNumber,
    flowMode: assessment.flow_mode,
    state: assessment.state,
    gender: assessment.gender,
    age: assessment.age,
    prescription,
    storeOriginUrl: decodeURIComponent(assessment.origin_url),
    successRedirectUrl: decodeURIComponent(assessment.ok_callback_url),
    failRedirectUrl: decodeURIComponent(assessment.fail_callback_url),
  };
};

export const getIncomingKeys = ({assessment}: any) => {
  let incoming: string[];
  incoming = Object.keys(assessment || {});

  if (assessment.prescription) {
    incoming = [...incoming, ...Object.keys(assessment.prescription)];
  }

  return incoming;
};
