import React, {MouseEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

import {Text} from '../../atoms/Typography';

import arrowLeft from '../../assets/icons/arrow-left.svg';

const BackLink: React.FC = () => {
  const {t} = useTranslation();
  const history = useHistory();

  const handleBack = (e: MouseEvent) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <BackButton onClick={handleBack}>
      <Arrow />
      <BackText>{t('action.back')}</BackText>
    </BackButton>
  );
};

export default BackLink;

const BackButton = styled.button`
  display: inline-flex;
  border: 0px;
  background-color: transparent;
  outline: none;
`;
const Arrow = styled.img.attrs(() => ({
  src: arrowLeft,
}))`
  margin-right: 5px;
`;

const BackText = styled(Text)`
  font-weight: 900;
  color: ${props => props.theme.colors.black};
`;
