import React from 'react';
import {I18nextProvider} from 'react-i18next';
import {ThemeProvider, createGlobalStyle} from 'styled-components';
import {
  StylesProvider,
  ThemeProvider as MaterialThemeProvider,
} from '@material-ui/core';

import MainNavigation from './MainNavigation';
import i18n, {loadTranslations} from '../i18n/i18n';
import AppStateProvider from './provider/AppStateProvider';

import {themes, themeType} from '../theme';

const GlobalStyle = createGlobalStyle`
    :root {
      --vh: 100vh;
    }
`;

const setDocHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

export default class App extends React.Component {
  state = {
    loading: true,
    theme: 'default' as themeType,
  };

  async componentDidMount(): Promise<void> {
    // Async init stuffs
    await Promise.all([loadTranslations()]);
    this.setState({
      loading: false,
    });

    setDocHeight();
  }

  setTheme = (store: themeType) => {
    this.setState({theme: store});
  };

  render(): JSX.Element {
    console.log(process.env.REACT_APP_API_ENDPOINT);
    if (this.state.loading) {
      return <h1>Loading...</h1>;
    }

    const theme: themeType = this.state.theme;
    const {styledTheme, muiTheme} = themes[theme];

    return (
      <AppStateProvider>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme={styledTheme}>
            <MaterialThemeProvider theme={muiTheme}>
              <GlobalStyle />
              <StylesProvider injectFirst>
                <MainNavigation setTheme={this.setTheme} />
              </StylesProvider>
            </MaterialThemeProvider>
          </ThemeProvider>
        </I18nextProvider>
      </AppStateProvider>
    );
  }
}
