import React from 'react';
import styled from 'styled-components';
import {Text} from '../atoms/Typography';

import remove from '../assets/icons/close-simple.svg';
import {ReactComponent as add} from '../assets/icons/blue-add.svg';

interface Props {
  label: string;
  onClick: () => void;
  selected?: boolean;
}

const CustomChip: React.FC<Props> = props => {
  return (
    <Chip onClick={props.onClick} selected={props.selected}>
      <Label selected={props.selected}>{props.label}</Label>
      <IconContainer selected={props.selected}>
        {props.selected ? <Remove /> : <Add />}
      </IconContainer>
    </Chip>
  );
};

export default CustomChip;

const Chip = styled.button<{selected?: boolean}>`
  padding: 7px 12px 8px;
  height: 36px;
  background-color: ${props =>
    props.selected
      ? props.theme.colors.primary.first
      : props.theme.colors.grey200};
  border-radius: 19.2px;
  display: inline-flex;
  align-items: center;
  border: none;
  outline: none;
  margin: 10px;
`;

const IconContainer = styled.div<{selected?: boolean}>`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: ${props =>
    props.selected
      ? props.theme.colors.whiteOverlay
      : props.theme.colors.white};
  margin-left: 8.5px;
`;

const Label = styled(Text)<{selected?: boolean}>`
  font-size: 14px;
  line-height: 1.5;
  color: ${props =>
    props.selected ? props.theme.colors.white : props.theme.colors.grey500};
`;
const Add = styled(add).attrs(({theme}) => ({fill: theme.colors.main}))`
  margin: 0 auto;
  width: 16px;
  height: 16px;
`;

const Remove = styled.img.attrs(() => ({
  src: remove,
}))`
  margin: 0 auto;
  width: 16px;
  height: 16px;
`;
