import {useState, useCallback, useRef, useEffect} from 'react';

/**
 * Usage:
 *
 * const audioRecorer = useAudioRecorder();
 * audioRecorer.askPermission -> throw Error
 * audioRecorer.startRecording -> throw Error
 * const blob = await audioRecorer.stopRecording(); -> throw Error
 */

interface MyMediaStream extends MediaStream {
  stream: MediaStream;
}

export default function useAudioRecorder() {
  const [permission, setPermission] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const volumeRef = useRef<number | null>();
  const audioContextRef = useRef<any>(null);

  const microphoneRef = useRef<MediaStream>();
  const stream = useRef<MyMediaStream | null>(null);
  const mediaRecorder = useRef<MediaRecorder | null>();

  // useEffect(() => {
  //   const AudioContext = window.AudioContext || window.webkitAudioContext;
  //   const audioContext = new AudioContext();
  //   audioContextRef.current = audioContext;
  // }, []);

  const askPermission = useCallback(async () => {
    if (navigator.permissions) {
      try {
        const result = await navigator.permissions.query({name: 'microphone'});
        if (result.state === 'granted') {
          setPermission(true);
          return true;
        }
      } catch (error) {
        console.log(error);
      }
    }

    microphoneRef.current = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });

    const AudioContext = window.AudioContext || window.webkitAudioContext;
    audioContextRef.current = new AudioContext();

    const audioContext = audioContextRef.current;
    const analyser = audioContext.createAnalyser();
    const microphone = audioContext.createMediaStreamSource(
      microphoneRef.current
    );
    const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

    // analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    microphone.connect(analyser);
    analyser.connect(javascriptNode);
    javascriptNode.connect(audioContext.destination);

    javascriptNode.onaudioprocess = function() {
      const array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      let values = 0;

      const length = array.length;
      for (let i = 0; i < length; i++) {
        values += array[i];
      }

      const average = values / length;
      volumeRef.current = average;
    };

    stream.current = audioContext.createMediaStreamDestination();
    setPermission(true);
  }, []);

  const startRecording = useCallback(async () => {
    if (!permission) {
      console.error('No permission asked');
      return;
    }

    if (isRecording) {
      console.error('It is already recording');
      return;
    }

    if (!microphoneRef.current) {
      microphoneRef.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      const AudioContext = window.AudioContext || window.webkitAudioContext;
      audioContextRef.current = new AudioContext();

      const audioContext = audioContextRef.current;
      const analyser = audioContext.createAnalyser();
      const microphone = audioContext.createMediaStreamSource(
        microphoneRef.current
      );
      const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

      // analyser.smoothingTimeConstant = 0.8;
      analyser.fftSize = 1024;

      microphone.connect(analyser);
      analyser.connect(javascriptNode);
      javascriptNode.connect(audioContext.destination);

      javascriptNode.onaudioprocess = function() {
        const array = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(array);
        let values = 0;

        const length = array.length;
        for (let i = 0; i < length; i++) {
          values += array[i];
        }

        const average = values / length;
        volumeRef.current = average;
      };

      stream.current = audioContext.createMediaStreamDestination();
    }

    if (!stream.current) {
      return;
    }

    const recorder = new MediaRecorder(microphoneRef.current as MediaStream);
    recorder.start();

    mediaRecorder.current = recorder;

    setIsRecording(true);
  }, [permission, isRecording, audioContextRef]);

  const stopRecording = useCallback(
    async (stopStream?: boolean): Promise<Blob> => {
      return new Promise((resolve, reject) => {
        if (!mediaRecorder.current) {
          return;
        }

        mediaRecorder.current.addEventListener(
          'dataavailable',
          (e: BlobEvent) => {
            console.log(`Audio data available`);
            resolve(e.data);
          }
        );

        mediaRecorder.current.stop();
        mediaRecorder.current = null;

        if (stopStream && microphoneRef.current) {
          microphoneRef.current.getTracks().forEach(track => track.stop());
          microphoneRef.current = undefined;
          stream.current = null;
        }
        setIsRecording(false);
      });
    },
    [isRecording, stream]
  );

  // clear all references on unmount
  useEffect(
    () => () => {
      if (mediaRecorder.current) {
        mediaRecorder.current.stop();
        mediaRecorder.current = null;
      }

      if (microphoneRef.current) {
        microphoneRef.current.getTracks().forEach(track => track.stop());
        microphoneRef.current = undefined;
        stream.current = null;
      }

      audioContextRef.current?.close();
    },
    []
  );

  return {
    askPermission,
    startRecording,
    stopRecording,
    volumeRef,
  };
}
