import styled, {css} from 'styled-components';

interface TextProps {
  bold?: boolean;
}

export const Title = styled.h1`
  font-family: Avenir;
  font-size: 58px;
  font-weight: 900;
  line-height: 1.03;
  letter-spacing: -1.6px;
  color: ${props => props.theme.colors.black};

  @media ${props => props.theme.breakpoints.mobile} {
    font-size: 48px;
    line-height: 1;
    letter-spacing: -1.4px;
    text-align: center;
  }
`;

export const Subtitle = styled.h2`
  font-family: Avenir;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.4;
  letter-spacing: -0.2px;
  color: ${props => props.theme.colors.gray};
  margin-bottom: 40px;
`;

export const Heading = styled.h3`
  font-family: Avenir;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: -0.91px;
  color: ${props => props.theme.colors.black};
`;

export const Text = styled.p<TextProps>`
  font-family: Avenir;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: ${props => props.theme.colors.gray};
  font-weight: ${({bold}) => (bold ? '900' : 'normal')};
`;

export const FooterText = styled.span`
  font-family: Avenir;
  font-size: 11px;
  line-height: 1.45;
  letter-spacing: 0.18px;
  color: ${props => props.theme.colors.lightGray};
`;
