import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

import BackLink from '../components/BackLink';
import {Wrapper, ContentLeft, ContentRight} from '../../atoms/Layout';
import {Title, Subtitle, Text} from '../../atoms/Typography';
import SecondaryButton from '../../atoms/Button/SecondaryButton';

const QualifyIssues: React.FC = () => {
  const {t} = useTranslation();
  const history = useHistory();

  const proceedWithTest = () => history.push('/prescription-product');
  const stopTest = () => history.push('/stop');

  return (
    <Wrapper light="left" header={<BackLink />} footer>
      <ContentLeft>
        <Title>{t('checkQualify.issues.title')}</Title>
      </ContentLeft>
      <ContentRight>
        <ListTitle>{t('checkQualify.issues.eyeMonitoring.title')}</ListTitle>
        <List>
          <li>
            <Text>{t('checkQualify.issues.eyeMonitoring.first')}</Text>
          </li>
          <li>
            <Text>{t('checkQualify.issues.eyeMonitoring.second')}</Text>
          </li>
          <li>
            <Text>{t('checkQualify.issues.eyeMonitoring.third')}</Text>
          </li>
          <li>
            <Text>{t('checkQualify.issues.eyeMonitoring.fourth')}</Text>
          </li>
        </List>

        <ListTitle>{t('checkQualify.issues.healthCondition.title')}</ListTitle>
        <Text>{t('checkQualify.issues.healthCondition.description')}</Text>

        <Row>
          <Button onClick={stopTest}>
            {t('checkQualify.issues.experiencedCondition')}
          </Button>
          <Button onClick={proceedWithTest}>
            {t('checkQualify.issues.noExperiencedCondition')}
          </Button>
        </Row>
      </ContentRight>
    </Wrapper>
  );
};

export default QualifyIssues;

const ListTitle = styled(Subtitle)`
  margin-bottom: 16px;
`;

const List = styled.ul`
  margin-left: 18px;
  margin-bottom: 38px;
  color: ${({theme}) => theme.colors.lightGray};

  & li > p {
    color: ${({theme}) => theme.colors.gray};
  }
`;

const Row = styled.div`
  margin-top: 48px;
  display: flex;
`;

const Button = styled(SecondaryButton)`
  width: 186px;
  margin-right: 20px;
`;
