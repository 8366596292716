import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useField} from 'react-final-form';

import Input from '../../../atoms/Form/Input';
import {OptionType} from './SelectField';

interface AutocompleteProps {
  name: string;
  label: string;
  helperText?: string;
  options: Array<OptionType>;
  validate?: () => string | undefined;
  placeholder?: string;
  loading?: boolean;
  disabled?: boolean;
}

const AutocompleteField: React.FC<AutocompleteProps> = ({
  name,
  label,
  validate,
  ...props
}) => {
  const inputProps = useField(name, {validate});

  const fieldProps = {
    label,
    error:
      !!inputProps.meta.touched &&
      (inputProps.meta.error || inputProps.meta.submitError),
    helperText:
      !!inputProps.meta.touched &&
      (inputProps.meta.error || inputProps.meta.submitError),
  };

  const inputValue = props.options.find(
    option => option.abbreviation === inputProps.input.value
  ) || {
    label: '',
    value: '',
  };

  return (
    <Autocomplete
      {...props}
      onChange={(_, value) => {
        inputProps.input.onChange(value?.abbreviation);
      }}
      value={inputValue}
      onBlur={inputProps.input.onBlur}
      onFocus={inputProps.input.onFocus}
      renderInput={params => <Input {...fieldProps} {...params} />}
      options={props.options}
      getOptionLabel={option => option.label}
      popupIcon={null}
    />
  );
};

export default AutocompleteField;
