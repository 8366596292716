import * as commonTheme from './common';
import lensCrafterTheme from './lens-crafter';
import targetOpticalTheme from './target-optical';
import contactsDirectTheme from './contacts-direct';

export const themes = {
  'lens-crafter': lensCrafterTheme,
  'target-optical': targetOpticalTheme,
  'contacts-direct': contactsDirectTheme,
  contactsdirect: contactsDirectTheme,
  default: contactsDirectTheme,
};

export type themeType = keyof typeof themes;

export const themeTypeCheck = (theme: string): themeType => {
  if (theme in themes) {
    return theme as themeType;
  }
  return 'default';
};

export const GlobalStyle = commonTheme.GlobalStyle;
