import React, {useMemo} from 'react';
import styled from 'styled-components';
import {Button as MButton, ButtonProps, CircularProgress} from '@material-ui/core';

interface Props extends ButtonProps {
  underlined?: boolean;
  inverted?: boolean;
  grey?: boolean;
  loading?: boolean;
}

const Button: React.FC<Props> = ({children, underlined, inverted, grey, loading, ...props}) => {
  const color = useMemo(() => {
    if (grey) {
      return 'default';
    }
    if (inverted) {
      return 'secondary';
    }

    return 'primary';
  }, [grey, inverted]);

  if (props.variant === 'text') {
    return (
      <TextButton {...props} disableFocusRipple={true} disableRipple={true}>
        <Label underlined={underlined}>{children}</Label>
      </TextButton>
    );
  }

  return (
    <MyButton color={color} variant="contained" {...props} disableElevation>
      <Label underlined={underlined} loading={loading}>
        {children}
      </Label>
      {loading && <Spinner />}
    </MyButton>
  );
};

export default Button;

const MyButton = styled(MButton)`
  text-transform: none;
  border-radius: 22px;
  padding: 8px 28px;
  font-size: 16px;
  font-weight: 900;
`;

const TextButton = styled(MButton)`
  &:hover {
    background-color: transparent;
  }
`;

const Label = styled.span<Props>`
  opacity: ${props => (props.loading ? 0.3 : 1)};
  border-bottom: ${props => (props.underlined ? 'solid 1px currentcolor' : 'none')};
`;

const Spinner = styled(CircularProgress).attrs(({theme}) => ({
  color: theme.colors.white,
  size: 16,
}))`
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
`;
