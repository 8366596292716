import axios from 'axios';
import https, {API_URL} from './https';

export const getTestSteps = async (): Promise<[]> => {
  try {
    const response = await https.get(`${API_URL}/exam/config`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendErrorTrace = async (type: string, trace: any) => {
  try {
    const httpsLog = axios.create({
      baseURL: API_URL.replace('/pr', ''),
    });
    const response = await httpsLog.post('/log', {type, trace});
    return response.data;
  } catch (error) {
    throw error;
  }
};
