import React from 'react';
import styled from 'styled-components';

import {
  Checkbox as MCheckbox,
  FormControlLabel,
  FormControlLabelProps,
} from '@material-ui/core';

// import ThickIcon from './icons/ThickIcon';
// import theme from './theme';

type Props = Omit<FormControlLabelProps, 'control'>;

const Checkbox: React.FC<Props> = ({
  checked,
  value,
  onChange,
  label,
  ...props
}) => {
  return (
    <StyledFormControlLabel
      control={
        <StyledCheckbox
          checked={checked}
          onChange={onChange}
          color="primary"
          // checkedIcon={<ThickIcon />}
        />
      }
      label={label}
      {...props}
    />
  );
};

export default Checkbox;

const StyledCheckbox = styled(MCheckbox)`
  border-radius: 2px;
  padding: 5px;
  height: 24px;
  width: 24px;
  margin-right: 10px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  align-items: flex-start;
`;
