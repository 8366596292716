import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import {Wrapper} from '../../atoms/Layout';
import {Title, Subtitle} from '../../atoms/Typography';
import Button from '../../atoms/Button';
import StoreLogo from '../../atoms/StoreLogo';

const Inactivity: React.FC = () => {
  const {t} = useTranslation();
  const history = useHistory();

  const restartTest = () => {
    history.replace('/');
  };

  return (
    <Wrapper dark footer header={<StoreLogo inverted />}>
      <Container>
        <FailTitle>{t('inactivity.title')}</FailTitle>
        <Description>{t('inactivity.description')}</Description>

        <StyledButton onClick={restartTest}>
          {t('inactivity.restart')}
        </StyledButton>
      </Container>
    </Wrapper>
  );
};

export default Inactivity;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 96px 48px;

  @media ${props => props.theme.breakpoints.mobile} {
    padding: 0;
    padding-bottom: 48px;
  }
`;

const FailTitle = styled(Title)`
  margin-bottom: 24px;
  white-space: pre-line;

  @media ${props => props.theme.breakpoints.mobile} {
    text-align: left;
  }
`;

const Description = styled(Subtitle)`
  white-space: pre-line;
  color: ${({theme}) => theme.colors.grey200};
`;

const StyledButton = styled(Button)`
  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
  }
`;
