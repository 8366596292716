import React, {FC} from 'react';
import styled from 'styled-components';
import {CircularProgress} from '@material-ui/core';

import AudioButton from './AudioButton';
import {Text} from '../../atoms/Typography';

interface AudioBoxProps {
  label: string;
  text?: string;
  audioUrl?: string;
}

const AudioBox: FC<AudioBoxProps> = ({label, text, audioUrl}) => (
  <Box>
    <div>
      <Label>{label}</Label>
      <Letters>{text}</Letters>
    </div>

    <ButtonContainer>
      {audioUrl ? <AudioButton audio={audioUrl} /> : <CircularProgress />}
    </ButtonContainer>
  </Box>
);

export default AudioBox;

const Box = styled.div`
  width: 100%;
  min-height: 86px;
  margin-bottom: 10px;
  padding: 8px 20px;
  border-radius: 8px;
  background-color: ${({theme}) => theme.colors.white};
  box-shadow: 0 10px 30px 0 rgba(123, 138, 154, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Label = styled(Text)`
  font-weight: 900;
  line-height: 1.69;
  text-align: left;
  color: ${({theme}) => theme.colors.grey500};
`;

const Letters = styled.p`
  font-family: OpticianSans;
  font-size: 36px;
  line-height: 0.83;
  color: ${({theme}) => theme.colors.black};
`;
