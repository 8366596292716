import React, {PureComponent} from 'react';
import {sendErrorTrace} from './api/configuration';

type Props = {};
type State = {
  hasError: boolean;
};

export default class CatcherInTheRye extends PureComponent<Props, State> {
  public readonly state: State = {
    hasError: false,
  };

  componentDidCatch(error: any, info: any) {
    this.setState({hasError: true});

    sendErrorTrace('render-error', {error, info});
  }

  render() {
    const {hasError} = this.state;

    if (hasError) {
      return <h1>An error was catched</h1>;
    }

    return this.props.children;
  }
}
