import React, {useState, useCallback, useRef, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useAppState} from '../../provider/AppStateProvider';
import Intro from './Intro';
import Step from './Step';
import {Title} from '../../../atoms/Typography';
import Button from '../../../atoms/Button';
import ImagePreview from './ImagePreview';
import {API_URL} from '../../../api/https';
import {Wrapper} from '../../../atoms/Layout/Wrapper';

export enum DIRECTIONS {
  LEFT,
  RIGHT,
  UP,
}

export enum PHASES {
  START,
  SETUP,
  LEFT,
  RIGHT,
  UP,
  REVIEW,
}

const RednessEyes: React.FC = () => {
  const {t} = useTranslation();
  const [phase, setPhase] = useState<PHASES>(PHASES.START);
  const history = useHistory();

  const appState = useAppState();

  const [imageLeft, setImageLeft] = useState('');
  const [imageUp, setImageUp] = useState('');
  const [imageRight, setImageRight] = useState('');
  const [timerReady, setTimerReady] = useState(false);

  const REDNESS_AUDIO_MAP = useMemo(
    () => ({
      [PHASES.SETUP]: API_URL + t('redness.soundStart'),
      [PHASES.LEFT]: API_URL + t('redness.soundLeft'),
      [PHASES.RIGHT]: API_URL + t('redness.soundRight'),
      [PHASES.UP]: API_URL + t('redness.soundUp'),
      [PHASES.START]: API_URL + t('redness.soundStart'),
      [PHASES.REVIEW]: '',
    }),
    [t]
  );

  const audioRef = useRef<HTMLAudioElement>(null);

  const handleRetry = useCallback(() => {
    setPhase(PHASES.START);
    setImageLeft('');
    setImageUp('');
    setImageRight('');

    if (audioRef && audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, []);

  const handleConfirm = useCallback(() => {
    appState.updateState({
      rednessImages: [imageLeft, imageRight, imageUp],
    });

    history.push('/screen-size');
  }, [appState, imageLeft, imageRight, imageUp, history]);

  useEffect(() => {
    if (
      REDNESS_AUDIO_MAP[phase] &&
      audioRef.current &&
      phase !== PHASES.START
    ) {
      audioRef.current.play().catch(console.error);
    }
  }, [phase, REDNESS_AUDIO_MAP]);

  useEffect(() => {
    audioRef.current?.addEventListener('ended', startTimer);
    return () => audioRef.current?.removeEventListener('ended', startTimer);
  }, [phase === PHASES.START]);

  const startTimer = () => {
    setTimerReady(true);
  };

  let step = null;

  if (phase === PHASES.START) {
    step = (
      <Intro
        onNext={async cb => {
          // setup audio for safari
          audioRef.current && audioRef.current.play();
          audioRef.current && audioRef.current.pause();

          const result = await cb();

          if (result) {
            setPhase(PHASES.SETUP);
            window.scrollTo(0, 0);
          }
        }}
      />
    );
  }

  if (phase === PHASES.SETUP) {
    step = (
      <Step
        phase={phase}
        onNext={() => {
          setPhase(PHASES.LEFT);
          setTimerReady(false);
        }}
        onCancel={handleRetry}
      />
    );
  }

  if (phase === PHASES.LEFT) {
    step = (
      <Step
        phase={phase}
        onNext={image => {
          image && setImageLeft(image);
          setPhase(PHASES.RIGHT);
          setTimerReady(false);
        }}
        onCancel={handleRetry}
        takePhoto
        timerReady={timerReady}
      />
    );
  }

  if (phase === PHASES.RIGHT) {
    step = (
      <Step
        phase={phase}
        onNext={image => {
          image && setImageRight(image);
          setPhase(PHASES.UP);
          setTimerReady(false);
        }}
        onCancel={handleRetry}
        takePhoto
        timerReady={timerReady}
      />
    );
  }

  if (phase === PHASES.UP) {
    step = (
      <Step
        phase={phase}
        onNext={image => {
          image && setImageUp(image);
          setPhase(PHASES.REVIEW);
          setTimerReady(false);
        }}
        onCancel={handleRetry}
        takePhoto
        timerReady={timerReady}
      />
    );
  }

  if (step) {
    return (
      <>
        {step}
        <audio ref={audioRef} src={REDNESS_AUDIO_MAP[phase]}></audio>
      </>
    );
  }

  return (
    <Wrapper>
      <Container>
        <Heading>{t('redness.clear')}</Heading>

        <Row>
          <ImagePreview src={imageLeft} direction={DIRECTIONS.LEFT} />
          <ImagePreview src={imageRight} direction={DIRECTIONS.RIGHT} />
          <ImagePreview src={imageUp} direction={DIRECTIONS.UP} />
        </Row>

        <ButtonRow>
          <StyledButton onClick={handleRetry} inverted>
            {t('redness.retake')}
          </StyledButton>
          <StyledButton onClick={handleConfirm}>
            {t('redness.confirm')}
          </StyledButton>
        </ButtonRow>
      </Container>
    </Wrapper>
  );
};

export default RednessEyes;

const Container = styled.div`
  max-width: 833px;
  margin: 0 auto;
  text-align: center;
`;

const Heading = styled(Title)`
  padding: 95px 32px 65px;

  @media ${props => props.theme.breakpoints.mobile} {
    padding: 24px 32px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${props => props.theme.breakpoints.mobile} {
    flex-direction: column;
  }
`;

const ButtonRow = styled(Row)`
  margin-top: 73px;
  justify-content: center;

  @media ${props => props.theme.breakpoints.mobile} {
    margin: 0;
    padding: 32px 0;
  }
`;

const StyledButton = styled(Button)`
  margin: 0 24px;

  @media ${props => props.theme.breakpoints.mobile} {
    margin-bottom: 32px;
  }
`;
