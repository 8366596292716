import React, {useState, useEffect, useMemo, useRef} from 'react';
import styled from 'styled-components';

interface Props {
  withCircles?: boolean;
  lightRail?: boolean;
  darkText?: boolean;
  endTime: number;
  disabled?: boolean;
  initialTime?: number;
  volumeRef?: any;
}

const calcRemainingTime = (endTime: number) =>
  Math.max(0, (endTime - Date.now()) / 1000);

const CountDown: React.FC<Props> = props => {
  const [time, setTime] = useState(
    props.initialTime || calcRemainingTime(props.endTime)
  );
  const innerAudioFeedback = useRef<HTMLDivElement>(null!);
  const middleAudioFeedback = useRef<HTMLDivElement>(null!);
  const outerAudioFeedback = useRef<HTMLDivElement>(null!);

  const duration = useMemo(() => {
    return calcRemainingTime(props.endTime);
  }, [props.endTime, props.disabled]);

  useEffect(() => {
    if (props.disabled || !props.volumeRef) {
      return;
    }

    let timer: any = null;
    const updateFeedback = () => {
      const volumeValue = props.volumeRef.current;
      const scale = 1 + volumeValue / 100;

      innerAudioFeedback.current.style.transform = `scale(${scale * 1.6})`;
      outerAudioFeedback.current.style.transform = `scale(${scale * 1.5})`;
      middleAudioFeedback.current.style.transform = `scale(${scale * 1.4})`;

      timer = requestAnimationFrame(updateFeedback);
    };

    updateFeedback();
    return () => cancelAnimationFrame(timer);
  }, [props.volumeRef, props.disabled]);

  useEffect(() => {
    if (props.disabled) {
      return;
    }
    const interval = setInterval(() => {
      const remainingTime = calcRemainingTime(props.endTime);

      if (remainingTime <= 0) {
        setTime(0);
        clearInterval(interval);
        return;
      }
      setTime(remainingTime);
    }, 100);

    return () => clearInterval(interval);
  }, [props.endTime, props.disabled]);

  const timer = (
    <CountDownContainer>
      <Svg>
        <RailCircle
          light={props.lightRail}
          withCircles={props.withCircles}
          darkText={props.darkText}
          r="33.5"
          cx="37.5"
          cy="37.5"
        />
        <ValueCircle
          r="33.5"
          cx="37.5"
          cy="37.5"
          seconds={duration}
          animate={!props.disabled}
          withCircles={props.withCircles}
          darkText={props.darkText}
        />
      </Svg>
      <Number withCircles={props.withCircles} darkText={props.darkText}>
        {time.toFixed(0)}
      </Number>
    </CountDownContainer>
  );

  if (!props.withCircles) {
    return timer;
  }

  return (
    <FeedBackContainer>
      <BackCircleThree ref={outerAudioFeedback} />
      <BackCircleTwo ref={middleAudioFeedback} />
      <BackCircleOne ref={innerAudioFeedback} />
      <span>{timer}</span>
    </FeedBackContainer>
  );
};

export default CountDown;

const CountDownContainer = styled.div`
  position: relative;
  height: 77px;
  width: 77px;
  text-align: center;
  background-color: transparent;
`;
const Number = styled.h1<{withCircles?: boolean; darkText?: boolean}>`
  color: ${props => {
    if (props.withCircles) {
      return props.theme.colors.main;
    }

    if (props.darkText) {
      return props.theme.colors.black;
    }

    return props.theme.colors.white;
  }};

  display: inline-block;
  font-family: Avenir;

  line-height: 75px;
  letter-spacing: -1.6px;
  font-size: 32px;
  position: absolute;
  top: 6;
  right: 0;
  left: 0;
  bottom: 0;
`;
const Svg = styled.svg`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 75px;
  height: 75px;
  transform: rotate(-90deg);

  @keyframes countdown {
    from {
      stroke-dashoffset: 0px;
    }
    to {
      stroke-dashoffset: 213.62px;
    }
  }
`;
const RailCircle = styled.circle<{
  withCircles?: boolean;
  light?: boolean;
  darkText?: boolean;
}>`
  stroke: ${props => {
    if (props.withCircles) {
      return props.theme.colors.timerCirclesRail;
    }
    if (props.light) {
      return props.theme.colors.timerLightRail;
    }
    if (props.darkText) {
      return props.theme.colors.ironGray;
    }
    return props.theme.colors.timerRail;
  }};
  stroke-width: 7.7px;
  fill: ${props => (props.withCircles ? props.theme.colors.white : 'none')};
`;

const ValueCircle = styled.circle<{
  seconds: number;
  withCircles?: boolean;
  darkText?: boolean;
  animate: boolean;
}>`
  stroke-dasharray: 213.62px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 8px;
  stroke: ${props => {
    if (props.withCircles) {
      return props.theme.colors.timerCircles; //mariner
    }
    if (props.darkText) {
      return props.theme.colors.grey500;
    }
    return props.theme.colors.timer;
  }};
  fill: none;

  animation: ${props =>
    props.animate ? `countdown ${props.seconds}s linear forwards` : 'none'};
`;

const BackCircleOne = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.timerCirclesInner};
  transition: transform 0.1s;
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
`;

const BackCircleTwo = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.timerCirclesMiddle};
  position: absolute;
  transition: transform 0.2s;
  top: calc(50% - 35px);
  left: calc(50% - 35px);
`;

const BackCircleThree = styled.div`
  width: 76px;
  height: 76px;
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.timerCirclesOuter};
  position: absolute;
  transition: transform 0.3s;
  top: calc(50% - 38px);
  left: calc(50% - 38px);
`;

const FeedBackContainer = styled.div`
  width: 200px;
  height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
