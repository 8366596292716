import {createGlobalStyle} from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Avenir';
    src: url('/fonts/AvenirLTPro-Medium.woff2') format('woff2'),
        url('/fonts/AvenirLTPro-Medium.ttf') format('truetype'),
        url('/fonts/AvenirLTPro-Medium.svg#AvenirLTPro-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Avenir';
    src: url('/fonts/AvenirLTPro-Black.woff2') format('woff2'),
        url('/fonts/AvenirLTPro-Black.ttf') format('truetype'),
        url('/fonts/AvenirLTPro-Black.svg#AvenirLTPro-Black') format('svg');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Avenir';
    src: url('/fonts/AvenirLTPro-Heavy.woff2') format('woff2'),
        url('/fonts/AvenirLTPro-Heavy.ttf') format('truetype'),
        url('/fonts/AvenirLTPro-Heavy.svg#AvenirLTPro-Heavy') format('svg');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'OpticianSans';
    src: url('/fonts/Optiker-K.woff2') format('woff2'), /* Modern Browsers */
         url('/fonts/Optiker-K.ttf') format('truetype'); /* Safari, Android, iOS */
             font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }


  html,
  body {
    height: 100%;
    font-family: Avenir, Arial, sans-serif;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

export const colors = {
  primary: {
    first: '#4072EE',
  },
  main: '#4072EE',
  white: '#ffffff',
  black: '#1f2327',
  gray: '#535b64',
  lightGray: '#97a3af',
  blue: '#4072ee',
  red: '#d2202f',
  grey50: '#f6f7f8',
  grey100: '#f1f2f4',
  grey200: '#e8ebed',
  grey400: '#a2abb5',
  grey600: '#535b64',
  grey500: '#7b8a9a',
  grey1000: '#1f2327',
  ironGray: '#ccd1d6',
  porcelain: '#f4f5f6',
  mariner: '#2e5dd3',
  whiteOverlay: 'rgba(255,255,255, 0.3)',
  testInstruction1: '#1f2327',
  testInstruction2: '#535b64',
  darkBackground: '#535b64',
  timer: '#ffffff',
  timerCircles: '#2e5dd3',
  timerRail: '#1f2327',
  timerLightRail: '#535b64',
  timerCirclesRail: '#dbe5ff',
  timerCirclesOuter: '#eaeef8',
  timerCirclesMiddle: '#d3ddf7',
  timerCirclesInner: '#becef5',
  wrapperBackgroundLeft: '#ffffff',
  wrapperBackgroundTop: '#f6f7f8',
  wrapperBackgroundBottom: '#f6f7f8',
};

export const breakpoints = {
  mobile:
    '(max-width: 1023px) and (orientation: landscape), (max-width: 767px) and (orientation: portrait)',
  tablet: '(max-width: 1024px)',
  tabletPortrait: '(max-width: 768px) and (orientation: portrait)',
  smallAndFat: '(min-width: 1023px) and (max-height: 620px)',
};

export const shadows = {
  wrapper: '12px 0 48px 0 #e8ebed;',
};

export const muiTheme = {
  typography: {
    fontFamily: 'Avenir, Arial, sans-serif',
  },
  shape: {
    borderRadius: 5,
    borderThickness: 1,
  },
  palette: {
    primary: {
      main: '#4072EE',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FFFFFF',
      contrastText: '#4072EE',
    },
    error: {
      main: '#E61B34',
      contrastText: '#fff',
    },
    background: {
      default: '#fff',
      secondary: '#f6f7f8',
    },

    grey: {
      50: '#f6f7f8',
      100: '#f1f2f4',
      200: '#e8ebed',
      300: '#ccd1d6',
      400: '#a2abb5',
      500: '#7b8a9a',
      600: '#535b64',
      700: '#464e54',
      800: '#2d3238',
      900: '#292e33',
      1000: '#1f2327',
      1100: '#171a1c',
      1200: '#111214',
    },
  },
  overrides: {
    MuiPopover: {
      paper: {
        border: 'solid 2px #ccd1d6',
      },
    },
    MuiList: {
      padding: {
        paddingBottom: 0,
        paddingTop: 0,
      },
    },
    MuiAutocomplete: {
      paper: {
        border: 'solid 2px #ccd1d6',
      },
      option: {
        border: '2px solid',
        borderColor: '#ccd1d6',
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        '&:last-child': {
          borderBottomWidth: 0,
        },
        height: '56px',
      },
      listbox: {
        paddingBottom: 0,
        paddingTop: 0,
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: '#a2abb5',
        color: '#fff',
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderWidth: '2px',
      },
    },
  },
};
