export const ADD_DESCRIPTION_VALUES = [
  {
    value: 'Low',
    label: 'Low',
  },
  {
    value: 'Lo',
    label: 'Lo',
  },
  {
    value: 'Medium',
    label: 'Medium',
  },
  {
    value: '​Med',
    label: '​Med',
  },
  {
    value: 'Mid',
    label: 'Mid',
  },
  {
    value: 'High',
    label: 'High',
  },
  {
    value: 'Hi',
    label: 'Hi',
  },
  {
    value: 'D',
    label: 'D',
  },
  {
    value: 'N',
    label: 'N',
  },
];

export const SPHERE_VALUES = [
  {
    value: 'PL',
    label: 'PL',
  },
  {
    value: '0.25',
    label: '0.25',
  },
  {
    value: '0.50',
    label: '0.50',
  },
  {
    value: '0.75',
    label: '0.75',
  },
  {
    value: '1.00',
    label: '1.00',
  },
  {
    value: '1.25',
    label: '1.25',
  },
  {
    value: '1.50',
    label: '1.50',
  },
  {
    value: '1.75',
    label: '1.75',
  },
  {
    value: '2.00',
    label: '2.00',
  },
  {
    value: '2.25',
    label: '2.25',
  },
  {
    value: '2.50',
    label: '2.50',
  },
  {
    value: '2.75',
    label: '2.75',
  },
  {
    value: '3.00',
    label: '3.00',
  },
  {
    value: '3.25',
    label: '3.25',
  },
  {
    value: '3.50',
    label: '3.50',
  },
  {
    value: '3.75',
    label: '3.75',
  },
  {
    value: '4.00',
    label: '4.00',
  },
  {
    value: '4.25',
    label: '4.25',
  },
  {
    value: '4.50',
    label: '4.50',
  },
  {
    value: '4.75',
    label: '4.75',
  },
  {
    value: '5.00',
    label: '5.00',
  },
  {
    value: '5.25',
    label: '5.25',
  },
  {
    value: '5.50',
    label: '5.50',
  },
  {
    value: '5.75',
    label: '5.75',
  },
  {
    value: '6.00',
    label: '6.00',
  },
  {
    value: '6.25',
    label: '6.25',
  },
  {
    value: '6.50',
    label: '6.50',
  },
  {
    value: '6.75',
    label: '6.75',
  },
  {
    value: '7.00',
    label: '7.00',
  },
  {
    value: '7.25',
    label: '7.25',
  },
  {
    value: '7.50',
    label: '7.50',
  },
  {
    value: '7.75',
    label: '7.75',
  },
  {
    value: '8.00',
    label: '8.00',
  },
  {
    value: '8.25',
    label: '8.25',
  },
  {
    value: '8.50',
    label: '8.50',
  },
  {
    value: '8.75',
    label: '8.75',
  },
  {
    value: '9.00',
    label: '9.00',
  },
  {
    value: '9.25',
    label: '9.25',
  },
  {
    value: '9.50',
    label: '9.50',
  },
  {
    value: '9.75',
    label: '9.75',
  },
  {
    value: '10.00',
    label: '10.00',
  },
];

export const ADD_VALUES = [
  {
    value: '0.00',
    label: '0.00',
  },
  {
    value: '0.25',
    label: '0.25',
  },
  {
    value: '0.50',
    label: '0.50',
  },
  {
    value: '0.75',
    label: '0.75',
  },
  {
    value: '1.00',
    label: '1.00',
  },
  {
    value: '1.25',
    label: '1.25',
  },
  {
    value: '1.50',
    label: '1.50',
  },
  {
    value: '1.75',
    label: '1.75',
  },
  {
    value: '2.00',
    label: '2.00',
  },
  {
    value: '2.25',
    label: '2.25',
  },
  {
    value: '2.50',
    label: '2.50',
  },
  {
    value: '2.75',
    label: '2.75',
  },
  {
    value: '3.00',
    label: '3.00',
  },
  {
    value: '3.25',
    label: '3.25',
  },
  {
    value: '3.50',
    label: '3.50',
  },
  {
    value: '3.75',
    label: '3.75',
  },
  {
    value: '4.00',
    label: '4.00',
  },
];

export const PRISM_VALUES = [
  {
    value: '0.00',
    label: '0.00',
  },
  {
    value: '0.25',
    label: '0.25',
  },
  {
    value: '0.50',
    label: '0.50',
  },
  {
    value: '0.75',
    label: '0.75',
  },
  {
    value: '1.00',
    label: '1.00',
  },
  {
    value: '1.25',
    label: '1.25',
  },
  {
    value: '1.50',
    label: '1.50',
  },
  {
    value: '1.75',
    label: '1.75',
  },
  {
    value: '2.00',
    label: '2.00',
  },
  {
    value: '2.25',
    label: '2.25',
  },
  {
    value: '2.50',
    label: '2.50',
  },
  {
    value: '2.75',
    label: '2.75',
  },
  {
    value: '3.00',
    label: '3.00',
  },
  {
    value: '3.25',
    label: '3.25',
  },
  {
    value: '3.50',
    label: '3.50',
  },
  {
    value: '3.75',
    label: '3.75',
  },
  {
    value: '4.00',
    label: '4.00',
  },
  {
    value: '4.25',
    label: '4.25',
  },
  {
    value: '4.50',
    label: '4.50',
  },
  {
    value: '4.75',
    label: '4.75',
  },
  {
    value: '5.00',
    label: '5.00',
  },
  {
    value: '5.25',
    label: '5.25',
  },
  {
    value: '5.50',
    label: '5.50',
  },
  {
    value: '5.75',
    label: '5.75',
  },
  {
    value: '6.00',
    label: '6.00',
  },
  {
    value: '6.25',
    label: '6.25',
  },
  {
    value: '6.50',
    label: '6.50',
  },
  {
    value: '6.75',
    label: '6.75',
  },
  {
    value: '7.00',
    label: '7.00',
  },
  {
    value: '7.25',
    label: '7.25',
  },
  {
    value: '7.50',
    label: '7.50',
  },
  {
    value: '7.75',
    label: '7.75',
  },
  {
    value: '8.00',
    label: '8.00',
  },
  {
    value: '8.25',
    label: '8.25',
  },
  {
    value: '8.50',
    label: '8.50',
  },
  {
    value: '8.75',
    label: '8.75',
  },
  {
    value: '9.00',
    label: '9.00',
  },
  {
    value: '9.25',
    label: '9.25',
  },
  {
    value: '9.50',
    label: '9.50',
  },
  {
    value: '9.75',
    label: '9.75',
  },
  {
    value: '10.00',
    label: '10.00',
  },
  {
    value: '10.25',
    label: '10.25',
  },
  {
    value: '10.50',
    label: '10.50',
  },
  {
    value: '10.75',
    label: '10.75',
  },
  {
    value: '11.00',
    label: '11.00',
  },
  {
    value: '11.25',
    label: '11.25',
  },
  {
    value: '11.50',
    label: '11.50',
  },
  {
    value: '11.75',
    label: '11.75',
  },
  {
    value: '12.00',
    label: '12.00',
  },
  {
    value: '12.25',
    label: '12.25',
  },
  {
    value: '12.50',
    label: '12.50',
  },
  {
    value: '12.75',
    label: '12.75',
  },
  {
    value: '13.00',
    label: '13.00',
  },
  {
    value: '13.25',
    label: '13.25',
  },
  {
    value: '13.50',
    label: '13.50',
  },
  {
    value: '13.75',
    label: '13.75',
  },
  {
    value: '14.00',
    label: '14.00',
  },
  {
    value: '14.25',
    label: '14.25',
  },
  {
    value: '14.50',
    label: '14.50',
  },
  {
    value: '14.75',
    label: '14.75',
  },
  {
    value: '15.00',
    label: '15.00',
  },
  {
    value: '15.25',
    label: '15.25',
  },
  {
    value: '15.50',
    label: '15.50',
  },
  {
    value: '15.75',
    label: '15.75',
  },
  {
    value: '16.00',
    label: '16.00',
  },
  {
    value: '16.25',
    label: '16.25',
  },
  {
    value: '16.50',
    label: '16.50',
  },
  {
    value: '16.75',
    label: '16.75',
  },
  {
    value: '17.00',
    label: '17.00',
  },
  {
    value: '17.25',
    label: '17.25',
  },
  {
    value: '17.50',
    label: '17.50',
  },
  {
    value: '17.75',
    label: '17.75',
  },
  {
    value: '18.00',
    label: '18.00',
  },
  {
    value: '18.25',
    label: '18.25',
  },
  {
    value: '18.50',
    label: '18.50',
  },
  {
    value: '18.75',
    label: '18.75',
  },
  {
    value: '19.00',
    label: '19.00',
  },
  {
    value: '19.25',
    label: '19.25',
  },
  {
    value: '19.50',
    label: '19.50',
  },
  {
    value: '19.75',
    label: '19.75',
  },
  {
    value: '20.00',
    label: '20.00',
  },
];

export const CYLINDER_VALUES = () => {
  const values = [{value: 'DS', label: 'DS'}];

  for (let i = -0.75; i > -6; i -= 0.5) {
    const value = i.toString();
    const obj = {
      value: value,
      label: value,
    };

    values.push(obj);
  }
  return values;
};

export const AXIS_VALUES = () => {
  const values = [];

  for (let i = 0; i <= 180; i += 5) {
    const value = (i < 10 ? '00' : i < 100 ? '0' : '') + i;
    const obj = {
      value: value,
      label: value,
    };

    values.push(obj);
  }

  return values;
};
