import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import Button from '../../../atoms/Button';
import {Title, Subtitle, Heading} from '../../../atoms/Typography';
import close from '../../../assets/icons/close-dark.svg';

interface Props {
  visible: boolean;
  onQuit: () => void;
  onContinue: () => void;
  info?: boolean;
}

const QualificationStateModal: React.FC<Props> = ({
  visible,
  onQuit,
  onContinue,
  info,
}) => {
  const {t} = useTranslation();

  if (!visible) {
    return null;
  }

  return (
    <Container>
      <ModalContainer>
        <CloseButton onClick={onQuit} />
        <TextContainer>
          <StyledTitle>
            {info
              ? t('checkQualify.fail.state.infoTitle')
              : t('checkQualify.fail.state.title')}
          </StyledTitle>

          <Subtitle>{t('checkQualify.fail.state.subtitle')}</Subtitle>
        </TextContainer>

        <Row>
          <StyledButton onClick={onQuit} inverted>
            {info
              ? t('checkQualify.fail.state.back')
              : t('checkQualify.fail.state.back')}
          </StyledButton>
          <StyledButton onClick={onContinue}>
            {t('checkQualify.fail.state.backEcommerce')}
          </StyledButton>
        </Row>
      </ModalContainer>
    </Container>
  );
};

export default QualificationStateModal;

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
`;

const ModalContainer = styled.div`
  overflow: hidden;
  width: 832px;
  border-radius: 24px;
  background-color: ${({theme}) => theme.colors.white};
  box-shadow: 0 10px 30px 0 rgba(31, 35, 39, 0.2);
  position: relative;

  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
    min-height: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: visible;
  }
`;

const TextContainer = styled.div`
  padding: 48px 48px 16px;
  text-align: left;

  @media ${props => props.theme.breakpoints.mobile} {
    padding: 24px;
  }
`;

const StyledTitle = styled(Heading)`
  margin-bottom: 32px;
  font-weight: 900;
`;

const Row = styled.div`
  padding: 48px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: ${({theme}) => theme.colors.grey100};

  @media ${props => props.theme.breakpoints.mobile} {
    display: block;
  }
`;

const StyledButton = styled(Button)`
  margin: 0 24px;
  min-width: 252px;

  @media ${props => props.theme.breakpoints.mobile} {
    min-width: 100%;
    margin: 0;
    margin-bottom: 24px;
  }
`;
const CloseButton = styled.img.attrs({src: close})`
  cursor: pointer;
  position: absolute;
  top: 48px;
  right: 48px;
`;
