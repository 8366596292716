import React from 'react';
import styled from 'styled-components';

import {Wrapper} from '../../atoms/Layout';
import {Title, Subtitle} from '../../atoms/Typography';
import Button from '../../atoms/Button';

interface Action {
  text: string;
  handleClick: () => void;
}

interface Props {
  title: string;
  subtitle: string;
  action?: Action;
}

const InvalidSession: React.FC<Props> = ({title, subtitle, action}) => (
  <Wrapper dark footer>
    <Container>
      <FailTitle>{title}</FailTitle>
      <Description>{subtitle}</Description>

      {action && (
        <StyledButton onClick={action.handleClick}>{action.text}</StyledButton>
      )}
    </Container>
  </Wrapper>
);

export default InvalidSession;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 96px 48px;

  @media ${props => props.theme.breakpoints.mobile} {
    padding: 0;
    padding-top: 48px;
    padding-bottom: 48px;
  }
`;

const FailTitle = styled(Title)`
  margin-bottom: 24px;
  white-space: pre-line;

  @media ${props => props.theme.breakpoints.mobile} {
    text-align: left;
  }
`;

const Description = styled(Subtitle)`
  white-space: pre-line;
  color: ${({theme}) => theme.colors.grey200};
`;

const StyledButton = styled(Button)`
  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
  }
`;
