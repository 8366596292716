import React, {useEffect, useCallback, useState} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Welcome from './views/Welcome';
import Test from './views/Test';
import Meter from './views/Meter';
import StateSelection from './views/User/StateSelection';
// import Login from './views/Login';
import TestCompleted from './views/TestCompleted';
import RednessEyes from './views/RednessEyes';
import Prescription from './views/Prescription';
import BasicInfo from './views/User/BasicInfo';
import HealthHistory from './views/User/HealthHistory';
import Conditions from './views/User/Conditions';
import QualifyIssues from './views/QualifyIssues';
import QualificationFail from './views/QualificationFail';
import ErrorScreen from './views/ErrorScreen';
import Inactivity from './views/Inactivity';
import ManualPrescription from './views/User/ManualPrescription';
import PrescriptionProduct from './views/PrescriptionProduct';
import Thanking from './views/Thanking';
import HowItWorks from './views/HowItWorks';
import SizeScreenIntro from './views/SizeScreenIntro';
import Name from './views/User/Name';
import Permissions from './views/Permissions';
import {Main, Wrapper} from '../atoms/Layout';
import CheckDimensions from './views/CheckDimensions';
import {postSession, postAbandonSession} from '../api/user';
import {useAppState} from './provider/AppStateProvider';
import {getTestSteps} from '../api/configuration';
import {
  mapSessionToAppState,
  getIncomingKeys,
} from '../utils/mapSessionToAppState';
import {themeType, themeTypeCheck} from '../theme';
import Spinner from '../atoms/Spinner';

interface Props {
  setTheme: (store: themeType) => void;
}

const MainNavigation: React.FC<Props> = ({setTheme}) => {
  const {t} = useTranslation();
  const appState = useAppState();
  const [loading, setLoading] = useState(true);
  const [validSession, setValidSession] = useState(false);

  useEffect(() => {
    (async () => {
      if (!appState.state.token) {
        setLoading(false);
        return;
      }

      try {
        const [session, steps] = await Promise.all([
          postSession(appState.state.token),
          getTestSteps(),
        ]);

        appState.updateState({
          ...mapSessionToAppState(session),
          incomingData: getIncomingKeys(session),
          stepConfig: steps,
        });

        //TODO: set theme from backend
        setTheme(themeTypeCheck(session.assessment.theme));

        setValidSession(true);
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const onUnload = useCallback(() => {
    const {sessionId, email} = appState.state;
    if (sessionId) {
      postAbandonSession(sessionId, email);
    }
  }, [appState.state]);

  useEffect(() => {
    window.addEventListener('unload', onUnload);

    return () => window.removeEventListener('unload', onUnload);
  }, [onUnload]);

  const PrivateRoute = useCallback(
    props =>
      process.env.NODE_ENV !== 'production' || appState.state.sessionId ? (
        <Route {...props} />
      ) : (
        <Redirect from={props.path} to="/" />
      ),
    [appState.state.sessionId]
  );

  if (loading) {
    return (
      <Wrapper footer>
        <Spinner />
      </Wrapper>
    );
  }

  if (!validSession) {
    return (
      <ErrorScreen
        title={t('invalidSession.title')}
        subtitle={t('invalidSession.subtitle')}
      />
    );
  }

  return (
    <Router>
      <Main>
        <Switch>
          <CheckDimensions>
            <Route exact path="/" component={Welcome} />
            <PrivateRoute exact path="/how-it-works" component={HowItWorks} />
            <PrivateRoute path="/start" component={Name} />
            <PrivateRoute path="/nation" component={StateSelection} />
            <PrivateRoute path="/user-info/:step?" component={BasicInfo} />
            <PrivateRoute
              path="/health-history/:step?"
              component={HealthHistory}
            />
            <PrivateRoute
              path="/prescription-product"
              component={PrescriptionProduct}
            />
            <PrivateRoute path="/prescription" component={Prescription} />
            <PrivateRoute
              path="/manual-prescription"
              component={ManualPrescription}
            />
            <PrivateRoute path="/redness" component={RednessEyes} />
            <PrivateRoute path="/screen-size" component={SizeScreenIntro} />
            <PrivateRoute path="/meter" component={Meter} />
            <PrivateRoute path="/test" component={Test} />
            <PrivateRoute path="/completed" component={TestCompleted} />
            <PrivateRoute path="/thanking" component={Thanking} />

            <PrivateRoute path="/conditions/:step?" component={Conditions} />
            <PrivateRoute path="/issues" component={QualifyIssues} />
            <PrivateRoute path="/stop" component={QualificationFail} />
            <PrivateRoute path="/inactivity" component={Inactivity} />

            <PrivateRoute path="/permissions" component={Permissions} />
          </CheckDimensions>
        </Switch>
      </Main>
    </Router>
  );
};

export default MainNavigation;
