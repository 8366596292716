import React, {useCallback} from 'react';
import {Form} from 'react-final-form';
import {OnChange} from 'react-final-form-listeners';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../../atoms/Button';
import {Wrapper} from '../../../atoms/Layout';
import {Text, Title} from '../../../atoms/Typography';
import {
  ADD_DESCRIPTION_VALUES,
  ADD_VALUES,
  CYLINDER_VALUES,
  PRISM_VALUES,
  SPHERE_VALUES,
  AXIS_VALUES,
} from '../../../constants/prescriptionValues';
import {PrescriptionType} from '../../../types';
import {requiredInterval} from '../../../utils/validation';
import BackLink from '../../components/BackLink';
import CheckboxField from '../../components/Fields/CheckboxField';
import PrescriptionField from '../../components/Fields/PrescriptionField';
import TextField from '../../components/Fields/TextField';
import {useAppState} from '../../provider/AppStateProvider';

const ManualPrescription: React.FC = () => {
  const {t} = useTranslation();
  const appState = useAppState();
  const history = useHistory();

  const onSubmit = (data: PrescriptionType): void => {
    const prescription =
      data.sameValues === true
        ? {
            ...data,
            lensBrandLeft: data.lensBrandRight,
            lensTypeLeft: data.lensTypeRight,
          }
        : data;

    appState.updateState({
      prescription,
    });
    history.push('/redness');
  };
  const productType = appState.state.productType;

  const isContactLens = productType === 'CL';

  const leftAxisMutator = useCallback(([leftCylinder], state, utils) => {
    if (leftCylinder === 'DS') {
      utils.changeValue(state, 'leftAxis', () => null);
    }
  }, []);

  const rightAxisMutator = useCallback(([rightCylinder], state, utils) => {
    if (rightCylinder === 'DS') {
      utils.changeValue(state, 'rightAxis', () => null);
    }
  }, []);

  return (
    <Wrapper>
      <Form
        mutators={{
          leftAxisMutator,
          rightAxisMutator,
        }}
        initialValues={appState.state.prescription}
        onSubmit={onSubmit}
        render={({
          hasValidationErrors,
          handleSubmit,
          values,
          form,
          pristine,
          dirty,
        }): JSX.Element => {
          const isLeftBCAndDIANeeded =
            !!values.leftSphere ||
            !!values.leftCylinder ||
            !!values.leftAdd ||
            !!values.leftBaseCurve ||
            !!values.leftDiameter;

          const isRightBCAndDIANeeded =
            !!values.rightSphere ||
            !!values.rightCylinder ||
            !!values.rightAdd ||
            !!values.rightBaseCurve ||
            !!values.rightDiameter;

          const isEyefilled = Object.keys(values).some((key: string) => {
            return !!values[key] && key !== 'clBrand' && key !== 'clType';
          });

          return (
            <Content>
              <OnChange name="leftCylinder">
                {value => {
                  form.mutators.leftAxisMutator(value);
                }}
              </OnChange>
              <OnChange name="rightCylinder">
                {value => {
                  form.mutators.rightAxisMutator(value);
                }}
              </OnChange>
              <TopContainer>
                <TitleContainer>
                  <BackLink />
                  <ManualTitle>{t('prescription.manual.title')}</ManualTitle>
                </TitleContainer>
                <FormContainer>
                  <HalForm>
                    {/* RIGHT EYE */}
                    <GroupLabel>
                      <BordLeft />
                      <GroupTitle>{t('prescription.manual.rightEye')}</GroupTitle>
                      <BordRight />
                    </GroupLabel>
                    <InputRow>
                      <InputWrapper>
                        <PrescriptionField
                          withOperator
                          options={SPHERE_VALUES}
                          name={'rightSphere'}
                          label={t('prescription.manual.sphereLabel')}
                        />
                      </InputWrapper>
                    </InputRow>
                    <InputRow>
                      <InputWrapper>
                        <PrescriptionField
                          options={CYLINDER_VALUES()}
                          name={'rightCylinder'}
                          label={t('prescription.manual.cylinderLabel')}
                          optionColumns={1}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <PrescriptionField
                          options={AXIS_VALUES()}
                          name={'rightAxis'}
                          label={t('prescription.manual.axisLabel')}
                          disabled={!values.rightCylinder || values.rightCylinder === 'DS'}
                          optionColumns={1}
                        />
                      </InputWrapper>
                    </InputRow>
                    <InputRow>
                      <InputWrapper>
                        <PrescriptionField
                          options={ADD_VALUES}
                          name={'rightAdd'}
                          label={t('prescription.manual.addLabel')}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <PrescriptionField
                          options={ADD_DESCRIPTION_VALUES}
                          name={'rightAddDesc'}
                          label={t('prescription.manual.addDescLabel')}
                        />
                      </InputWrapper>
                    </InputRow>
                    <InputRow>
                      {isContactLens ? (
                        <>
                          <InputWrapper>
                            <TextField
                              name={'rightBaseCurve'}
                              fullWidth
                              validate={requiredInterval(8, 10, isRightBCAndDIANeeded)}
                              label={t('prescription.manual.baseCurveLabel')}
                              // update key to reregister validate function
                              key={isRightBCAndDIANeeded ? 'rightBC-0' : 'rightBC-1'}
                            />
                          </InputWrapper>

                          <InputWrapper>
                            <TextField
                              fullWidth
                              name={'rightDiameter'}
                              validate={requiredInterval(13, 15, isRightBCAndDIANeeded)}
                              label={t('prescription.manual.diameterLabel')}
                              // update key to reregister validate function
                              key={isRightBCAndDIANeeded ? 'rightD-0' : 'rightD-1'}
                            />
                          </InputWrapper>
                        </>
                      ) : (
                        <>
                          <InputWrapper>
                            <PrescriptionField
                              options={PRISM_VALUES}
                              name={'rightHPrism'}
                              label={t('prescription.manual.prismHLabel')}
                              withOperator
                              top
                              left
                              optionColumns={Math.ceil(PRISM_VALUES.length / 8)}
                              moveRight={-205}
                            />
                          </InputWrapper>

                          <InputWrapper>
                            <PrescriptionField
                              options={PRISM_VALUES}
                              name={'rightVPrism'}
                              label={t('prescription.manual.prismVLabel')}
                              withOperator
                              top
                              left
                              optionColumns={Math.ceil(PRISM_VALUES.length / 8)}
                            />
                          </InputWrapper>
                        </>
                      )}
                    </InputRow>
                    {isContactLens && (
                      <>
                        <InputRow>
                          <InputWrapper>
                            <TextField
                              fullWidth
                              name={'lensBrandRight'}
                              label={t('prescription.manual.lensBrand')}
                            />
                          </InputWrapper>
                          <InputWrapper>
                            <TextField
                              fullWidth
                              name={'lensTypeRight'}
                              label={t('prescription.manual.lensType')}
                            />
                          </InputWrapper>
                        </InputRow>
                        <InputRow>
                          <CheckboxFieldStyled
                            name="sameValues"
                            label={t('prescription.manual.sameValues')}
                          />
                        </InputRow>
                      </>
                    )}
                  </HalForm>
                  <HalForm>
                    {/* LEFT EYE */}
                    <GroupLabel>
                      <BordLeft />
                      <GroupTitle>{t('prescription.manual.leftEye')}</GroupTitle>
                      <BordRight />
                    </GroupLabel>
                    <InputRow>
                      <InputWrapper>
                        <PrescriptionField
                          withOperator
                          options={SPHERE_VALUES}
                          name="leftSphere"
                          label={t('prescription.manual.sphereLabel')}
                        />
                      </InputWrapper>
                    </InputRow>
                    <InputRow>
                      <InputWrapper>
                        <PrescriptionField
                          name="leftCylinder"
                          options={CYLINDER_VALUES()}
                          label={t('prescription.manual.cylinderLabel')}
                          optionColumns={1}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <PrescriptionField
                          options={AXIS_VALUES()}
                          name={'leftAxis'}
                          label={t('prescription.manual.axisLabel')}
                          disabled={!values.leftCylinder || values.leftCylinder === 'DS'}
                          optionColumns={1}
                        />
                      </InputWrapper>
                    </InputRow>
                    <InputRow>
                      <InputWrapper>
                        <PrescriptionField
                          options={ADD_VALUES}
                          name="leftAdd"
                          label={t('prescription.manual.addLabel')}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <PrescriptionField
                          options={ADD_DESCRIPTION_VALUES}
                          name="leftAddDesc"
                          label={t('prescription.manual.addDescLabel')}
                        />
                      </InputWrapper>
                    </InputRow>
                    <InputRow>
                      {isContactLens ? (
                        <>
                          <InputWrapper>
                            <TextField
                              fullWidth
                              name="leftBaseCurve"
                              validate={requiredInterval(8, 10, isLeftBCAndDIANeeded)}
                              label={t('prescription.manual.baseCurveLabel')}
                              // update key to reregister validate function
                              key={isLeftBCAndDIANeeded ? 'leftBC-0' : 'leftBC-1'}
                            />
                          </InputWrapper>

                          <InputWrapper>
                            <TextField
                              fullWidth
                              name="leftDiameter"
                              validate={requiredInterval(13, 15, isLeftBCAndDIANeeded)}
                              label={t('prescription.manual.diameterLabel')}
                              // update key to reregister validate function
                              key={isLeftBCAndDIANeeded ? 'leftD-0' : 'leftD-1'}
                            />
                          </InputWrapper>
                        </>
                      ) : (
                        <>
                          <InputWrapper>
                            <PrescriptionField
                              options={PRISM_VALUES}
                              name="leftHPrism"
                              label={t('prescription.manual.prismHLabel')}
                              withOperator
                              top
                              optionColumns={Math.ceil(PRISM_VALUES.length / 8)}
                            />
                          </InputWrapper>

                          <InputWrapper>
                            <PrescriptionField
                              options={PRISM_VALUES}
                              name="leftVPrism"
                              label={t('prescription.manual.prismVLabel')}
                              withOperator
                              top
                              optionColumns={Math.ceil(PRISM_VALUES.length / 8)}
                              moveLeft={-205}
                            />
                          </InputWrapper>
                        </>
                      )}
                    </InputRow>
                    {isContactLens && values.sameValues !== true && (
                      <>
                        <InputRow>
                          <InputWrapper>
                            <TextField
                              fullWidth
                              name={'lensBrandLeft'}
                              label={t('prescription.manual.lensBrand')}
                            />
                          </InputWrapper>
                          <InputWrapper>
                            <TextField
                              fullWidth
                              name={'lensTypeLeft'}
                              label={t('prescription.manual.lensType')}
                            />
                          </InputWrapper>
                        </InputRow>
                      </>
                    )}
                  </HalForm>
                </FormContainer>
              </TopContainer>
              <BottomContainer>
                <BottomButton
                  onClick={handleSubmit}
                  disabled={
                    hasValidationErrors || (pristine && !isEyefilled) || (dirty && !isEyefilled)
                  }
                >
                  {t('action.confirm')}
                </BottomButton>
              </BottomContainer>
            </Content>
          );
        }}
      />
    </Wrapper>
  );
};

export default ManualPrescription;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ManualTitle = styled(Title)`
  font-size: 32px;
  text-align: center;
  margin-bottom: 76px;
  line-height: 1.25;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  white-space: pre-line;
  @media ${props => props.theme.breakpoints.mobile} {
    margin-bottom: 70px;
    line-height: 1.25;
    font-size: 32px;
    font-weight: 900;
  }
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 80px 96px 48px;
  @media ${props => props.theme.breakpoints.mobile} {
    padding: 90px 0 32px;
  }
`;

const HalForm = styled.div`
  width: 392px;

  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${props => props.theme.breakpoints.mobile} {
    flex-direction: column;
  }
`;

const BottomButton = styled(Button)`
  margin: 0 auto;
  align-self: flex-end;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${props => props.theme.breakpoints.mobile} {
    flex-direction: column;
  }
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media ${props => props.theme.breakpoints.mobile} {
    margin-bottom: 90px;
  }
`;
const BottomContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const InputWrapper = styled.div`
  max-width: 188px;
  @media ${props => props.theme.breakpoints.mobile} {
    max-width: 100%;
  }
`;

const BordLeft = styled.div`
  height: 8px;
  width: 100%;
  border-width: 2px 0 0 2px;
  border-style: solid;
  margin-top: 5px;
  border-color: ${props => props.theme.colors.black};
`;

const BordRight = styled(BordLeft)`
  height: 8px;
  border-width: 2px 2px 0 0;
`;

const GroupLabel = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const GroupTitle = styled(Text)`
  text-align: center;
  font-weight: 900;
  color: ${props => props.theme.colors.black};
  margin: 0 11px;
  white-space: nowrap;
`;

const CheckboxFieldStyled = styled(CheckboxField)`
  label {
    margin: 0;
  }
`;
