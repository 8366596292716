import React, {useState, useContext, useCallback, useEffect} from 'react';
import {ThemeContext} from 'styled-components';
import {useHistory} from 'react-router';

import Mobile from '../components/MobileStop';
import useActivity from '../../hooks/useActivity';

type DeviceType = 'mobile' | 'tablet-portrait' | 'desktop';

const CheckDimensions: React.FC<{}> = props => {
  const theme = useContext(ThemeContext);
  useActivity();
  const history = useHistory();

  const path = history?.location?.pathname;

  const calcDeviceType = useCallback((): DeviceType => {
    const isMobile = window.matchMedia(theme.breakpoints.mobile).matches;

    if (isMobile) {
      return 'mobile';
    }

    const isTabletPortrait = window.matchMedia(theme.breakpoints.tabletPortrait)
      .matches;

    if (isTabletPortrait) {
      return 'tablet-portrait';
    }

    return 'desktop';
  }, []);

  const [deviceType, setDeviceType] = useState<DeviceType>(() =>
    calcDeviceType()
  );

  const deviceTypeListener = useCallback(
    () => setDeviceType(calcDeviceType()),
    []
  );

  useEffect(() => {
    window.addEventListener('resize', deviceTypeListener);
    window.addEventListener('orientationchange', deviceTypeListener);

    return () => {
      window.removeEventListener('resize', deviceTypeListener);
      window.removeEventListener('orientationchange', deviceTypeListener);
    };
  }, []);

  useEffect(() => {
    //TODO: not working for when going back
    window.scrollTo(0, 0);
  }, [path]);

  if (deviceType === 'tablet-portrait') {
    return <Mobile isMobile={false} />;
  }

  return <>{props.children}</>;
};

export default CheckDimensions;
