import styled from 'styled-components';

export const Main = styled.main`
  width: 100vw;

  ${({theme}) => `
    background-image: linear-gradient(
      to bottom,
      ${theme.colors.wrapperBackgroundTop},
      rgba(255, 255, 255, 0) 71%
    ),
    linear-gradient(to bottom, ${theme.colors.wrapperBackgroundBottom}, ${theme.colors.wrapperBackgroundBottom});
  `}
`;

export {Wrapper, ContentLeft, ContentRight} from './Wrapper';
