import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import Button from '../../../atoms/Button';
import {Title, Subtitle} from '../../../atoms/Typography';

interface Props {
  visible: boolean;
  onQuit: () => void;
  onContinue: () => void;
}

const QuitTestModal: React.FC<Props> = ({visible, onQuit, onContinue}) => {
  const {t} = useTranslation();

  if (!visible) {
    return null;
  }

  return (
    <Container>
      <ModalContainer>
        <TextContainer>
          <StyledTitle>{t('test.quit.title')}</StyledTitle>
          <Subtitle>{t('test.quit.subtitle')}</Subtitle>
        </TextContainer>

        <Row>
          <StyledButton onClick={onQuit} inverted>
            {t('test.quit.quit')}
          </StyledButton>
          <StyledButton onClick={onContinue}>
            {t('test.quit.continue')}
          </StyledButton>
        </Row>
      </ModalContainer>
    </Container>
  );
};

export default QuitTestModal;

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContainer = styled.div`
  overflow: hidden;
  width: 832px;
  border-radius: 24px;
  background-color: ${({theme}) => theme.colors.white};
  box-shadow: 0 10px 30px 0 rgba(31, 35, 39, 0.2);

  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
    min-height: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: visible;
  }
`;

const TextContainer = styled.div`
  padding: 48px 96px 16px;
  text-align: center;

  @media ${props => props.theme.breakpoints.mobile} {
    padding: 24px;
  }
`;

const StyledTitle = styled(Title)`
  margin-bottom: 32px;
`;

const Row = styled.div`
  padding: 48px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: ${({theme}) => theme.colors.grey100};

  @media ${props => props.theme.breakpoints.mobile} {
    display: block;
  }
`;

const StyledButton = styled(Button)`
  margin: 0 24px;
  min-width: 252px;

  @media ${props => props.theme.breakpoints.mobile} {
    min-width: 100%;
    margin: 0;
    margin-bottom: 24px;
  }
`;
