import React, {useState, useCallback} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Form} from 'react-final-form';

import {useAppState} from '../../provider/AppStateProvider';
import {Wrapper, ContentLeft, ContentRight} from '../../../atoms/Layout';
import {Title, Subtitle, Text} from '../../../atoms/Typography';
import Button from '../../../atoms/Button';
import BackLink from '../../components/BackLink';
import ImageField from '../../components/Fields/ImageField';
import {ReactComponent as camera} from '../../../assets/icons/camera.svg';
import {colors} from '../../../theme/common';
import checkmark from '../../../assets/icons/checkmark-circle-outlined.svg';
import prescription from '../../../assets/icons/prescription.svg';
import close from '../../../assets/icons/close-outlined.svg';
import clBox from '../../../assets/icons/cl-box.svg';

type Image = string | undefined;

const Prescription: React.FC = () => {
  const appState = useAppState();
  const history = useHistory();
  const {t} = useTranslation();
  const [preview, setPreview] = useState<Image>();

  const confirmPreview = useCallback(() => {
    confirmPicture({image: preview});
  }, [preview]);

  const resetPreview = useCallback(() => {
    setPreview(undefined);
  }, []);

  const [showLimitError, setLimitError] = useState<boolean>(false);

  const confirmPicture = useCallback(({image}) => {
    const imageSize = new Blob([image]).size;
    const limit = 10000000;
    if (imageSize > limit) {
      setLimitError(true);
      return;
    }

    appState.updateState({
      prescriptionImage: image,
    });

    history.push('/manual-prescription');
  }, []);

  const handleChange = useCallback(evt => {
    window.scrollTo(0, 0);
    const file = evt.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = ({target}) => {
        if (target && target.result) {
          const image = target.result as string;
          setPreview(image);
        }
      };

      return reader.readAsDataURL(file);
    }
  }, []);

  if (preview) {
    return (
      <PreviewContainer>
        <PreviewTitle>{t('prescription.picture.title')}</PreviewTitle>

        <PreviewImageContainer>
          <PreviewImage src={preview} />
          <Row>
            <Button onClick={resetPreview} inverted>
              {t('prescription.picture.retake')}
            </Button>

            <Button onClick={confirmPreview}>{t('prescription.picture.confirm')}</Button>
          </Row>
        </PreviewImageContainer>
      </PreviewContainer>
    );
  }

  return (
    <Wrapper light="left" header={<BackLink />} footer>
      <ContentLeft>
        <Title>{t('prescription.title')}</Title>
      </ContentLeft>

      <ContentRight>
        <Subtitle>{t('prescription.subtitle')}</Subtitle>

        <StyledText>{t('prescription.instruction')}</StyledText>
        <StyledTextImageUpload>
          <strong>{t('prescription.instructionUploadWarningBold')}</strong>
          {t('prescription.instructionUploadWarning')}
        </StyledTextImageUpload>
        <IconsContainer>
          <IconsGroup>
            <CheckmarkIcon src={checkmark} />
            <PrescriptionIcon src={prescription} />
          </IconsGroup>
          <IconsGroup>
            <CloseIcon src={close} />
            <ClBoxIcon src={clBox} />
          </IconsGroup>
        </IconsContainer>
        <Form
          onSubmit={confirmPicture}
          render={({handleSubmit, values}) => (
            <>
              <ImageField name="image" />
              {showLimitError && <ErrorDiv>{t('prescription.fileSizeError')}</ErrorDiv>}
              <FileRequirements>{t('prescription.picture.requirements')}</FileRequirements>

              <Row>
                <InputContainer>
                  <ButtonText>{t('prescription.picture.take')}</ButtonText>
                  <CameraIcon />
                  <Input
                    id="camera-input"
                    type="file"
                    capture="camera"
                    accept="image/*"
                    onChange={handleChange}
                  />
                </InputContainer>

                <Button onClick={handleSubmit} disabled={!values.image}>
                  {t('prescription.picture.upload')}
                </Button>
              </Row>
            </>
          )}
        />
      </ContentRight>
    </Wrapper>
  );
};

export default Prescription;

const StyledText = styled(Text)`
  margin: 48px 0 24px;
`;
const StyledTextImageUpload = styled(Text)`
  margin-bottom: 32px;
`;

const IconsContainer = styled.div`
  width: 100%
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 32px;
`;

const IconsGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const CheckmarkIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const PrescriptionIcon = styled.img`
  width: 70px;
  height: 70px;
`;

const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;

const ClBoxIcon = styled.img`
  width: 88px;
  height: 52px;
  margin-top: 12px;
`;

const Row = styled.div`
  position: relative;
  margin-top: 45px;
  display: flex;
  justify-content: space-between;

  @media ${props => props.theme.breakpoints.mobile} {
    flex-direction: column;
    justify-content: flex-end;

    & button {
      margin: 24px 0 44px;
    }
  }
`;

const FileRequirements = styled(Text)`
  margin-top: 8px;
  font-size: 14px;
  color: ${({theme}) => theme.colors.grey500};
`;

const InputContainer = styled.div`
  display: none;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.white};
  border-radius: 22px;

  ${props =>
    'ontouchstart' in window
      ? `@media ${props.theme.breakpoints.tablet} {
      display: flex;
    }`
      : ''}
`;

const ButtonText = styled(Text).attrs({bold: true})`
  color: ${({theme}) => theme.colors.blue};
`;

const CameraIcon = styled(camera).attrs(({theme}) => ({
  fill: theme.colors.main,
}))`
  margin-left: 12px;

  & path + path {
    fill: ${({theme}) => theme.colors.main};
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
`;

const ErrorDiv = styled.div`
  color: ${colors.red};
`;

const PreviewContainer = styled.div`
  min-height: 100vh;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.grey50};
`;

const PreviewTitle = styled(Title)`
  max-width: 600px;
`;

const PreviewImageContainer = styled.div`
  align-self: center;
  width: 100%;
  max-width: 400px;
`;

const PreviewImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
  align-self: center;
  margin: 64px auto 54px;
  border-radius: 24px;
`;
