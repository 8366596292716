type ShoeSize = {
  [key: string]: {
    [key: string]: number;
  };
};

export const SHOE_SIZE_MAP: ShoeSize = {
  male: {
    '6': 24.5,
    '6.5': 25,
    '7': 24.4,
    '7.5': 24.8,
    '8': 25.4,
    '8.5': 25.7,
    '9': 26,
    '9.5': 26.7,
    '10': 27,
    '10.5': 27.3,
    '11': 27.9,
    '11.5': 28.3,
    '12': 28.6,
    '13': 29.4,
  },
  female: {
    '6': 22.5,
    '6.5': 23,
    '7': 23.5,
    '7.5': 23.8,
    '8': 24.1,
    '8.5': 24.6,
    '9': 25.1,
    '9.5': 25.4,
    '10': 25.9,
    '10.5': 26.2,
    '11': 26.7,
    '11.5': 27.1,
    '12': 27.6,
    '13': 28.7,
  },
};

export const SIZE_OPTIONS = [
  {value: '6', label: '6'},
  {value: '6.5', label: '6.5'},
  {value: '7', label: '7'},
  {value: '7.5', label: '7.5'},
  {value: '8', label: '8'},
  {value: '8.5', label: '8.5'},
  {value: '9', label: '9'},
  {value: '9.5', label: '9.5'},
  {value: '10', label: '10'},
  {value: '10.5', label: '10.5'},
  {value: '11', label: '11'},
  {value: '11.5', label: '11.5'},
  {value: '12', label: '12'},
  {value: '13', label: '13'},
];

export const STEP_AS_CM = 76.2;
