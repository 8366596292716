import {SHOE_SIZE_MAP} from '../constants/shoeSize';

const FIVE_FEET_DISTANCE_CM = 152.4;

export const getDistanceInSteps = ({
  gender,
  shoeSize,
}: {
  gender?: string;
  shoeSize?: string;
}) => {
  if (shoeSize && gender) {
    return Math.round(FIVE_FEET_DISTANCE_CM / SHOE_SIZE_MAP[gender][shoeSize]);
  }

  return 0;
};
