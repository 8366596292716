import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {Title} from './Typography';
import spinner from '../assets/spinner.svg';

const Spinner = () => {
  const {t} = useTranslation();
  return (
    <Container>
      <Image />
      <StyledTitle>{t('action.wait')}</StyledTitle>
    </Container>
  );
};

export default Spinner;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img.attrs({src: spinner})`
  height: 165px;
  width: 165px;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  -webkit-animation-name: rotate;
  -webkit-animation-duration: 0.8s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
`;

const StyledTitle = styled(Title)`
  margin: 20px;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: normal;
  font-weight: 500;
  text-align: center;
  color: ${({theme}) => theme.colors.grey600};
`;
