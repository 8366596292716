import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import Button from '../../../atoms/Button';
import {Title, Subtitle} from '../../../atoms/Typography';

interface Props {
  visible: boolean;
  onQuit: () => void;
}

const PrivacyModal: React.FC<Props> = ({visible, onQuit}) => {
  const {t} = useTranslation();

  if (!visible) {
    return null;
  }

  return (
    <Container>
      <ModalContainer>
        <TextContainer>
          <ModalContent>
            <StyledTitle>{t('privacy.title')}</StyledTitle>
            {t('privacy.content')}
          </ModalContent>
          <StyledButton onClick={onQuit}>X</StyledButton>
        </TextContainer>
      </ModalContainer>
    </Container>
  );
};

export default PrivacyModal;

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  @media ${props => props.theme.breakpoints.mobile} {
    padding: 24px;
  }
`;

const ModalContainer = styled.div`
  overflow: hidden;
  border-radius: 24px;
  height: 100%;
  background-color: ${({theme}) => theme.colors.white};
  box-shadow: 0 10px 30px 0 rgba(31, 35, 39, 0.2);

  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
    min-height: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: visible;
  }
`;

const TextContainer = styled.div`
  padding: 48px;
  height: 100%;
  position: relative;
  @media ${props => props.theme.breakpoints.mobile} {
    padding: 24px;
  }
`;

const StyledTitle = styled(Title)`
  margin-bottom: 32px;
  font-size: 24px;
`;

const ModalContent = styled.div`
  white-space: pre-line;
  height: 100%;
  overflow-y: auto;
  font-size: 20px;
`;

const StyledButton = styled(Button)`
  position: absolute;
  padding-top: 12px;
  top: 25px;
  right: 25px;
  width: 40px;
  font-size: 1.5em;
  font-weight: bold;
  border-radius: 50%;
  background-color: #000;
  color: white;
  text-align: center;
  cursor: pointer;
`;
