import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {sendTest} from '../../api/user';
import {useAppState} from '../provider/AppStateProvider';
import ErrorScreen from '../../app/views/ErrorScreen';
import AudioBox from '../components/AudioBox';
import {Title, Subtitle} from '../../atoms/Typography';
import Button from '../../atoms/Button';
import {Wrapper} from '../../atoms/Layout';

const TestCompleted: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const {t} = useTranslation();
  const appState = useAppState();
  const history = useHistory();
  const goToCart =
    appState.state.flowMode && appState.state.flowMode === 'paid';

  const handleRetake = async () => {
    appState.updateState({tests: []});
    history.goBack();
  };

  const handleTestSubmission = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const testData = {
        ...appState.state,
        audios:
          appState.state.tests &&
          appState.state.tests.map(testStep => ({
            id: testStep.step.id,
            audio: testStep.audioBlob,
          })),
      };

      const {sessionId, isPlayingAudio, ...data} = testData;

      await sendTest(data, sessionId);

      if (goToCart && appState.state.successRedirectUrl) {
        window.location.href = appState.state.successRedirectUrl;
        return;
      }

      history.push('/thanking');
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return (
      <ErrorScreen
        title={t('submissionError.title')}
        subtitle={t('submissionError.subtitle')}
      />
    );
  }

  return (
    <Wrapper>
      <Container>
        <TitleContainer>
          <Title>{t('test.complete.title')}</Title>
        </TitleContainer>

        <Description>{t('test.complete.description')}</Description>

        <ContentContainer>
          {appState.state && appState.state.tests && (
            <>
              {appState.state.tests[0] && (
                <AudioBox
                  label={t('test.complete.leftEye')}
                  text={
                    appState.state.tests[0]?.step.letters &&
                    appState.state.tests[0].step.letters
                  }
                  audioUrl={URL.createObjectURL(
                    appState?.state?.tests[0]?.audioBlob
                  )}
                />
              )}
              {appState.state.tests[1] && (
                <AudioBox
                  label={t('test.complete.rightEye')}
                  text={
                    appState.state.tests[1]?.step.letters &&
                    appState.state.tests[1].step.letters
                  }
                  audioUrl={URL.createObjectURL(
                    appState?.state?.tests[1]?.audioBlob
                  )}
                />
              )}
              {appState.state.tests[2] && (
                <AudioBox
                  label={t('test.complete.bothEyes')}
                  text={
                    appState.state.tests[2]?.step.letters &&
                    appState.state.tests[2].step.letters
                  }
                  audioUrl={URL.createObjectURL(
                    appState?.state?.tests[2]?.audioBlob
                  )}
                />
              )}
            </>
          )}
          {goToCart && (
            <StyledRow>
              <GoToCartContainer>
                {t('test.complete.goToCartDescription')}
              </GoToCartContainer>
            </StyledRow>
          )}
          <StyledRow>
            <StyledButton onClick={handleRetake} inverted>
              {t('test.complete.retake')}
            </StyledButton>

            <StyledButton onClick={handleTestSubmission} loading={loading}>
              {t(goToCart ? 'test.complete.goToCart' : 'test.complete.send')}
            </StyledButton>
          </StyledRow>
        </ContentContainer>
      </Container>
    </Wrapper>
  );
};

export default TestCompleted;

const Container = styled.div`
  height: 100%;
  padding: 48px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const TitleContainer = styled.div`
  margin: 32px 0;
`;

const Description = styled(Subtitle)`
  max-width: 640px;
  margin-bottom: 40px;
`;

const ContentContainer = styled.div`
  max-width: 500px;
`;

const Row = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media ${props => props.theme.breakpoints.mobile} {
    display: block;
  }
`;

const StyledButton = styled(Button)`
  margin: 0 24px;

  @media ${props => props.theme.breakpoints.mobile} {
    margin: 0;
    margin-bottom: 24px;
    width: 100%;
  }
`;

const StyledRow = styled(Row)`
  margin-top: 30px;
`;

const GoToCartContainer = styled.div`
  font-size: 16px;
  font-family: Avenir;
  color: #7b8a9a;
  line-height: 21px;
`;
