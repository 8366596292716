import React from 'react';
import styled from 'styled-components';

import {Text} from '../../atoms/Typography';

interface Props {
  icon: string;
  text: string;
}

const IconWithText: React.FC<Props> = ({icon, text}) => (
  <Row>
    <Icon src={icon} />
    <Text>{text}</Text>
  </Row>
);

export default IconWithText;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const Icon = styled.img`
  width: 48px;
  height: 48px;
  margin-right: 16px;
`;
