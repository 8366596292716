import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {getTranslations} from '../api/languages';

i18n.use(initReactI18next).init({
  lng: 'en',
  resources: {},
  interpolation: {
    escapeValue: false, // react already safe from xss
  },
  debug: process.env.NODE_ENV !== 'production',
});

export default i18n;

export async function loadTranslations() {
  const translations = await getTranslations();

  for (const language in translations) {
    i18n.addResourceBundle(
      language,
      'translation',
      translations[language],
      true,
      true
    );
  }
}
