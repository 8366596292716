import React from 'react';
import {Form} from 'react-final-form';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';

import {Wrapper, ContentRight, ContentLeft} from '../../../atoms/Layout';
import BackLink from '../../components/BackLink';
import SelectField from '../../components/Fields/SelectField';
import TextField from '../../components/Fields/TextField';
import {useAppState} from '../../provider/AppStateProvider';
import {required} from '../../../utils/validation';
import {LAST_EXAM_OPTIONS, EYEWEAR_TYPE_OPTIONS, MEDICATION_OPTIONS} from '../../../constants/user';
import Button from '../../../atoms/Button';
import {Title, Subtitle, Text} from '../../../atoms/Typography';
import RadioField from '../../components/Fields/RadioField';
import LastExamModal from '../../components/Modal/LastExamModal';

enum INFO {
  EXAM,
  CONDITIONS,
}

const HealthHistory: React.FC = () => {
  const {t} = useTranslation();
  const appState = useAppState();
  const history = useHistory();

  const params: {step: string} = useParams();
  const formStep = parseInt(params.step) || 0;

  const nextStep = () => {
    history.push('/health-history/1');
  };

  const onSubmit = (data: {
    lastExam: string;
    type: string;
    medications: string;
    infection: string;
  }): void => {
    appState.updateState({
      questionnaire: {
        ...appState.state.questionnaire,
        lastExam: data.lastExam,
        eyewearType: data.type,
        medications: data.medications,
        infection: data.infection === 'true',
      },
    });

    history.push('/conditions');
  };

  const isStepValid = (errors: {
    lastExam?: string[];
    type?: string[];
    medications?: string[];
    infection?: string;
  }): boolean => {
    if (formStep === INFO.EXAM) {
      return !errors.lastExam && !errors.type;
    }
    return !errors.infection;
  };

  const showModal = (values: {
    lastExam: string;
    type: string;
    medications: string;
    infection: string;
  }) => {
    return values.lastExam === '5';
  };

  const handleChangePeriod = (form: any) => {
    form.change('lastExam', '');
  };

  const handleGoBack = () => {
    if (appState.state.storeOriginUrl) {
      window.location.href = appState.state.storeOriginUrl;
    }
  };

  return (
    <Wrapper light="left" header={<BackLink />} footer>
      <ContentLeft>
        <Title>{t('healthHistory.title')}</Title>
      </ContentLeft>

      <ContentRight>
        <Subtitle>{t('healthHistory.subtitle')}</Subtitle>

        <Form
          initialValues={{
            lastExam: appState.state.questionnaire?.lastExam || '',
            type: appState.state.questionnaire?.eyewearType || '',
            medications: appState.state.questionnaire?.medications || '',
            infection: appState.state.questionnaire?.infection?.toString() || '',
          }}
          onSubmit={onSubmit}
          render={({values, handleSubmit, errors, form}): JSX.Element => (
            <>
              {formStep === INFO.EXAM ? (
                <>
                  <StyledText>{t('healthHistory.lastExamDate')}</StyledText>
                  <Select
                    name="lastExam"
                    options={LAST_EXAM_OPTIONS}
                    validate={required}
                    label={t('healthHistory.selectDate')}
                  />

                  <StyledText>{t('healthHistory.currentEyewear')}</StyledText>
                  <Select
                    name="type"
                    options={EYEWEAR_TYPE_OPTIONS}
                    validate={required}
                    disabled={showModal(values)}
                    label={t('healthHistory.selectType')}
                    multiple
                  />
                </>
              ) : (
                <>
                  <StyledText>{t('healthHistory.medications')}</StyledText>
                  <TextField
                    name="medications"
                    fullWidth
                    label={t('healthHistory.enterMedications')}
                    maxLength="100"
                  />

                  <StyledText>{t('healthHistory.pastInfections')}</StyledText>
                  <RadioField name="infection" options={MEDICATION_OPTIONS} validate={required} />
                </>
              )}

              <StyledButton
                onClick={formStep === INFO.EXAM ? nextStep : handleSubmit}
                disabled={!isStepValid(errors) || showModal(values)}
              >
                {t('action.continue')}
              </StyledButton>
              <LastExamModal
                visible={showModal(values)}
                onChangePeriod={() => handleChangePeriod(form)}
                onGoBack={handleGoBack}
              />
            </>
          )}
        />
      </ContentRight>
    </Wrapper>
  );
};

export default HealthHistory;

const StyledText = styled(Text)`
  margin-bottom: 18px;
`;

const Select = styled(SelectField)`
  width: 100%;

  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  display: block;
  margin-top: 24px;

  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
    margin-bottom: 50px;
  }
`;
